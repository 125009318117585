<template>
  <div class="container-card view-parceiros">
    <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
      <div class="card custom-card">
        <div class="card-header">
          <h5 class="card-title">Editar parceiro</h5>
        </div>
        <div v-if="usuario.nome" class="card-body">
            <form class="mt-1 fh-form" @submit.prevent="submitForm()" data-vv-scope="formulario">
              <div class="form-group d-flex container-cnpj-cadastro-parceiro">
                <div class="col-12 col-md-7 col-lg-5 p-0">
                 <label for="cnpj">CNPJ</label>
                   <TheMask :class="{'form-control': true, 'is-invalid': errors.has('formulario.cnpj')}" placeholder="Digite o CNPJ da empresa" 
                      v-model="formulario.cnpj"  v-validate="'required|min:14|cnpj'" type="text" name="cnpj" autocomplete="off" :mask="'##.###.###/####-##'"/>
                  <span v-show="errors.has('formulario.cnpj')" class="invalid-feedback">{{ errors.first('formulario.cnpj') }}</span>
                </div>
              </div>
              <div class="form-group col-12 col-md-7 col-lg-5 p-0">
                <label for="empresa">Empresa</label>
                <input :class="{'form-control': true, 'is-invalid': errors.has('formulario.empresa')}" placeholder="Que empresa ele representa?" 
                  v-model="formulario.empresa"  v-validate="'required|min:4'" type="text" name="empresa" autocomplete="off"/>
                <span v-show="errors.has('formulario.empresa')" class="invalid-feedback">{{ errors.first('formulario.empresa') }}</span>
              </div>
              <div class="form-group col-12 col-md-7 col-lg-5 p-0">
                <label for="nome">Nome completo</label>
                <input :class="{'form-control': true, 'is-invalid': errors.has('formulario.nome')}" placeholder="Nome Completo" 
                   v-model="formulario.nome"  v-validate="'required|nomeSobrenome|min:4'" type="text" name="nome" autocomplete="off"/>
                <span v-show="errors.has('formulario.nome')" class="invalid-feedback">{{ errors.first('formulario.nome') }}</span>
              </div>
              <div class="form-group col-12 col-md-7 col-lg-5 p-0">
                <label for="telefone">Telefone</label>
                <TheMask :class="{'form-control': true, 'is-invalid': errors.has('formulario.telefone')}" placeholder="(00) 00000-0000" :mask="['(##) ####-####', '(##) #####-####']"
                   v-model="formulario.telefone"  v-validate="'required|telefone'" type="tel" name="telefone" autocomplete="off"/>
                <span v-show="errors.has('formulario.telefone')" class="invalid-feedback">{{ errors.first('formulario.telefone') }}</span>
              </div>
              <div class="form-group col-12 col-md-7 col-lg-5 p-0">
                <label for="email">E-mail</label>
                <input :class="{'form-control': true, 'is-invalid': errors.has('formulario.email')}" placeholder="Digite o e-mail" 
                    v-model="formulario.email"  v-validate="'required|email'" type="email" name="email" autocomplete="off"/>
                <span v-show="errors.has('formulario.email')" class="invalid-feedback">{{ errors.first('formulario.email') }}</span>
              </div>
              <div class="form-group col-12 col-md-7 col-lg-5 p-0">
                  <label for="status">Status</label>
                  <multiselect v-model="formulario.status.model" :options="formulario.status.options" placeholder="Selecione" label="value" track-by="key">
                    <template slot="option" slot-scope="props">
                      <span class="">{{props.option.value}}</span>
                    </template>
                    <template slot="noResult">Nenhum resultado encontrado.</template>
                  </multiselect>
                  <span v-show="errors.has('formulario.status')" class="invalid-feedback">{{ errors.first('formulario.status') }}</span>
              </div>
              <div class="form-group col-12 col-md-7 col-lg-5 p-0">
                <label for="taxa">Perfil</label>
                <multiselect v-model="formulario.perfil.model" :options="formulario.perfil.options" placeholder="Selecione" label="value" track-by="key">
                  <template slot="option" slot-scope="props">
                    <span class="">{{props.option.value}}</span>
                  </template>
                  <template slot="noResult">Nenhum resultado encontrado.</template>
                </multiselect>
              </div>
              <div class="form-group col-12 col-md-7 col-lg-5 p-0">
                <label for="taxa">Taxa de juros %</label>
                  <money v-bind="money" :class="{'form-control': true, 'is-invalid': errors.has('formulario.taxa')}"
                  v-model="formulario.taxa"  v-validate="'required|min_value:0.1|max_value:100'" type="text" name="taxa" autocomplete="off"/>
                <span v-show="errors.has('formulario.taxa')" class="invalid-feedback">{{ errors.first('formulario.taxa') }}</span>
              </div>
              <div class="mb-4">
                <button class="mt-2 btn btn-custom secondary default small" type="button" v-b-modal.modal-alterar-senha>Alterar senha</button>
              </div>
              <CustomAlert :alert="alert"></CustomAlert>
              <div class="container-botoes-form">
                <button class="btn btn-custom primary default" type="submit" :disabled="!validForm">Salvar alterações</button>
                <router-link class="font-weight-bold pointer mt-3 mt-sm-0" to="/parceiros">Voltar</router-link>
              </div>
            </form>
        </div>
        <div v-else class="card-body">
            <CustomAlert :alert="alert"></CustomAlert>
        </div>
      </div>
      <b-modal id="modal-ativar-parceiro" centered title="Ativar parceiro" footerClass="d-flex justify-content-between">
        <template #modal-header-close><CustomIcon :name="'x'"></CustomIcon></template>
        <p>Você tem certeza que deseja ativar o parceiro <strong>{{usuario.nome}}</strong> ? </p>
        <p>Nós enviaremos um e-mail a ele e, a partir de agora, ele poderá realizar simulações no portal do parceiro.</p>
        <template #modal-footer>
            <button type="button" class="btn btn-link" @click="$bvModal.hide('modal-ativar-parceiro')">Cancelar</button>
            <button type="button" class="btn btn-custom primary default" @click="alterarStatus(true)">Ativar parceiro</button>
        </template>
      </b-modal>
      <b-modal id="modal-inativar-parceiro" centered title="Inativar parceiro" footerClass="d-flex justify-content-between">
        <template #modal-header-close><CustomIcon :name="'x'"></CustomIcon></template>
        <p>Você tem certeza que deseja inativar o parceiro <strong>{{usuario.nome}}</strong> ? </p>
        <p>Nós enviaremos um e-mail a ele sobre a isso, e ele não conseguirar acessar o portal do parceiro.</p>
        <template #modal-footer>
            <button type="button" class="btn btn-link" @click="$bvModal.hide('modal-inativar-parceiro')">Cancelar</button>
            <button type="button" class="btn btn-custom primary danger" @click="alterarStatus(true)">Inativar parceiro</button>
        </template>
      </b-modal>
      <b-modal id="modal-alterar-senha" centered title="Alterar senha">
          <template #modal-header-close><CustomIcon :name="'x'"></CustomIcon></template>
          <form class="mt-1 mb-3 fh-form" @submit.prevent="submitFormSenha()" data-vv-scope="formularioSenha">
            <div class="form-group">
              <label for="novaSenha">Nova senha</label>
              <div class="input-icon">
                <input :class="{'form-control':true, 'is-invalid': errors.has('formularioSenha.novaSenha')}" placeholder="Digite sua nova senha" v-model="formularioSenha.novaSenha" 
                        v-validate="'required|min:6'" :type="novaSenhaVisivel ? 'text' : 'password'" name="novaSenha" ref="novaSenha" autocomplete="new-password"/>
                <div class="icon" @click="novaSenhaVisivel = !novaSenhaVisivel">
                <CustomIcon :name="novaSenhaVisivel ? 'hide' : 'show'"/>
                </div>
                <span v-show="errors.has('formularioSenha.novaSenha')" class="invalid-feedback">{{ errors.first('formularioSenha.novaSenha') }}</span>
              </div>
            </div>
            <div class="form-group">
              <label for="confirmeSenha">Confirme a nova senha</label>
              <input :class="{'form-control':true, 'is-invalid': errors.has('formularioSenha.confirmeSenha')}" placeholder="Confirme sua senha" v-model="formularioSenha.confirmeSenha" 
                      v-validate="'required|confirmed:novaSenha'" :type="'password'" name="confirmeSenha" autocomplete="new-password"/>                
              <span v-show="errors.has('formularioSenha.confirmeSenha')" class="invalid-feedback">{{ errors.first('formularioSenha.confirmeSenha') }}</span>
            </div>
          </form>
          <CustomAlert :alert="alert2"></CustomAlert>
          <template #modal-footer>
            <div class="mx-auto">
              <button type="button" class="btn btn-custom primary default" :disabled="!validFormSenha" @click="submitFormSenha()">Salvar alterações</button>
            </div>
          </template>
        </b-modal>
      <AlertModal :alertModal="alertModal" @fechar="alertModal = null"/>
  </div>
</template>

<script>

import UsuarioService from '@/services/creditPlus/usuario.service.js'
import Multiselect from 'vue-multiselect'
import { TheMask } from 'vue-the-mask'

export default {
  name: 'EditarParceiro',
  components: {
    TheMask,
    Multiselect
  },
  data() {
    return {
      usuario: {},
      breadcrumb: [
        { text: 'Parceiros', href: '/parceiros' },
        { text: 'Editar parceiro', active: true }
      ],
      formularioSenha: {
        novaSenha: '',
        confirmeSenha: ''
      },
      formulario: {
        email: '',
        empresa: '',
        nome: '',
        telefone: '',
        cnpj: '',
        taxa: '',
        perfil: {
          model: null,
          options: []
        },
        status: {
          model: null,
          options: []
        },
      },
      money: {
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: '%',
          precision: 2,
          masked: false
      },
      senhaVisivel: false,
      novaSenhaVisivel: false,
      alertModal: null,
      alert: null,
      alert2: null,
      loading: false,
    }
  },    
  mounted() {
    this.dadoUsuario()
  },
  methods: {
    dadoUsuario() {
      UsuarioService.dadosUsuario(this.$route.query.id).then(result => {
        if (result.success && result.data.id > 0 && result.data.perfil !== this.$enums.perfis.SUPERADMIN.key) {
          this.usuario = {
            alterarSenha: result.data.alterarSenha,
            dataCriacaoConta: result.data.dataCriacaoConta,
            email: result.data.email,
            id: result.data.id,
            nome: result.data.nome,
            nomeEmpresa: result.data.nomeEmpresa,
            perfil: result.data.perfil,
            telefone: result.data.telefone,
            status: result.data.status,
            taxa: result.data.taxa,
            cnpj: result.data.cnpj,
          }

          this.formulario.cnpj = this.usuario.cnpj
          this.formulario.empresa = this.usuario.nomeEmpresa
          this.formulario.nome = this.usuario.nome
          this.formulario.telefone = this.usuario.telefone
          this.formulario.email = this.usuario.email
          this.formulario.taxa = this.usuario.taxa

          this.formulario.status.options = this.$enums.getArray(this.$enums.statusUsuario).filter(d => d.key != this.$enums.statusUsuario.EXCLUIDO.key)
          if (this.$enums.statusUsuario.PENDENTE.key != this.usuario.status) {
            this.formulario.status.options = this.formulario.status.options.filter(d => d.key != this.$enums.statusUsuario.PENDENTE.key)
          }

          this.formulario.status.options.forEach(element => {
            if (element.key == this.usuario.status) {
              this.formulario.status.model = element
            }
          });

          this.formulario.perfil.options = this.$enums.getArray(this.$enums.perfis).filter(d => d.key != this.$enums.perfis.SUPERADMIN.key)
          this.formulario.perfil.options.forEach(element => {
            if (element.key == this.usuario.perfil) {
              this.formulario.perfil.model = element
            }
          });

        } else {
          this.alert = {
            type: 'warning',
            message: 'Usuário com o id ' + this.$route.query.id + ' não foi encontrado ou não pode ser editado.',
            layout: 2
          }
        }
      });
    },
    submitForm(avisoPersonalizado = false) {

      if (this.usuario.status == this.$enums.statusUsuario.PENDENTE.key) {
        if (this.formulario.status.model.key == this.$enums.statusUsuario.ATIVO.key) {
            this.$bvModal.show('modal-ativar-parceiro')
            return;
      } else if (this.formulario.status.model.key == this.$enums.statusUsuario.INATIVO.key) {
            this.$bvModal.show('modal-inativar-parceiro')
            return;
        }
      }

      let data = {
        id: this.usuario.id,
        nome: this.formulario.nome,
        nomeEmpresa: this.formulario.empresa,
        telefone: this.formulario.telefone,
        cnpj: this.formulario.cnpj,
        perfil: this.formulario.perfil.model.key,
        status: this.formulario.status.model.key,
        taxa: this.formulario.taxa
      }
      if (this.formulario.email != this.usuario.email) {
        data.email = this.formulario.email
      }

      this.loadingMessage = "Salvando informações do parceiro."
      UsuarioService.editarUsuario(data).then(result => {
        if (result.data && result.data.success) {
          if (avisoPersonalizado) {
            this.$bvModal.hide('modal-inativar-parceiro')
            this.$bvModal.hide('modal-ativar-parceiro')
            this.alertModal = {
              type: "success", 
              title: this.formulario.status.model.key == this.$enums.statusUsuario.ATIVO.key ? "Parceiro ativado com sucesso!" : "Parceiro inativado com sucesso!",
              htmlBody: this.formulario.status.model.key == this.$enums.statusUsuario.ATIVO.key ? "<p>O parceiro <strong>" + this.usuario.nome + "</strong> está ativo e poderá realizar simulações no portal.</p>" : "<p>O parceiro <strong>" + this.usuario.nome + "</strong> foi inativo e não poderá acessar o portal.</p>",
              botaoFechar: "Fechar janela"
            }
          } else {
             this.alertModal = {
              type: "success", 
              title: "Parceiro editado com sucesso", 
              htmlBody: `<p>O parceiro ${this.formulario.nome} foi editado com sucesso.</p>` , 
              botaoFechar: "Fechar janela"
            }
            this.dadoUsuario()
          }
        } else {
          this.alert = {
            type: 'danger',
            message: result ? result.message : ''
          };
        }
      }).finally(() => {
        this.loadingMessage = ""
      })       
      //this.alterarStatus();
    },
    submitFormSenha() {
      let data = {
        id: this.usuario.id,
        senha: this.formularioSenha.novaSenha,
      }
      UsuarioService.alterarSenhaUsuario(data).then(result => {
        if (result.data && result.data.success) {
          this.$bvModal.hide('modal-alterar-senha')
          this.alertModal = {
            type: "success", 
            title: "Senha alterada com sucesso", 
            htmlBody: "<p>A sua senha do Parceiro foi alterada. Ele receberá um e-mail com a nova senha e precisará alterar no primeiro acesso</p>", 
            botaoFechar: "Fechar janela"
          }
        } else {
          this.alert2 = {
            type: 'danger',
            message: result.data != null ? result.data.message : result.message
          };
        }
      });
    },
    alterarStatus(avisoPersonalizado = false) {
      let data = {
        id: this.usuario.id,
        status: this.formulario.status.model.key,
        perfil: this.formulario.perfil.model.key,
        taxa: this.formulario.taxa
      }
      UsuarioService.alterarStatus(data).then(result => {
        if (result.data && result.data.success) {
          this.usuario.status = this.formulario.status.model.key
          if (avisoPersonalizado) {
            this.$bvModal.hide('modal-inativar-parceiro')
            this.$bvModal.hide('modal-ativar-parceiro')
            this.alertModal = {
              type: "success",
              title: this.formulario.status.model.key == this.$enums.statusUsuario.ATIVO.key ? "Parceiro ativado com sucesso!" : "Parceiro inativado com sucesso!",
              htmlBody: this.formulario.status.model.key == this.$enums.statusUsuario.ATIVO.key ? "<p>O parceiro <strong>" + this.usuario.nome + "</strong> está ativo e poderá realizar simulações no portal.</p>" : "<p>O parceiro <strong>" + this.usuario.nome + "</strong> foi inativo e não poderá acessar o portal.</p>",
              botaoFechar: "Fechar janela"
            }
          } else {
            this.alert = {
              type: 'success',
              message: 'As alterações foram salvas com sucesso.',
              layout: 2
            };
          }
        } else {
          this.alert = {
            type: 'danger',
            message: result ? result.message : ''
          };
        }
      });
    },
  },
  computed: {
      validForm() {
        return this.formulario.email != "" 
          && this.formulario.empresa != "" 
          && this.formulario.cnpj != "" 
          && this.formulario.nome != "" 
          && this.formulario.telefone != "" 
          && this.formulario.status.model != null
          && this.formulario.perfil.model != null
          && this.formulario.taxa != "" 
          && (this.formulario.status.model.key !== this.usuario.status ||
              this.formulario.perfil.model.key !== this.usuario.perfil ||
              this.formulario.taxa !== this.usuario.taxa ||
              this.formulario.cnpj !== this.usuario.cnpj ||
              this.formulario.empresa !== this.usuario.nomeEmpresa ||
              this.formulario.nome !== this.usuario.nome ||
              this.formulario.telefone !== this.usuario.telefone ||
              this.formulario.email !== this.usuario.email)
      },
      validFormSenha() {
        return this.formularioSenha.novaSenha != "" 
          && this.formularioSenha.confirmeSenha != ""          
          && this.formularioSenha.confirmeSenha === this.formularioSenha.novaSenha
      },
  },
  watch: {
    'formulario' : {
      handler: function () {
        this.alert = null
      },
      deep: true
    },
    'formularioSenha' : {
      handler: function () {
        this.alert2 = null
      },
      deep: true
    },
  }
}
</script>
<style scoped lang="scss">
.container-cnpj-cadastro-parceiro {
  align-items: end;
}
</style>
