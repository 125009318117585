<template>
    <div v-if="items.length" class="table-container">
      <b-table class="custom-table" hover :items="items" :fields="fields" show-empty emptyText="Nenhum dado encontrado." sort-icon-left :busy="loading">
        <template #table-busy>
            <div class="text-center text-dark-gray my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Carregando informações...</strong>
            </div>
        </template>
      </b-table>
      <div class="d-flex justify-content-end">
        <router-link to="/propostas">Ver detalhes das propostas</router-link>
      </div>
  </div>
  <div v-else>
    <p v-if="loading">...</p>
    <p v-else>Você não possui nenhuma proposta.</p>
  </div>
</template>

<script>

import PropostaService from '@/services/creditPlus/proposta.service.js'

export default {
  name: 'PropostasDestaque',
  components: {
  },
  data() {
    return {
      fields: [
          { key: 'data', label: 'Data', sortable: false },
          { key: 'razaoSocial', label: 'Razão Social', sortable: false },
          { key: 'totalContrato', label: 'Valor do Projeto', sortable: false },
      ],
      items: [],
      loading: false,
    }
  },
  mounted() {
    this.listarPropostas();
  },
  methods: {
    listarPropostas() {
      this.loading = true;
      this.items = [];
      PropostaService.listarUltimas().then(result => {
        this.loading = false
        if (result.data && result.data.itens.length > 0) {
            result.data.itens.forEach(element => {
              this.items.push({
                data: this.$uteis.formatDate(element.dataCriacao),
                id: element.id,
                razaoSocial: element.simulacao.razaoSocial,
                totalContrato: 'R$ ' + this.$uteis.formatNumber(element.simulacao.valorTotalEquipamentos + element.simulacao.valorAdicional + element.simulacao.valorInstalacao),
              })
            });
        } else {
          this.alert = {
            type: 'danger',
            message: result ? result.message : ''
          };
        }
      });
    },
  }
}
</script>
<style lang="scss">
.label-status {
    padding: 2px 4px;
    border-radius: 6px;
    color: white;
    text-transform: uppercase;
    text-align: center;
    font-size: .8rem;
}
</style>
