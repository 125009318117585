import axios from "axios"
import store from '@/store/index.js'
import router from '@/router/router.js'

export default class ApiService {

    baseUrl = '';
    authorizationToken = "";

    constructor(baseUrl) {
        //this.baseUrl = "http://192.168.15.7:57839/api" + baseUrl;//local
        //this.baseUrl = "http://localhost:5000/api" + baseUrl;//local
        //this.baseUrl = "http://3.231.115.116:666/api" + baseUrl;//dev
        this.baseUrl = "https://parceiros.allugg.com.br:666/api" + baseUrl;//prod
    }

    setToken(token) {
        this.authorizationToken = `Bearer ${token}`;
    }
    cleanToken() {
        this.authorizationToken = "";
    }

    headers() {
        return {
            "Access-Control-Allow-Origin": '*',
            "Access-Control-Allow-Headers": '*',
            "Access-Control-Allow-Methods": '*',
            "Authorization": this.authorizationToken
        }
    }

    get(resource, data) {
        return axios({
            url: resource,
            method: 'get',
            baseURL: this.baseUrl,
            params: data,
            timeout: 0,
            responseType: 'json',
            headers: this.headers()
        });
    }
    postWithFile(resource, data, timeout = 0) {
        var headers = this.headers()
        headers['Content-Type'] = 'multipart/form-data'
        return axios({
            url: resource,
            method: 'post',
            baseURL: this.baseUrl,
            data: data,
            timeout: timeout,
            responseType: 'json',
            headers: headers
        });
    }
    post(resource, data) {
        return axios({
            url: resource,
            method: 'post',
            baseURL: this.baseUrl,
            data: data,
            timeout: 0,
            responseType: 'json',
            headers: this.headers()
        });
    }
    /*
        put(resource, data) {

        }
    */
    delete(resource, data) {
        return axios({
            url: resource,
            method: 'delete',
            baseURL: this.baseUrl,
            data: data,
            timeout: 0,
            responseType: 'json',
            headers: this.headers()
        });
    }

    defaultCatch(error) {
        if (error.response == null) {//Provavelmente API OFF ou apontamento incorreto para a mesma
            return {
                success: false,
                message: "Falha ao estabelecer conexão com a API, atualize a página e tente novamente."
            }
        }

        switch (error.response.status) {
            case 401: {
                if (store.getters.token) {
                    store.commit("setAuth", null);
                    router.push('/login');
                }
                if (Array.isArray(error.response.data)) {
                    let errors = "";
                    error.response.data.forEach(element => {
                        errors += "<p class='my-1'>" + element + "</p>";
                    });
                    return { success: false, message: errors };
                }
                return { success: false, message: 'Parece que algo não está funcionando corretamente, atualize a página e tente novamente.' };
            }
            case 400: {
                if (Array.isArray(error.response.data)) {
                    let errors = "";
                    error.response.data.forEach(element => {
                        errors += "<p class='my-1'>" + element + "</p>";
                    });
                    return { success: false, message: errors };
                }
                return { success: false, message: 'Parece que algo não está funcionando corretamente, atualize a página e tente novamente.' };
            }
            case 404: {
                if (Array.isArray(error.response.data)) {
                    let errors = "";
                    error.response.data.forEach(element => {
                        errors += "<p class='my-1'>" + element + "</p>";
                    });
                    return { success: false, message: errors };
                }
                return { success: false, message: 'Não foi encontrada a página ou recurso solicitado, atualize a página e tente novamente.' };
            }
            default:
                //console.log(error.response.data); //Console de debug
                return { success: false, message: 'Parece que algo não está funcionando corretamente, atualize a página e tente novamente. Code: ' + error.response.status };
        }

    }

}
