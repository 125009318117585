export const Enums = {
    perfis: {
        DEFAULT: { key: 0, value: 'Parceiro' },
        ADMIN: { key: 1, value: 'Admin' },
        SUPERADMIN: { key: 2, value: 'Super admin' },
    },
    statusUsuario: {
        PENDENTE: { key: 0, value: 'Pendente' },
        ATIVO: { key: 1, value: 'Ativo' },
        INATIVO: { key: 2, value: 'Inativo' },
        EXCLUIDO: { key: 3, value: 'Excluído' },
    },
    statusProposta: {
        SALVA: {key: 0, value: 'Salva', cor: '#333333' },
        ANALISE: {key: 1, value: 'Em Análise', cor: '#7878e8' },
        APROVADA: {key: 2, value: 'Aprovada', cor: '#00C803' },
        NEGADA: {key: 3, value: 'Negada', cor: '#D01D1D' },
        CANCELADA: {key: 4, value: 'Cancelada', cor: '#989898' },
        EXCLUIDA: {key: 5, value: 'Excluída', cor: '#989898' },
        EXCLUIDAADMIN: {key: 6, value: 'Excluída', cor: '#989898' },
    },
    tipoDocumento: {
        RG: { key: 0, value: 'RG do(s) sócio(s)' },
        CPF: { key: 1, value: 'CPF do(s) sócio(s)' },
        CONTRATOSOCIAL: { key: 2, value: 'Contrato Social' },
        CERTIDAONEGATIVA: { key: 3, value: 'Certidão Negativa' },
        ORCAMENTO: { key: 4, value: 'Orçamento' },
        FICHACADASTRAL : { key: 6, value: 'Ficha Cadastral' },
        COMPROVANTEENDERECO: { key: 7, value: 'Comprovante de endereço' },
        COMPROVANTEENDERECOSOCIOS: { key: 8, value: 'Comprovante de endereço do(s) sócio(s)' },
        CNH: { key: 9, value: 'CNH do(s) sócio(s)' },
        OUTROS: { key: 5, value: 'Outros' },
    },
    getArray: (obj) => {
        var array = [];
        Object.entries(obj).forEach(element => {
            array.push(element[1]);
        });
        return array;
    },
    getObjectByKey: (obj, key) => {
        var object = {};
        Object.entries(obj).forEach(element => {
            if (element[1].key == key) {
                object = element[1];
            }
        });
        return object;
    },
    getValueByKey: (obj, key) => {
        var object = Enums.getObjectByKey(obj, key)
        return object ? object.value : ''
    },
 }