<template>
  <div v-if="!enviado" class="fh-container container-view-recuperar-senha col-md-8 offset-md-2 ajuste-form-auth">
    <h1>Esqueceu sua senha?</h1>
    <p>Digite seu e-mail abaixo e enviaremos um link para você criar uma nova senha.</p>
      <form class="mt-1 fh-form" @submit.prevent="submitForm()" data-vv-scope="formulario">
        <div class="form-group">
            <label for="email">E-mail</label>
            <input :class="{'form-control': true, 'is-invalid': errors.has('formulario.email')}" placeholder="Digite seu e-mail" 
                v-model="formulario.email"  v-validate="'required|email'" type="email" name="email" />
            <span v-show="errors.has('formulario.email')" class="invalid-feedback">{{ errors.first('formulario.email') }}</span>
        </div>
        <CustomAlert :alert="alert"></CustomAlert>
        <div class="container-botoes-form">
          <button class="btn btn-custom primary default" type="submit" :disabled="!validForm">Receber e-mail</button>
          <router-link class="mt-2 mt-sm-0" to="/login"><CustomIcon :name="'chevron-left'"></CustomIcon>Voltar</router-link>
        </div>
      </form>
  </div>  
  <div v-else-if="!loading" class="container-view-recuperar-senha col-md-8 offset-md-2 ajuste-view-auth">
    <h1>Link enviado</h1>
    <p>Enviamos para o seu e-mail um link para você criar sua nova senha.</p>
    <p>Caso não visualize o e-mail na sua caixa de entrada, verifique na caixa de spam ou lixo eletrônico.</p>
    <div class="d-flex flex-row justify-content-end align-items-center">
      <router-link class="d-flex" to="/login"><CustomIcon :name="'chevron-left'"></CustomIcon>Voltar</router-link>
    </div>
  </div>
  <div v-else class="container-view-validar-email col-md-8 offset-md-2">
    carregando
  </div>
</template>

<script>
import AuthService from '@/services/creditPlus/auth.service.js'

export default {
  name: 'RecuperarSenha',
  data() {
      return {
        enviado: false,
          formulario: {
              email: '',
          },
          alert: null,
          loading: true,
      }
  },
  methods: {
    submitForm() {
      AuthService.recuperarSenha({email: this.formulario.email}).then(result => {
        this.loading = false;
        if (result && result.success) {
          this.enviado = result.data.success;
        }
        if (!this.enviado) {
          this.alert = {
            type: 'danger',
            message: result ? result.message : ''
          };
        }
      });
    }
  },
  computed: {
      validForm() {
          return this.formulario.email != ''
              && this.errors.items.length == 0
      }
  },
}
</script>

<style scoped lang="scss">
</style>
