<template>
  <div class="container-card view-parceiros">
    <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
      <div class="card custom-card">
        <div class="card-header">
          <h5 class="card-title">Criar parceiro</h5>
        </div>
        <div class="card-body">
          <form class="mt-1 fh-form" @submit.prevent="submitForm()" data-vv-scope="formulario">
            <div class="form-group d-flex container-cnpj-cadastro-parceiro">
              <div class="col-12 col-md-7 col-lg-5 p-0">
                <label for="cnpj">CNPJ</label>
                <TheMask :class="{'form-control': true, 'is-invalid': errors.has('formulario.cnpj')}" placeholder="Digite o CNPJ da empresa"
                    v-model="formulario.cnpj"  v-validate="'required|min:14|cnpj'" type="text" name="cnpj" autocomplete="off" :mask="'##.###.###/####-##'" :disabled="consultando"/>
                <span v-show="errors.has('formulario.cnpj')" class="invalid-feedback">{{ errors.first('formulario.cnpj') }}</span>
              </div>
              <div class="col-12 col-md-4 col-lg-6 p-0 pl-4">
                <CustomAlert :alert="alertCnpj"></CustomAlert>
              </div>
            </div>
            <div class="form-group col-12 col-md-7 col-lg-5 p-0">
              <label for="empresa">Empresa</label>
              <input :class="{'form-control': true, 'is-invalid': errors.has('formulario.empresa')}" placeholder="Que empresa ele representa?"
                  v-model="formulario.empresa"  v-validate="'required|min:4'" type="text" name="empresa" autocomplete="off"/>
              <span v-show="errors.has('formulario.empresa')" class="invalid-feedback">{{ errors.first('formulario.empresa') }}</span>
            </div>
            <div class="form-group col-12 col-md-7 col-lg-5 p-0">
              <label for="nome">Nome completo</label>
              <input :class="{'form-control': true, 'is-invalid': errors.has('formulario.nome')}" placeholder="Nome Completo"
                  v-model="formulario.nome"  v-validate="'required|nomeSobrenome|min:4'" type="text" name="nome" autocomplete="off"/>
              <span v-show="errors.has('formulario.nome')" class="invalid-feedback">{{ errors.first('formulario.nome') }}</span>
            </div>
            <div class="form-group col-12 col-md-7 col-lg-5 p-0">
              <label for="telefone">Telefone</label>
              <TheMask :class="{'form-control': true, 'is-invalid': errors.has('formulario.telefone')}" placeholder="(00) 00000-0000" :mask="['(##) ####-####', '(##) #####-####']"
                  v-model="formulario.telefone"  v-validate="'required|telefone'" type="tel" name="telefone" autocomplete="off"/>
              <span v-show="errors.has('formulario.telefone')" class="invalid-feedback">{{ errors.first('formulario.telefone') }}</span>
            </div>
            <div class="form-group col-12 col-md-7 col-lg-5 p-0">
              <label for="email">E-mail</label>
              <input :class="{'form-control': true, 'is-invalid': errors.has('formulario.email')}" placeholder="Digite o e-mail"
                  v-model="formulario.email"  v-validate="'required|email'" type="email" name="email" autocomplete="off"/>
              <span v-show="errors.has('formulario.email')" class="invalid-feedback">{{ errors.first('formulario.email') }}</span>
            </div>
            <div class="form-group col-12 col-md-7 col-lg-5 p-0">
              <label for="novaSenha">Senha</label>
              <div class="input-icon">
                <input :class="{'form-control':true, 'is-invalid': errors.has('formulario.novaSenha')}" placeholder="Digite uma senha" v-model="formulario.novaSenha"
                        v-validate="'required|min:6'" :type="senhaVisivel ? 'text' : 'password'" name="novaSenha" ref="novaSenha"  autocomplete="off"/>
                <div class="icon" @click="senhaVisivel = !senhaVisivel">
                  <CustomIcon :name="senhaVisivel ? 'hide' : 'show'"/>
                </div>
                <span v-show="errors.has('formulario.novaSenha')" class="invalid-feedback">{{ errors.first('formulario.novaSenha') }}</span>
              </div>
            </div>
            <div class="form-group col-12 col-md-7 col-lg-5 p-0">
              <label for="taxa">Perfil</label>
              <multiselect v-model="formulario.perfil.model" :options="formulario.perfil.options" placeholder="Selecione" label="value" track-by="key">
                <template slot="option" slot-scope="props">
                  <span class="">{{props.option.value}}</span>
                </template>
                <template slot="noResult">Nenhum resultado encontrado.</template>
              </multiselect>
            </div>
            <div class="form-group col-12 col-md-7 col-lg-5 p-0">
              <label for="taxa">Taxa de juros %</label>
                <money v-bind="money" :class="{'form-control': true, 'is-invalid': errors.has('formulario.taxa')}"
                v-model="formulario.taxa"  v-validate="'required|min_value:0.1|max_value:100'" type="text" name="taxa" autocomplete="off"/>
              <span v-show="errors.has('formulario.taxa')" class="invalid-feedback">{{ errors.first('formulario.taxa') }}</span>
            </div>
            <CustomAlert :alert="alert"></CustomAlert>
            <div class="container-botoes-form">
              <button class="btn btn-custom primary default" type="submit" :disabled="!validForm">Salvar alterações</button>
              <router-link class="font-weight-bold pointer mt-3 mt-sm-0" to="/parceiros">Voltar</router-link>
            </div>
          </form>
        </div>
      </div>
      <AlertModal :alertModal="alertModal" @fechar="alertModal = null; $router.push('/parceiros')"></AlertModal>
      <ModalLoading :message="loadingMessage"></ModalLoading>
  </div>
</template>

<script>

import UsuarioService from '@/services/creditPlus/usuario.service.js'
import Multiselect from 'vue-multiselect'
import { TheMask } from 'vue-the-mask'
import { cnpj } from 'cpf-cnpj-validator'

export default {
  name: 'CriarParceiro',
  components: {
    TheMask,
    Multiselect
  },
  data() {
    return {
      usuario: {},
      breadcrumb: [
        { text: 'Parceiros', href: '/parceiros' },
        { text: 'Cadastrar parceiro', active: true }
      ],
      formulario: {
        email: '',
        empresa: '',
        nome: '',
        telefone: '',
        novaSenha: '',
        confirmeSenha: '',
        cnpj: '',
        perfil: {
          model: null,
          options: []
        },
        taxa: ''
      },
      money: {
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: '%',
          precision: 2,
          masked: false
      },
      alertModal: null,
      alert: null,
      loading: false,
      consultando: false,
      alertCnpj: null,
      senhaVisivel: false,
      loadingMessage: '',
    }
  },
  methods: {
    submitForm() {
      let data = {
        nome: this.formulario.nome,
        email: this.formulario.email,
        senha: this.formulario.novaSenha,
        nomeEmpresa: this.formulario.empresa,
        telefone: this.formulario.telefone,
        cnpj: this.formulario.cnpj,
        perfil: this.formulario.perfil.model.key,
        taxa: this.formulario.taxa,
      }
      this.loadingMessage = "Salvando informações do novo parceiro."
      UsuarioService.criar(data).then(result => {
        if (result.data && result.data.id > 0) {
          this.alertModal = {
            type: "success",
            title: "Parceiro cadastrado com sucesso",
            htmlBody: `<p>O parceiro ${this.formulario.nome} foi cadastrado com sucesso. Ele receberá um e-mail com os dados e precisará alterar a senha no primeiro acesso.</p>` , 
            botaoFechar: "Fechar janela"
          }
        } else {
          this.alert = {
            type: 'danger',
            message: result ? result.message : ''
          };
        }
      }).finally(() => {
        this.loadingMessage = ""
      })
    },
  },
  mounted() {
    this.formulario.perfil.options = this.$enums.getArray(this.$enums.perfis).filter(d => d.key != this.$enums.perfis.SUPERADMIN.key);
    this.formulario.perfil.model = this.$enums.getArray(this.$enums.perfis).find(d => d.key == this.$enums.perfis.DEFAULT.key);
  },
  computed: {
      validForm() {
        return this.formulario.email != ""
          && this.formulario.empresa != ""
          && this.formulario.cnpj != ""
          && this.formulario.nome != ""
          && this.formulario.telefone != ""
          && this.formulario.novaSenha != ""
          && this.formulario.perfil != ""
          && this.formulario.taxa != ""
          && this.errors.items.length == 0
      }
  },
  watch: {
    'formulario' : {
      handler: function () {
        this.alert = null
      },
      deep: true
    },
    'formulario.cnpj': {
      handler: function (value) {
        this.alertCnpj = null
        if (cnpj.isValid(value)) {
          this.consultando = true
          this.alertCnpj = { message: 'Estamos consultando algumas informações do CNPJ informado.', type: 'info', layout: 3 }
          UsuarioService.consultarCNPJ({cnpj: value}).then(result => {
            if (result.success && result.data && result.data.success) {
              this.formulario.email = result.data.emailPrincipal
              this.formulario.empresa = result.data.nome
              this.formulario.telefone = result.data.telefonePrincipal
              this.alertCnpj = null
            } else {
              this.alertCnpj = { message: 'Não foi possivel recuperar os dados do CNPJ informado.', type: 'danger', layout: 3 }
              setTimeout(() => {
                this.alertCnpj = null
              }, 5000)
            }
          }).finally( () => {
            this.consultando = false
          })
        }
      },
    }
  }
}
</script>
<style scoped lang="scss">
.container-cnpj-cadastro-parceiro {
  align-items: end;
}
</style>
