import ApiService from '@/services/creditPlus/creditplus.api.service.js'
import store from '@/store/index.js'

const AnaliseService = {
    api: new ApiService("/analise"),
    
    consultarCNPJ: async function (dados) {
        this.api.setToken(store.getters.token);
        return await this.api.get("/consultarCNPJ", dados).then((response) => {
            return { success: true, data: response.data };
        }).catch(this.api.defaultCatch);
    },
    resultadoAnalise: async function (dados) {
        this.api.setToken(store.getters.token);
        return await this.api.post("/resultadoAnalise", dados).then((response) => {
            return { success: true, data: response.data };
        }).catch(this.api.defaultCatch);
    },
    abrirAnalise: async function (dados) {
        this.api.setToken(store.getters.token);
        return await this.api.post("/abrirAnalise", dados).then((response) => {
            return { success: true, data: response.data };
        }).catch(this.api.defaultCatch);
    },
}

export default AnaliseService;