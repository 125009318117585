<template>
    <div class="container-card view-ver-proposta container">
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
        <div v-if="!alert && proposta.id" class="card custom-card">
            <div class="card-header d-flex flex-row justyify-content-between align-items-center">
                <div class="d-flex flex-row card-title justyify-content-between w-100">
                    <div class="flex-grow-1"><h5>Proposta {{proposta.identificador}}</h5></div>
                    <router-link :to="'/impressao-proposta?id=' + $route.query.id" target="_blank"><CustomIcon :name="'print'" class="pointer" /></router-link>
                </div>
            </div>
            <div class="card-body pt-2 flex-column flex-md-column flex-lg-row">
                <div class="flex-1 ml-lg-5 ml-md-0 flex-row flex-lg-column infos-propostas">
                    <div class="status-proposta status mt-0">
                        <h6>Status da proposta</h6>
                        <p>{{$enums.getValueByKey($enums.statusProposta, proposta.status)}}</p>
                    </div>
                </div>
                <div class="flex-2">
                    <h3>Dados do contrato</h3>
                    <div class="mt-3">
                        <label>Valor total dos equipamentos</label>
                        <p class="m-0 font-weight-bold">{{ 'R$ ' + $uteis.formatNumber(this.proposta.simulacao.valorTotalEquipamentos) || ''}}</p>
                    </div>
                    <div class="mt-3">
                        <label>Valores adicionais (Software, etc)</label>
                        <p class="m-0 font-weight-bold">{{ 'R$ ' + $uteis.formatNumber(this.proposta.simulacao.valorAdicional) || ''}}</p>
                    </div>
                    <!-- <div class="mt-3">
                    <label>Valor do seu serviço mensal</label>
                    <p class="m-0 font-weight-bold">{{ 'R$ '+ $uteis.formatNumber(this.proposta.simulacao.valorDoServicoMensal) || ''}}</p>
                </div> -->
                    <div class="mt-3">
                        <label>Valor total da instalação dos equipamentos (Máximo 10%)</label>
                        <p class="m-0 font-weight-bold">{{ 'R$ ' + $uteis.formatNumber(this.proposta.simulacao.valorInstalacao) || ''}}</p>
                    </div>
                    <div class="mt-3">
                        <label>Valor Total a ser recebido pela {{$nome}}</label>
                        <p class="m-0 font-weight-bold">
                            {{ 'R$ ' + $uteis.formatNumber(this.proposta.simulacao.valorTotalEquipamentos + this.proposta.simulacao.valorAdicional + this.proposta.simulacao.valorDoServicoMensal + this.proposta.simulacao.valorInstalacao) ||
                            ''}}
                        </p>
                    </div>
                    <div class="mt-3">
                        <label>Prazo do contrato</label>
                        <p class="m-0 font-weight-bold">{{this.proposta.simulacao.prazoContrato || ''}} meses</p>
                    </div>
                    <div class="bg-dark-white mt-2 p-2">
                        <div class="mt-3">
                            <label>Valor estimado da parcela mensal ({{$nome}})</label>
                            <p class="font-weight-bold">
                                {{'R$ ' + $uteis.formatNumber($uteis.calculoParcela(proposta.simulacao.taxa, proposta.simulacao.totalRecebido, proposta.simulacao.prazoContrato, proposta.simulacao.incluirSeguro))}}
                            </p>
                        </div>
                        <div v-if="proposta.simulacao.incluirSeguro" class="mt-3">
                            <label>Valor estimado de Proteção e Danos</label>
                            <p class="font-weight-bold">{{'R$ ' + $uteis.formatNumber($uteis.calculoProtecao(proposta.simulacao.totalRecebido, proposta.simulacao.prazoContrato))}}</p>
                        </div>
                    </div>
                    <hr />
                    <h3>Dados complementares</h3>
                    <div class="mt-3">
                        <label>CNPJ</label>
                        <p class="m-0 font-weight-bold">{{ $uteis.formatCnpj(this.proposta.simulacao.cnpj) || ''}}</p>
                    </div>
                    <div class="mt-3">
                        <label>CEP</label>
                        <p class="m-0 font-weight-bold">{{ this.proposta.cep || ''}}</p>
                    </div>
                    <div class="mt-3">
                        <label>Cidade</label>
                        <p class="m-0 font-weight-bold">{{ this.proposta.cidade || ''}}</p>
                    </div>
                    <div class="mt-3">
                        <label>Telefone</label>
                        <p class="m-0 font-weight-bold">{{ $uteis.formatTelefone(this.proposta.telefone) || ''}}</p>
                    </div>
                    <div class="mt-3">
                        <label>Razão Social</label>
                        <p class="m-0 font-weight-bold">{{ this.proposta.simulacao.razaoSocial || ''}}</p>
                    </div>
                    <div class="mt-3">
                        <label>Estado</label>
                        <p class="m-0 font-weight-bold">{{ this.proposta.estado || ''}}</p>
                    </div>
                    <div class="mt-3">
                        <label>Endereço completo</label>
                        <p class="m-0 font-weight-bold">{{ this.proposta.endereco || ''}}</p>
                    </div>
                    <div class="mt-3">
                        <label>E-mail</label>
                        <p class="m-0 font-weight-bold">{{ this.proposta.email || ''}}</p>
                    </div>
                    <hr />
                    <h3>Descrição dos produtos</h3>
                    <p>{{proposta.descricaoProduto}}</p>
                    <hr />
                    <h3>Documentos</h3>
                    <div class="d-flex flex-row">
                        <div class="d-flex flex-column flex-1 mr-2">
                            <div class="d-contents" v-for="item in proposta.documentos.filter(d => proposta.documentos.indexOf(d) % 2 == 0 && d.model != null && d.fileModel != null )" :key="item.key">
                                <p class="mb-1">Tipo: {{item.model.value}}</p>
                                <p class="mb-0 font-weight-bold pointer" @click="abrirDocumento(item.fileModel.id)">{{item.fileModel.name}} <CustomIcon :name="'external-link'" :width="'18'" class="ml-2" /></p>
                            </div>
                        </div>
                        <div class="d-flex flex-column flex-1">
                            <div class="d-contents" v-for="item in proposta.documentos.filter(d => proposta.documentos.indexOf(d) % 2 == 1 && d.model != null && d.fileModel != null )" :key="item.key">
                                <p class="mb-1">Tipo: {{item.model.value}}</p>
                                <p class="mb-0 font-weight-bold pointer" @click="abrirDocumento(item.fileModel.id)">{{item.fileModel.name}} <CustomIcon :name="'external-link'" :width="'18'" class="ml-2" /></p>
                            </div>
                        </div>
                    </div>
                    <div v-if="proposta.justificativa" class="d-contents">
                        <hr />
                        <h3>Informações adicionais</h3>
                        <p>{{proposta.justificativa}}</p>
                    </div>
                    <hr class="d-print-none" />
                    <router-link class="btn btn-custom small secondary dark" to="/propostas">Voltar</router-link>
                </div>
            </div>
        </div>
        <CustomAlert :alert="alert"></CustomAlert>
        <AlertModal :alertModal="alertModal" @fechar="alertModal = null"></AlertModal>
        <ModalLoading :message="loadingMessage"></ModalLoading>
    </div>
</template>

<script>
    import PropostaService from "@/services/creditPlus/proposta.service.js"

    export default {
        name: "VerProposta",
        components: {},
        data() {
            return {
                alert: null,
                alertModal: null,
                loadingMessage: "",
                proposta: {
                    cep: "",
                    cidade: "",
                    dataCriacao: "",
                    dataUltimaEdicao: "",
                    descricaoProduto: "",
                    email: "",
                    endereco: "",
                    estado: "",
                    id: "",
                    idSimulacao: "",
                    justificativa: "",
                    status: "",
                    telefone: "",
                    identificador: "",
                    simulacao: null,
                    documentos: [],
                },
                breadcrumb: [
                    { text: "Propostas", href: "/propostas" },
                    { text: "Proposta", active: true },
                ],
            }
        },
        mounted() {
            this.dadosProposta()
        },
        methods: {
            dadosProposta() {
                PropostaService.dadosProposta(this.$route.query.id).then((result) => {
                    if (result.data) {
                        this.proposta.cep = result.data.cep
                        this.proposta.cidade = result.data.cidade
                        this.proposta.dataCriacao = result.data.dataCriacao
                        this.proposta.dataUltimaEdicao = result.data.dataUltimaEdicao
                        this.proposta.descricaoProduto = result.data.descricaoProduto
                        this.proposta.email = result.data.email
                        this.proposta.endereco = result.data.endereco
                        this.proposta.estado = result.data.estado
                        this.proposta.id = result.data.id
                        this.proposta.idSimulacao = result.data.idSimulacao
                        this.proposta.justificativa = result.data.justificativa
                        this.proposta.status = result.data.status
                        this.proposta.telefone = result.data.telefone
                        this.proposta.simulacao = result.data.simulacao
                        this.proposta.simulacao.totalRecebido = result.data.simulacao.valorTotalEquipamentos + result.data.simulacao.valorAdicional + result.data.simulacao.valorInstalacao
                        this.proposta.identificador = result.data.identificador

                        this.breadcrumb[this.breadcrumb.length - 1].text = "Proposta " + this.proposta.identificador
                        result.data.documentos.forEach((element) => {
                            this.proposta.documentos.push({ fileModel: { name: element.nome, id: element.id }, model: this.$enums.getObjectByKey(this.$enums.tipoDocumento, element.tipo) })
                        })
                    } else {
                        this.alert = {
                            type: "info",
                            message: result ? "Nenhum dado encontrado" : "",
                        }
                    }
                })
            },
            abrirDocumento(id) {
                this.loadingMessage = "Carregando arquivo..."
                PropostaService.baixarDocumentoPessoal({ id: id }).then((result) => {
                    if (result.success && result.data && result.data.success) {
                        let a = document.createElement("a")
                        a.href = result.data.documento
                        a.download = result.data.nome
                        a.click()
                    } else {
                        this.alertModal = {
                            type: "danger",
                            title: "Falha ao realizar download de documento!",
                            htmlBody: "<p>" + result.data.message + "</p>",
                            botaoFechar: "Fechar janela",
                        }
                    }
                    this.loadingMessage = ""
                })
            },
        },
    }
</script>
<style lang="scss">
    .view-ver-proposta {
        .card-body {
            display: flex;
            .status-proposta {
                border: 2px solid;
                border-radius: 10px;
                padding: 10px 15px;
                margin: 1.2rem 0;
                p {
                    margin-bottom: 05px;
                }
                h6 {
                    font-weight: bold;
                }
                &.status {
                    border-color: $light-gray;
                    h6 {
                        color: $light-gray;
                    }
                    p {
                        font-weight: bold;
                    }
                }
                &.status-vadu {
                    border-color: $warning;
                    h6 {
                        color: $warning;
                    }
                }
                &.status-serasa {
                    border-color: $danger;
                    h6 {
                        color: $danger;
                    }
                }
            }
        }
    }
    .infos-propostas {
        margin-left: auto;
        order: 2;
        @media (max-width: $min-lg) {
            margin-left: 0;
            order: 0;
        }
    }
</style>
