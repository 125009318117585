import ApiService from '@/services/creditPlus/creditplus.api.service.js'
import store from '@/store/index.js'

const PropostaService = {
    api: new ApiService("/proposta"),

    criar: async function (data) {
        this.api.setToken(store.getters.token);
        return await this.api.postWithFile("/criar", data, 300000).then((response) => {
            return { success: true, data: response.data };
        }).catch(this.api.defaultCatch);
    },
    editar: async function (data) {
        this.api.setToken(store.getters.token);
        return await this.api.postWithFile("/editar", data, 300000).then((response) => {
            return { success: true, data: response.data };
        }).catch(this.api.defaultCatch);
    },
    cancelar: async function (data) {
        this.api.setToken(store.getters.token);
        return await this.api.post("/cancelar", data).then((response) => {
            return { success: true, data: response.data };
        }).catch(this.api.defaultCatch);
    },
    salvarFechamento: async function (data) {
        this.api.setToken(store.getters.token);
        return await this.api.post("/salvarFechamento", data).then((response) => {
            return { success: true, data: response.data };
        }).catch(this.api.defaultCatch);
    },
    salvarIntrodutorio: async function (data) {
        this.api.setToken(store.getters.token);
        return await this.api.post("/salvarIntrodutorio", data).then((response) => {
            return { success: true, data: response.data };
        }).catch(this.api.defaultCatch);
    },
    dadosProposta: async function (id) {
        this.api.setToken(store.getters.token);
        return await this.api.get("/dadosProposta", { id: id }).then((response) => {
            return { success: true, data: response.data };
        }).catch(this.api.defaultCatch);
    },
    dadosPropostaParceiro: async function (id) {
        this.api.setToken(store.getters.token);
        return await this.api.get("/dadosPropostaParceiro", { id: id }).then((response) => {
            return { success: true, data: response.data };
        }).catch(this.api.defaultCatch);
    },
    listar: async function (dados) {
        this.api.setToken(store.getters.token);
        return await this.api.get("/listar", dados).then((response) => {
            return { success: true, data: response.data };
        }).catch(this.api.defaultCatch);
    },
    listarUltimas: async function () {
        this.api.setToken(store.getters.token);
        return await this.api.get("/listar?ultimas=3").then((response) => {
            return { success: true, data: response.data };
        }).catch(this.api.defaultCatch);
    },
    alterarStatus: async function (dados) {
        this.api.setToken(store.getters.token);
        return await this.api.post("/alterarStatus", dados).then((response) => {
            return { success: true, data: response.data };
        }).catch(this.api.defaultCatch);
    },
    listarPropostasParceiros: async function (dados) {
        this.api.setToken(store.getters.token);
        return await this.api.get("/listarPropostasParceiros", dados).then((response) => {
            return { success: true, data: response.data };
        }).catch(this.api.defaultCatch);
    },
    listarParceirosComPropostas: async function () {
        this.api.setToken(store.getters.token);
        return await this.api.get("/listarParceirosComPropostas").then((response) => {
            return { success: true, data: response.data };
        }).catch(this.api.defaultCatch);
    },
    excluir: async function (data) {
        this.api.setToken(store.getters.token);
        return await this.api.delete("/excluir", data).then((response) => {
            return { success: true, data: response.data };
        }).catch(this.api.defaultCatch);
    },
    baixarDocumento: async function (data) {
        this.api.setToken(store.getters.token);
        return await this.api.post("/baixarDocumento", data).then((response) => {
            return { success: true, data: response.data };
        }).catch(this.api.defaultCatch);
    },
    baixarDocumentoPessoal: async function (data) {
        this.api.setToken(store.getters.token);
        return await this.api.post("/baixarDocumentoPessoal", data).then((response) => {
            return { success: true, data: response.data };
        }).catch(this.api.defaultCatch);
    },
    alterarDescricaoDocumentos: async function (data) {
        this.api.setToken(store.getters.token);
        return await this.api.post("/alterarDescricaoDocumentos", data).then((response) => {
            return { success: true, data: response.data };
        }).catch(this.api.defaultCatch);
    },
    buscarPorNomeEmpresa: async function (dados) {
        this.api.setToken(store.getters.token)
        return await this.api.get("/buscarPorNomeEmpresa", dados).then((response) => {
            return { success: true, data: response.data }
        }).catch(this.api.defaultCatch)
    },
}

export default PropostaService;