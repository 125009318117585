<template>
  <div v-if="!alterada" class="fh-container container-view-nova-senha col-md-8 offset-md-2 ajuste-form-auth">
    <h1>Criar nova senha</h1>
    <p>Digite sua nova senha nos campos abaixo. Tente esolher uma segura, com letras, números e caracteres especiais (#, @, !, *).</p>
      <form class="mt-1 fh-form" @submit.prevent="submitForm()" data-vv-scope="formulario">
        <div class="form-group">
          <label for="novaSenha">Nova senha</label>
          <div class="input-icon">
            <input :class="{'form-control':true, 'is-invalid': errors.has('formulario.novaSenha')}" placeholder="Digite sua nova senha" v-model="formulario.novaSenha" 
                    v-validate="'required|min:6'" :type="senhaVisivel ? 'text' : 'password'" name="novaSenha" ref="novaSenha" />
            <div class="icon" @click="senhaVisivel = !senhaVisivel">
             <CustomIcon :name="senhaVisivel ? 'hide' : 'show'"/>
            </div>
            <span v-show="errors.has('formulario.novaSenha')" class="invalid-feedback">{{ errors.first('formulario.novaSenha') }}</span>
          </div>
        </div>
        <div class="form-group">
          <label for="confirmeSenha">Confirme a nova senha</label>
          <input :class="{'form-control':true, 'is-invalid': errors.has('formulario.confirmeSenha')}" placeholder="Confirme sua senha" v-model="formulario.confirmeSenha" 
                  v-validate="'required|confirmed:novaSenha'" :type="'password'" name="confirmeSenha" />                
          <span v-show="errors.has('formulario.confirmeSenha')" class="invalid-feedback">{{ errors.first('formulario.confirmeSenha') }}</span>
        </div>
        <CustomAlert :alert="alert"></CustomAlert>
        <div class="container-botoes-form">
          <button class="btn btn-custom primary default" type="submit" :disabled="!validForm">Criar nova senha</button>
          <router-link class="mt-2 mt-sm-0" to="/login"><CustomIcon :name="'chevron-left'"></CustomIcon>Ir para o login</router-link>
        </div>
      </form>
  </div>
  <div v-else class="container-view-nova-senha col-12 col-sm-8 offset-sm-2">
    <h1>Sua nova senha foi criada!</h1>
    <p>Agora você já pode acessar o simulador com seu e-mail e sua nova senha.</p>
    <div class="d-flex flex-row align-items-center mt-3">
      <router-link class="btn btn-custom primary default" to="/login">Ir para o login</router-link>
    </div>
  </div>
</template>

<script>
import AuthService from '@/services/creditPlus/auth.service.js'

export default {
  name: 'NovaSenha',
  data() {
      return {
        alterada: false,
          formulario: {
              novaSenha: '',
              confirmeSenha: ''
          },
          loading: false,
          token: null,
          alert: null,
          senhaVisivel: false
      }
  },
  methods: {
    submitForm() {
        this.token = this.$route.query.token;
        if (this.token) {
          AuthService.alterarSenha({token: this.token, senha: this.formulario.novaSenha}).then(result => {
            this.loading = false;
            if (result && result.success) {
              this.alterada = result.data.alterado;
            }
            if (!this.alterada) {
              this.alert = {
                type: 'danger',
                message: result ? result.message : ''
              };
            }
          });
        }
    }
  },
  computed: {
      validForm() {
          return this.formulario.novaSenha != ''
              && this.formulario.confirmeSenha != ''
              && this.errors.items.length == 0
      }
  },
}
</script>

<style scoped lang="scss">
.container-view-nova-senha {
   @media (max-width: $min-sm) {
        flex: 1 !important;
    }
    @media (min-width: $min-sm) {
      min-height: 60%;
      justify-content: center;
    }
    display: flex;
    flex-direction: column;
}
</style>
