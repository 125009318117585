import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
//Modules
import user from '@/store/modules/user.js'
import token from '@/store/modules/token.js'
import settings from '@/store/modules/settings.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user: user,
    token: token,
    settings: settings,
  },  
  plugins: [createPersistedState()],
})
