<template>
    <div class="d-contents">
        <div class="container-print-button">
            <div class="d-print-none" @click="print()"><CustomIcon :name="'print'" class="pointer" /></div>
        </div>
        <div class="container-card view-impressao-proposta container">
            <div v-if="!alert && proposta.id" class="card custom-card">
                <div class="card-header d-flex flex-column justyify-content-between align-items-center">
                    <img class="" src="img/lgo-allugg-color.svg">
                    <div class="d-flex flex-row card-title justyify-content-between w-100">
                        <div class="flex-grow-1"><h5>Proposta {{proposta.identificador}}</h5></div>
                        <p>{{dataHora}}</p>
                    </div>
                </div>
                <div class="card-body pt-2 flex-column">
                    <div class="mb-2">
                        <QuillEditor v-if="formulario.editarIntrodutorio" v-model="formulario.textoIntrodutorio" :options="quillOptions"/>
                        <div v-else v-html="proposta.textoIntrodutorio"></div>
                        <button class="btn btn-custom primary default small mt-2" v-if="formulario.editarIntrodutorio" @click="salvarIntrodutorio()">Salvar alterações</button>
                        <button class="btn btn-custom terciary default small mt-2 p-0" v-else  @click="formulario.editarIntrodutorio = true"><CustomIcon name="edit"></CustomIcon>EDITAR TEXTO DE INTRODUÇÃO</button>
                        <hr v-if="proposta.textoIntrodutorio || formulario.editarIntrodutorio"/>
                    </div>
                    <div class="flex-2">
                        <h3>Dados do contrato</h3>
                        <div class="bg-dark-white mt-2 p-2 d-flex flex-row">
                            <div class="flex-1">
                                <div class="mt-3">
                                    <label>Valores adicionais (Software, etc)</label>
                                    <p class="m-0 font-weight-bold">{{ 'R$ ' + $uteis.formatNumber(this.proposta.simulacao.valorAdicional) || ''}}</p>
                                </div>
                                <div class="mt-3">
                                    <label>Valor total da instalação dos equipamentos</label>
                                    <p class="m-0 font-weight-bold">{{ 'R$ ' + $uteis.formatNumber(this.proposta.simulacao.valorInstalacao) || ''}}</p>
                                </div>
                                <div class="mt-3">
                                    <label>Prazo do contrato</label>
                                    <p class="m-0 font-weight-bold">{{this.proposta.simulacao.prazoContrato || ''}} meses</p>
                                </div>
                            </div>
                            <div class="flex-1">
                                <div class="mt-3">
                                    <label>Valor estimado da parcela mensal ({{$nome}})</label>
                                    <p class="font-weight-bold">
                                        {{'R$ ' + $uteis.formatNumber($uteis.calculoParcela(proposta.simulacao.taxa, proposta.simulacao.totalRecebido, proposta.simulacao.prazoContrato, proposta.simulacao.incluirSeguro))}}
                                    </p>
                                </div>
                                <div v-if="proposta.simulacao.incluirSeguro" class="mt-3">
                                    <label>Valor estimado de Proteção e Danos</label>
                                    <p class="font-weight-bold">{{'R$ ' + $uteis.formatNumber($uteis.calculoProtecao(proposta.simulacao.totalRecebido, proposta.simulacao.prazoContrato))}}</p>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <h3>Dados complementares</h3>
                        <div class="d-flex flex-row">
                            <div class="flex-1">
                                <div class="mt-3">
                                    <label>CNPJ</label>
                                    <p class="m-0 font-weight-bold">{{ $uteis.formatCnpj(this.proposta.simulacao.cnpj) || ''}}</p>
                                </div>
                                <div class="mt-3">
                                    <label>CEP</label>
                                    <p class="m-0 font-weight-bold">{{ this.proposta.cep || ''}}</p>
                                </div>
                                <div class="mt-3">
                                    <label>Cidade</label>
                                    <p class="m-0 font-weight-bold">{{ this.proposta.cidade || ''}}</p>
                                </div>
                                <div class="mt-3">
                                    <label>Telefone</label>
                                    <p class="m-0 font-weight-bold">{{ $uteis.formatTelefone(this.proposta.telefone) || ''}}</p>
                                </div>
                            </div>
                            <div class="flex-1">
                                <div class="mt-3">
                                    <label>Razão Social</label>
                                    <p class="m-0 font-weight-bold">{{ this.proposta.simulacao.razaoSocial || ''}}</p>
                                </div>
                                <div class="mt-3">
                                    <label>Estado</label>
                                    <p class="m-0 font-weight-bold">{{ this.proposta.estado || ''}}</p>
                                </div>
                                <div class="mt-3">
                                    <label>Endereço completo</label>
                                    <p class="m-0 font-weight-bold">{{ this.proposta.endereco || ''}}</p>
                                </div>
                                <div class="mt-3">
                                    <label>E-mail</label>
                                    <p class="m-0 font-weight-bold">{{ this.proposta.email || ''}}</p>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <h3>Descrição dos produtos</h3>
                        <p>{{proposta.descricaoProduto}}</p>
                        <hr />
                        <h3>Documentos</h3>
                        <div class="d-flex flex-row">
                            <div class="d-flex flex-column flex-1 mr-2">
                                <div class="d-contents" v-for="item in proposta.documentos.filter(d => proposta.documentos.indexOf(d) % 2 == 0 && d.model != null && d.fileModel != null )" :key="item.key">
                                    <p class="mb-1">Tipo: {{item.model.value}}</p>
                                    <p class="mb-0 font-weight-bold">{{item.fileModel.name}}</p>
                                </div>
                            </div>
                            <div class="d-flex flex-column flex-1">
                                <div class="d-contents" v-for="item in proposta.documentos.filter(d => proposta.documentos.indexOf(d) % 2 == 1 && d.model != null && d.fileModel != null )" :key="item.key">
                                    <p class="mb-1">Tipo: {{item.model.value}}</p>
                                    <p class="mb-0 font-weight-bold">{{item.fileModel.name}}</p>
                                </div>
                            </div>
                        </div>
                        <div v-if="proposta.justificativa" class="d-contents">
                            <hr />
                            <h3>Informações adicionais</h3>
                            <p>{{proposta.justificativa}}</p>
                        </div>
                    </div>
                    <div class="mb-2">
                        <hr v-if="proposta.textoFechamento || formulario.editarFechamento"/>
                        <QuillEditor v-if="formulario.editarFechamento" v-model="formulario.textoFechamento" :options="quillOptions"/>
                        <div v-else v-html="proposta.textoFechamento"></div>
                        <button class="btn btn-custom primary default small mt-2" v-if="formulario.editarFechamento" @click="salvarFechamento()">Salvar alterações</button>
                        <button class="btn btn-custom terciary default small mt-2 p-0" v-else  @click="formulario.editarFechamento = true"><CustomIcon name="edit"></CustomIcon>EDITAR TEXTO DE FECHAMENTO</button>
                    </div>
                </div>
            </div>
            <CustomAlert :alert="alert"></CustomAlert>
            <AlertModal :alertModal="alertModal" @fechar="alertModal = null;"></AlertModal>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import PropostaService from "@/services/creditPlus/proposta.service.js"
    import moment from 'moment'
    import { quillEditor } from 'vue-quill-editor'
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'

    export default {
        name: "ImpressaoPropostaParceiro",
        components: {
            QuillEditor: quillEditor
        },
        data() {
            return {
                alert: null,
                alertModal: null,
                keyPrint: 0,
                proposta: {
                    cep: '',
                    cidade: '',
                    dataCriacao: '',
                    dataUltimaEdicao: '',
                    descricaoProduto: '',
                    email: '',
                    endereco: '',
                    estado: '',
                    id: '',
                    idSimulacao: '',
                    justificativa: '',
                    justificativaPropostaNegada: '',
                    status: '',
                    telefone: '',
                    identificador: '',
                    anotacoes: '',
                    simulacao: null,
                    parceiro: '',
                    documentos: [],
                    descricoes: [],
                    ratingSigla: '',
                    ratingSerasa: '',
                    analise: {},
                    textoIntrodutorio: '',
                    textoFechamento: '',
                },
                formulario : {
                    textoIntrodutorio: "INTRUDTORIO Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
                    textoFechamento: "FECHAMENTO Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
                    editarIntrodutorio: false,
                    editarFechamento: false
                },
                quillOptions: {
                    placeholder: 'Escreva sue texto aqui',
                    theme: 'snow',
                    debug: false,
                    readOnly: true,
                    bounds: '.container-quill',
                    modules: {
                        toolbar: [
                            [{ 'header': [1, 2, 3, 4, 5, 6, false]}],
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ 'list': 'ordered'}, {'list': 'bullet'}, { 'align': [] }],
                            [{ 'script': 'sub'}, {'script': 'super'}],
                            ['blockquote', 'link'],
                            [{ 'color': [] }, {'background': [] }, 'clean'],
                        ]
                    },
                },
            }
        },
        mounted() {
            this.dadosProposta()
        },
        methods: {
            salvarFechamento() {
                 PropostaService.salvarFechamento({id: parseInt(this.$route.query.id), texto: this.formulario.textoFechamento}).then((result) => {
                    if (result.success && result.data.success) {
                        this.proposta.textoFechamento = this.formulario.textoFechamento = result.data.texto
                        this.formulario.editarFechamento = false
                    } else {
                        this.alertModal = {
                            type: "danger",
                            title: "Falha ao salvar o texto de fechamento",
                            htmlBody: `<p>Falha ao salvar o texto de fechamento, atualize a página e tente novamente.</p>` ,
                            botaoFechar: "Fechar janela"
                        }
                    }
                 })
            },
            salvarIntrodutorio() {
                 PropostaService.salvarIntrodutorio({id: parseInt(this.$route.query.id), texto: this.formulario.textoIntrodutorio}).then((result) => {
                    if (result.success && result.data.success) {
                        this.proposta.textoIntrodutorio = this.formulario.textoIntrodutorio = result.data.texto
                        this.formulario.editarIntrodutorio = false
                    } else {
                        this.alertModal = {
                            type: "danger",
                            title: "Falha ao salvar o texto de Introdução",
                            htmlBody: `<p>Falha ao salvar o texto de Introdução, atualize a página e tente novamente.</p>` ,
                            botaoFechar: "Fechar janela"
                        }
                    }
                 })
            },
            print() {
                this.formulario.editarIntrodutorio = false
                this.formulario.editarFechamento = false
                this.keyPrint++
                Vue.nextTick(() => {
                    this.$uteis.print()
                });
            },
            dadosProposta() {
                 PropostaService.dadosPropostaParceiro(this.$route.query.id).then((result) => {
                    if (result.data) {
                        this.proposta.cep = result.data.cep
                        this.proposta.cidade = result.data.cidade
                        this.proposta.dataCriacao = result.data.dataCriacao
                        this.proposta.dataUltimaEdicao = result.data.dataUltimaEdicao
                        this.proposta.descricaoProduto = result.data.descricaoProduto
                        this.proposta.email = result.data.email
                        this.proposta.endereco = result.data.endereco
                        this.proposta.estado = result.data.estado
                        this.proposta.id = result.data.id
                        this.proposta.idSimulacao = result.data.idSimulacao
                        this.proposta.justificativa = result.data.justificativa
                        this.proposta.justificativaPropostaNegada = result.data.justificativaPropostaNegada
                        this.proposta.status = result.data.status
                        this.proposta.telefone = result.data.telefone
                        this.proposta.simulacao = result.data.simulacao
                        this.proposta.simulacao.totalRecebido = result.data.simulacao.valorTotalEquipamentos + result.data.simulacao.valorAdicional + result.data.simulacao.valorInstalacao
                        this.proposta.identificador = result.data.identificador
                        this.proposta.anotacoes = result.data.anotacoes
                        this.proposta.parceiro = result.data.parceiro
                        this.proposta.ratingSigla = result.data.ratingSigla
                        this.proposta.analise = result.data.analiseItem
                        this.proposta.textoIntrodutorio = result.data.textoIntrodutorio
                        this.proposta.textoFechamento = result.data.textoFechamento

                        this.formulario.textoIntrodutorio = result.data.textoIntrodutorio
                        this.formulario.textoFechamento = result.data.textoFechamento
                        this.apontamentosVadu = result.data.logs
                        this.statusVadu = this.apontamentosVadu.filter((d) => d.bloqueio).length > 0 ? 'status-danger' : 'status-warning'

                        this.status = this.$enums.getObjectByKey(this.$enums.statusProposta, this.proposta.status)
                        this.proposta.documentos = []
                        result.data.documentos.forEach((element) => {
                            this.proposta.descricoes[element.id] = element.descricao
                            this.proposta.documentos.push({
                                fileModel: { name: element.nome, id: element.id },
                                model: this.$enums.getObjectByKey(this.$enums.tipoDocumento, element.tipo),
                                descricao: element.descricao,
                            })
                        })
                    } else {
                        this.alert = {
                            type: 'info',
                            message: result ? 'Nenhum dado encontrado' : '',
                        }
                    }
                })
            },
        },
        computed: {
            dataHora() {
                this.keyPrint // Menção para forçar computed
                let d = moment(new Date(), 'YYYY-MM-DD')
                return d.format('DD/MM - HH:mm')
            }
        }
    }
</script>
<style scoped lang="scss">
    .view-impressao-proposta {
        background-color: #F5FAFC !important;
        margin: 0 !important;
        padding: 10px 0 30px !important;
    }
    .container-print-button {
        background-color: #F5FAFC !important;
        > div {
            width: 27cm;
            margin: 0 auto;
            padding-top: 20px;
            display: flex;
            justify-content: flex-end;
        }
    }
    .view-impressao-proposta {
        //Configuração para o PDF
        .card {
            width: 27cm;
            margin: 0 auto;
            .card-body, .card-header {
                @media print {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }
                img {
                    max-height: 60px;
                    margin-bottom: 20px;
                }
            }
        }
        .card-body {
            display: flex;
            .status-proposta {
                border: 2px solid;
                border-radius: 10px;
                padding: 10px 15px;
                margin: 1.2rem 0;
                p {
                    margin-bottom: 05px;
                }
                h6 {
                    font-weight: bold;
                }
                &.status {
                    border-color: $light-gray;
                    h6 {
                        color: $light-gray;
                    }
                    p {
                        font-weight: bold;
                    }
                }
                &.status-vadu {
                    border-color: $warning;
                    h6 {
                        color: $warning;
                    }
                }
                &.status-serasa {
                    border-color: $danger;
                    h6 {
                        color: $danger;
                    }
                }
            }
        }
    }
</style>
<style lang="scss">
    .ql-editor {
        min-height: 100px;
    }
    .ql-toolbar, .ql-container {
        font-family: 'Poppins', sans-serif;
    }
</style>