<template>
    <div class="container-card view-nova-proposta container">
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
        <div class="card custom-card">
            <div class="card-header">
                <h5 class="card-title">{{titulo}}</h5>
            </div>
            <div class="card-body pt-2">
                <form-proposta v-if="!alert" :simulacao="simulacao" :proposta="proposta" @atualizarTitulo="atualizarTitulo"/>
                <CustomAlert :alert="alert"></CustomAlert>
            </div>
        </div>
    </div>
</template>

<script>
import PropostaService from '@/services/creditPlus/proposta.service.js'
import FormProposta from '@/views/Proposta/FormProposta.vue'
export default {
  name: 'EditarProposta',
  components: {
      FormProposta
  },
  data() {
  return {
      alert: null,
      titulo: 'Editar proposta',
      proposta: {
        id: '',
        dataCriacao: '',
        dataUltimaEdicao: '',
        idSimulacao: '',
        cep: '',
        estado: '',
        cidade: '',
        endereco: '',
        telefone: '',
        email: '',
        descricaoProduto: '',
        justificativa: '',
        justificativaPropostaNegada: '',
        status: '',
        identificador: '',
        documentos: [],
      },
      simulacao: {
            id : '',
            data : '',
            cnpj : '',
            razaoSocial : '',
            PrazoContrato: 0,
            valorTotalEquipamentos : 0,
            valorDoServicoMensal : 0,
            totalRecebido : 0,
      },
      breadcrumb: [
        { text: 'Propostas', href: '/propostas' },
      ],
    }
  },
  mounted() {
      this.dadosSimulacao();
  },
  methods: {
    atualizarTitulo(titulo) {
      this.titulo = titulo
    },
    dadosSimulacao() {
      PropostaService.dadosProposta(this.$route.query.id).then(result => {
        if (result.data) {
                this.proposta.id = result.data.id
                this.proposta.dataCriacao = result.data.dataCriacao
                this.proposta.dataUltimaEdicao = result.data.dataUltimaEdicao
                this.proposta.idSimulacao = result.data.idSimulacao
                this.proposta.cep = result.data.cep || ''
                this.proposta.estado = result.data.estado || ''
                this.proposta.cidade = result.data.cidade || ''
                this.proposta.endereco = result.data.endereco || ''
                this.proposta.telefone = result.data.telefone || ''
                this.proposta.email = result.data.email || ''
                this.proposta.descricaoProduto = result.data.descricaoProduto || ''
                this.proposta.justificativa = result.data.justificativa || ''
                this.proposta.justificativaPropostaNegada = result.data.justificativaPropostaNegada || ''
                this.proposta.status = result.data.status
                this.proposta.identificador = result.data.identificador

                this.simulacao.data = this.$uteis.formatDate(result.data.simulacao.dataCriacao)
                this.simulacao.id = result.data.simulacao.id
                this.simulacao.cnpj = this.$uteis.formatCnpj(result.data.simulacao.cnpj)
                this.simulacao.razaoSocial = result.data.simulacao.razaoSocial
                this.simulacao.prazo = result.data.simulacao.prazoContrato
                this.simulacao.prazoContrato = result.data.simulacao.prazoContrato + " meses"
                this.simulacao.seguro = result.data.simulacao.incluirSeguro
                this.simulacao.valorTotalEquipamentos = result.data.simulacao.valorTotalEquipamentos
                this.simulacao.valorDoServicoMensal = result.data.simulacao.valorDoServicoMensal
                this.simulacao.valorAdicional = result.data.simulacao.valorAdicional
                this.simulacao.valorInstalacao = result.data.simulacao.valorInstalacao
                this.simulacao.totalRecebido = result.data.simulacao.valorTotalEquipamentos + result.data.simulacao.valorAdicional + result.data.simulacao.valorInstalacao
                this.simulacao.taxa = result.data.simulacao.taxa
                this.simulacao.analiseItem = result.data.simulacao.analiseItem

                result.data.documentos.forEach(element => {
                  this.proposta.documentos.push({fileModel: {name: element.nome}, model: this.$enums.getObjectByKey(this.$enums.tipoDocumento, element.tipo)})                  
                });

                this.breadcrumb.push({ text: 'Proposta ' + this.proposta.identificador, active: true })
        } else {
          this.alert = {
            type: 'info',
            message: result ? 'Código da simulação inválido' : ''
          };
        }
      });
    },
  },
}
</script>
<style lang="scss">
</style>