import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js'
import {Uteis} from '@/services/uteis.service.js'
import {Enums} from '@/enums.js'

//Layouts
import BaseLayout from '@/components/layout/BaseLayout.vue'
import LoginLayout from '@/components/layout/LoginLayout.vue'

//Views
import Home from '@/views/Home.vue'
import Login from '@/views/Auth/Login.vue'
import RecuperarSenha from '@/views/Auth/RecuperarSenha.vue'
import NovaSenha from '@/views/Auth/NovaSenha.vue'
import RecadastrarSenha from "@/views/Auth/RecadastrarSenha.vue"
import Cadastro from '@/views/Auth/Cadastro.vue'
import ValidarEmail from '@/views/Auth/ValidarEmail.vue'
import MinhaConta from '@/views/Perfil/MinhaConta.vue'
import Parceiros from '@/views/Parceiros/Parceiros.vue'
import ParceirosInativos from '@/views/Parceiros/ParceirosInativos.vue'
import CriarParceiro from '@/views/Parceiros/CriarParceiro.vue'
import EditarParceiro from '@/views/Parceiros/EditarParceiro.vue'
import NovaSimulacao from '@/views/Simulacao/NovaSimulacao.vue'
import Simulacoes from '@/views/Simulacao/Simulacoes.vue'
import VerSimulacoes from '@/views/Simulacao/VerSimulacao.vue'
import EditarSimulacao from '@/views/Simulacao/EditarSimulacao.vue'
import NovaProposta from '@/views/Proposta/NovaProposta.vue'
import EditarProposta from '@/views/Proposta/EditarProposta.vue'
import Propostas from '@/views/Proposta/Propostas.vue'
import VerProposta from '@/views/Proposta/VerProposta.vue'
import ImpressaoProposta from '@/views/Proposta/ImpressaoProposta.vue'
import ImpressaoPropostaParceiro from '@/views/Proposta/ImpressaoPropostaParceiro.vue'
import PropostasParceiros from '@/views/Proposta/PropostasParceiros.vue'
import PropostasParceirosExcluidos from '@/views/Proposta/PropostasParceirosExcluidos.vue'
import EditarPropostaParceiro from '@/views/Proposta/EditarPropostaParceiro.vue'
import AuthService from '@/services/creditPlus/auth.service.js'
import PatchNote from '@/views/PatchNote.vue'
import TaxasParceiros from '@/views/Parceiros/TaxasParceiros.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: function() { return Uteis.goHome(store.getters.auth) } },
  { path: '/logout', redirect: function() {
      AuthService.logout()
      return Uteis.logout()
    }
  },
  { path: '/admin', redirect: '/parceiros' },

  //sem Layout / layout interno
  { path: '/nova-simulacao', name: 'Nova simulação', component: NovaSimulacao, meta: 'default' },
  { path: '/patchnotes', name: 'Patch notes', component: PatchNote, meta: 'everyone' },
  { path: '/impressao-proposta', name: 'Impressão Proposta', component: ImpressaoProposta, meta: 'default' },
  { path: '/impressao-proposta-parceiro', name: 'Impressão Proposta Parceiro', component: ImpressaoPropostaParceiro, meta: 'admin' },
    //Layout com Background
  { path: '/', component: LoginLayout, children: [
    { path: '/recadastrar-senha', name: 'Recadastrar Senha', component: RecadastrarSenha, meta: 'authenticated' },
    { path: '/login', name: 'Login', component: Login, meta: 'guest' },
    { path: '/recuperar-senha', name: 'Recuperar Senha', component: RecuperarSenha, meta: 'guest' },
    { path: '/nova-senha', name: 'Nova Senha', component: NovaSenha, meta: 'guest' },
    { path: '/cadastro', name: 'Cadastro', component: Cadastro, meta: 'guest' },
    { path: '/validar-email', name: 'Validar Email', component: ValidarEmail, meta: 'guest' },
  ]},
  //Layout Base com header
  { path: '/', component: BaseLayout, children: [
    { path: '/home', name: 'Home', component: Home, meta: 'default' },
    { path: '/minha-conta', name: 'Minha Conta', component: MinhaConta, meta: 'authenticated' },
    { path: '/parceiros', name: 'Parceiros', component: Parceiros, meta: 'admin' },
    { path: '/parceiros-inativos', name: 'Parceiros Inativos', component: ParceirosInativos, meta: 'admin' },
    { path: '/editar-parceiro', name: 'Editar parceiro', component: EditarParceiro, meta: 'admin' },
    { path: '/criar-parceiro', name: 'Criar parceiro', component: CriarParceiro, meta: 'admin' },
    { path: '/simulacoes', name: 'Simulações', component: Simulacoes, meta: 'default' },
    { path: '/ver-simulacao', name: 'Detalhes simulação', component: VerSimulacoes, meta: 'default' },
    { path: '/editar-simulacao', name: 'Editar simulação', component: EditarSimulacao, meta: 'default' },
    { path: '/nova-proposta', name: 'Nova proposta', component: NovaProposta, meta: 'default' },
    { path: '/editar-proposta', name: 'Editar proposta', component: EditarProposta, meta: 'default' },
    { path: '/propostas', name: 'Propostas', component: Propostas, meta: 'default' },
    { path: '/ver-proposta', name: 'Ver Proposta', component: VerProposta, meta: 'default' },
    { path: '/propostas-parceiros', name: 'Propostas dos parceiros', component: PropostasParceiros, meta: 'admin' },
    { path: '/propostas-parceiros-excluidas', name: 'Propostas excluidas dos parceiros', component: PropostasParceirosExcluidos, meta: 'admin' },
    { path: '/editar-proposta-parceiro', name: 'Editar Proposta Parceiro', component: EditarPropostaParceiro, meta: 'admin' },
    { path: '/taxas', name: 'Taxas dos Parceiros', component: TaxasParceiros, meta: 'admin' },
  ]}

];

const router = new VueRouter({
  mode: 'history',
  routes,
  base: '/'
});

router.beforeEach((to, _from, next) => {
  const auth = store.getters.auth
  switch (to.meta) {
    case 'everyone':
      next(true)
    break;
    case 'guest':
      next((auth.token == null) ? true : Uteis.goHome(auth))
    break;
    case 'authenticated':
      next((auth.token != null) ? true : Uteis.logout())
    break;
    case 'default':
      next((auth.token != null && auth.perfil == Enums.perfis.DEFAULT.key) ? true : Uteis.logout())
    break;
    case 'admin':
      next((auth.token != null && (auth.perfil == Enums.perfis.ADMIN.key || auth.perfil == Enums.perfis.SUPERADMIN.key)) ? true : Uteis.logout())
    break;
    default:
      next(auth.token != null ? Uteis.goHome(auth) : Uteis.logout())
    break;
  }
  if (auth.altS && to.path != '/recadastrar-senha') {
    next('/recadastrar-senha')
  }
});

router.afterEach((to) => {
  document.title = "Allugg" + (to.name == undefined ? "" : " | " + to.name);
});

export default router;