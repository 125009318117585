<template>
  <div v-if="!criado" class="fh-container container-view-cadastro col-md-8 offset-md-2 ajuste-form-auth">
    <h1>Seja um parceiro</h1>
    <p>Caso tenha interesse em ser um parceiro da {{$nome}}, preencha os campos abaixo.</p>
    <form class="mt-1 fh-form" @submit.prevent="submitForm()" data-vv-scope="formulario">
      <div class="form-group">
        <label for="cnpj">CNPJ</label>
        <TheMask :class="{'form-control': true, 'is-invalid': errors.has('formulario.cnpj')}" placeholder="Digite o CNPJ da empresa" 
            v-model="formulario.cnpj"  v-validate="'required|min:14|cnpj'" type="text" name="cnpj" autocomplete="off" :mask="'##.###.###/####-##'"/>
        <span v-show="errors.has('formulario.cnpj')" class="invalid-feedback">{{ errors.first('formulario.cnpj') }}</span>
      </div>
      <div class="form-group">
        <label for="empresa">Empresa</label>
        <input :class="{'form-control': true, 'is-invalid': errors.has('formulario.empresa')}" placeholder="Que empresa você representa?" 
            v-model="formulario.empresa"  v-validate="'required|min:4'" type="text" name="empresa" autocomplete="off"/>
        <span v-show="errors.has('formulario.empresa')" class="invalid-feedback">{{ errors.first('formulario.empresa') }}</span>
      </div>
      <div class="form-group">
        <label for="nome">Nome completo</label>
        <input :class="{'form-control': true, 'is-invalid': errors.has('formulario.nome')}" placeholder="Nome Completo" 
            v-model="formulario.nome"  v-validate="'required|nomeSobrenome|min:4'" type="text" name="nome" autocomplete="off"/>
        <span v-show="errors.has('formulario.nome')" class="invalid-feedback">{{ errors.first('formulario.nome') }}</span>
      </div>
      <div class="form-group">
        <label for="telefone">Telefone</label>
        <TheMask :class="{'form-control': true, 'is-invalid': errors.has('formulario.telefone')}" placeholder="(00) 00000-0000" :mask="['(##) ####-####', '(##) #####-####']"
            v-model="formulario.telefone"  v-validate="'required|telefone'" type="tel" name="telefone" autocomplete="off"/>
        <span v-show="errors.has('formulario.telefone')" class="invalid-feedback">{{ errors.first('formulario.telefone') }}</span>
      </div>
      <div class="form-group">
        <label for="email">E-mail</label>
        <input :class="{'form-control': true, 'is-invalid': errors.has('formulario.email')}" placeholder="Digite seu e-mail" 
            v-model="formulario.email"  v-validate="'required|email'" type="email" name="email" autocomplete="off"/>
        <span v-show="errors.has('formulario.email')" class="invalid-feedback">{{ errors.first('formulario.email') }}</span>
      </div>
      <div class="form-group">
        <label for="novaSenha">Nova senha</label>
        <div class="input-icon">
          <input :class="{'form-control':true, 'is-invalid': errors.has('formulario.novaSenha')}" placeholder="Digite sua nova senha" v-model="formulario.novaSenha" 
                  v-validate="'required|min:6'" :type="senhaVisivel ? 'text' : 'password'" name="novaSenha" ref="novaSenha"  autocomplete="off"/>
          <div class="icon" @click="senhaVisivel = !senhaVisivel">
            <CustomIcon :name="senhaVisivel ? 'hide' : 'show'"/>
          </div>
          <span v-show="errors.has('formulario.novaSenha')" class="invalid-feedback">{{ errors.first('formulario.novaSenha') }}</span>
        </div>
      </div>
      <div class="form-group">
        <label for="confirmeSenha">Confirme a nova senha</label>
        <input :class="{'form-control':true, 'is-invalid': errors.has('formulario.confirmeSenha')}" placeholder="Confirme sua senha" v-model="formulario.confirmeSenha" 
                v-validate="'required|confirmed:novaSenha'" :type="'password'" name="confirmeSenha"  autocomplete="new-password"/>                
        <span v-show="errors.has('formulario.confirmeSenha')" class="invalid-feedback">{{ errors.first('formulario.confirmeSenha') }}</span>
      </div>
      <CustomAlert :alert="alert"></CustomAlert>
      <div class="container-botoes-form">
        <button class="btn btn-custom primary default" type="submit" :disabled="!validForm">Enviar dados</button>
      </div>
    </form>
  </div>
  <div v-else class="d-flex flex-column col-md-6 offset-md-3 container-view-cadastro ajuste-view-auth">
    <h1>Sua conta foi criada</h1>
    <p class="font-weight-bold destaque">{{formulario.nome}}</p>
    <p>Agora nós iremos analisar sua solicitação e entraremos em contato com você em breve. </p>
    <p>Caso tenha dúvidas, envie um e-mail para <a :href="'mailto:' + $emailContato">{{$emailContato}}</a>.</p>
    <!-- <div class="d-flex flex-row justify-content-end mt-2">
      <router-link class="d-flex" to="/login"><CustomIcon :name="'chevron-left'"></CustomIcon>Voltar para o login</router-link>
    </div> -->
  </div>
</template>

<script>
import { TheMask } from 'vue-the-mask'
import UsuarioService from '@/services/creditPlus/usuario.service.js'

export default {
  name: 'CadastroParceiro',
  components: {
    TheMask
  },
  data() {
    return {
      criado: false,
      formulario: {
        email: '',
        empresa: '',
        nome: '',
        telefone: '',
        novaSenha: '',
        confirmeSenha: '',
        cnpj: '',
      },
      alert: null,
      senhaVisivel: false
    }
  },
  methods: {
    submitForm() {
      let data = {
        nome: this.formulario.nome,
        email: this.formulario.email,
        senha: this.formulario.novaSenha,
        nomeEmpresa: this.formulario.empresa,
        telefone: this.formulario.telefone,
        cnpj: this.formulario.cnpj,
      }
      UsuarioService.cadastro(data).then(result => {
        if (result.data && result.data.id > 0) {
          this.criado = true;
        } else {
          this.alert = {
            type: 'danger',
            message: result ? result.message : ''
          };
        }
      });
    }
  },
  mounted() {
  },
  computed: {
      validForm() {
        return this.formulario.email != "" 
          && this.formulario.empresa != "" 
          && this.formulario.cnpj != "" 
          && this.formulario.nome != "" 
          && this.formulario.telefone != "" 
          && this.formulario.novaSenha != "" 
          && this.formulario.confirmeSenha != "" 
          && this.errors.items.length == 0
      }
  },
}
</script>
<style scoped lang="scss">
</style>
