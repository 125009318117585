<template>
    <div class="container-card view-propostas">
        <div class="card custom-card">
            <div class="card-header">
                <h5 class="card-title">Propostas</h5>
            </div>
            <div class="card-body">
                <p class="card-text">Veja abaixo as suas propostas encaminhadas à {{$nome}}.</p>
                <div class="table-container">
                    <b-table class="custom-table" hover :items="items" :fields="fields" show-empty emptyText="Nenhum dado encontrado."
                        sort-icon-left :busy="loading" no-local-sorting :sort-by.sync="sort.sortBy" :sort-desc.sync="sort.sortDesc" @sort-changed="reordenar">
                        <template #cell(status)="row">
                            <div class="label-status" :style="'background: ' + row.item.status.cor">
                                {{row.item.status.value}}
                            </div>
                        </template>
                        <template #cell(acoes)="row">
                            <div v-if="row.item.status.key == $enums.statusProposta.SALVA.key">
                                <CustomIcon class="text-custom gray" :name="'show'" />
                                <router-link :to="{ 'path' : '/editar-proposta', query: { 'id' : row.item.id }}">
                                    <CustomIcon class="mx-2" :name="'edit'" />
                                </router-link>
                                <div class="d-contents" @click="prepararExclucao(row.item)">
                                    <CustomIcon :name="'x'" class="text-custom danger pointer" />
                                </div>
                            </div>
                            <div v-else-if="row.item.status.key == $enums.statusProposta.ANALISE.key">
                                <router-link :to="{ 'path' : '/ver-proposta', query: { 'id' : row.item.id }}">
                                    <CustomIcon class="text-custom primary" :name="'show'" />
                                </router-link>
                                <router-link :to="{ 'path' : '/editar-proposta', query: { 'id' : row.item.id }}">
                                    <CustomIcon class="mx-2" :name="'edit'" />
                                </router-link>
                                <CustomIcon :name="'x'" class="text-custom gray" />
                            </div>
                            <div v-else>
                                <router-link :to="{ 'path' : '/ver-proposta', query: { 'id' : row.item.id }}">
                                    <CustomIcon class="text-custom primary" :name="'show'" />
                                </router-link>
                                <CustomIcon class="mx-2 text-custom gray" :name="'edit'" />
                                <CustomIcon :name="'x'" class="text-custom gray" />
                            </div>
                        </template>
                        <template #table-busy>
                            <div class="text-center text-dark-gray my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong class="ml-2">Carregando informações...</strong>
                            </div>
                        </template>
                    </b-table>
                </div>
            </div>
        </div>
        <b-modal id="modal-excluir-proposta" centered footerClass="d-flex justify-content-between">
            <template #modal-header>
                <h5 class="modal-title text-custom danger">Exclusão de proposta</h5>
                <button type="button" aria-label="Close" class="close" @click="$bvModal.hide('modal-excluir-proposta')">
                    <CustomIcon :name="'x'" />
                </button>
            </template>
            <p>Tem certeza que deseja excluir essa proposta? Ela não será encaminhada para a {{$nome}}.</p>
            <p class="text-custom danger font-weight-bold">Essa operação é irreversível!</p>
            <template #modal-footer>
                <button class="btn btn-link" @click="$bvModal.hide('modal-excluir-proposta')">Cancelar</button>
                <button class="btn btn-custom primary danger" @click="excluir()">Excluir</button>
            </template>
        </b-modal>
        <AlertModal :alertModal="alertModal" @fechar="alertModal = null" />
    </div>
</template>


<script>
import PropostaService from "@/services/creditPlus/proposta.service.js"

export default {
    name: "PropostasListagem",
    components: {},
    data() {
        return {
            fields: [
                { key: "dataCriacao", label: "Data", sortable: true },
                { key: "cnpj", label: "CNPJ", sortable: true },
                { key: "razaoSocial", label: "Razão Social", sortable: true },
                { key: "totalContrato", label: "Valor do Projeto", sortable: true },
                { key: "status", label: "Status", sortable: true },
                { key: "acoes", label: "", sortable: false, tdClass: "text-right" },
            ],
            idExcluir: null,
            items: [],
            alertModal: null,
            loading: false,
            sort: {
                sortBy: "dataCriacao",
                sortDesc: true,
            },
        }
    },
    mounted() {
        this.listarPropostas()
    },
    methods: {
        reordenar(ctx) {
            this.sort = {
                sortBy: ctx.sortBy,
                sortDesc: ctx.sortDesc,
            }
            this.listarPropostas()
        },
        prepararExclucao(data) {
            if (data) {
                this.idExcluir = data.id
                this.$bvModal.show("modal-excluir-proposta")
            }
        },
        excluir() {
            PropostaService.excluir({ id: this.idExcluir }).then((result) => {
                if (result.success && result.data && result.data.success) {
                    this.listarPropostas()
                    this.$bvModal.hide("modal-excluir-proposta")
                    //this.$bvModal.show('modal-excluir-proposta-sucesso')
                    this.alertModal = {
                        type: "success",
                        title: "Proposta excluída com sucesso!",
                        htmlBody: "",
                        botaoFechar: "Fechar janela",
                    }
                }
            })
        },
        listarPropostas() {
            this.loading = true
            this.items = []
            let dados = {
                "sort.sortBy": this.sort?.sortBy,
                "sort.sortDesc": this.sort?.sortDesc,
            }
            PropostaService.listar(dados).then((result) => {
                this.loading = false
                if (result.data && result.data.itens.length > 0) {
                    result.data.itens.forEach((element) => {
                        this.items.push({
                            dataCriacao: this.$uteis.formatDate(element.dataCriacao),
                            id: element.id,
                            cnpj: this.$uteis.formatCnpj(element.simulacao.cnpj),
                            razaoSocial: element.simulacao.razaoSocial,
                            totalContrato: "R$ " + this.$uteis.formatNumber(element.simulacao.valorTotalEquipamentos + element.simulacao.valorAdicional + element.simulacao.valorInstalacao),
                            status: this.$enums.getObjectByKey(this.$enums.statusProposta, element.status),
                            //status: this.$enums.getObjectByKey(this.$enums.statusProposta, 0),
                        })
                    })
                } else {
                    this.alert = {
                        type: "danger",
                        message: result ? result.message : "",
                    }
                }
            })
        },
    },
}
</script>

<style lang="scss">
    .label-status {
        padding: 2px 4px;
        border-radius: 6px;
        color: white;
        text-transform: uppercase;
        text-align: center;
        font-size: 0.8rem;
    }
</style>