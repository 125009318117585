<template>
    <div v-if="alert && alert.message" :class="classes + ' alert alert-' + (alert.type ? alert.type : 'info')" role="alert">
        <div class="mr-2">
            <CustomIcon :name="iconName"></CustomIcon>
        </div>
        <p class="mb-0" v-html="alert.message"></p>
    </div>
</template>

<script>
    export default {
        name: "CustomAlert",
        props: { alert: Object },
        data() {
            return {
                classes: "px-2 py-3 d-flex flex-row justify-content-center align-items-center",
            };
        },
        watch: {
            alert: {
                handler: function () {
                    this.classes = "px-2 py-3 d-flex flex-row justify-content-center align-items-center"
                    if (this.alert !== null && this.alert.layout == 2) {
                        this.classes = "px-2 py-2 d-flex flex-row align-items-center"
                    } else if (this.alert !== null && this.alert.layout == 3) {
                        this.classes = "px-2 py-2 d-flex flex-row justify-content-center align-items-center mb-0"
                    }
                },
                deep: true,
            },
        },
        computed: {
            iconName() {
                return this.$uteis.iconName(this.alert ? this.alert.type : "", true)
            },
        },
    }
</script>

<style scoped lang="scss">
    .alert {
        a {
            color: $white;
        }
    }
</style>
