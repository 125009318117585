import { render, staticRenderFns } from "./EditarProposta.vue?vue&type=template&id=43c76467&"
import script from "./EditarProposta.vue?vue&type=script&lang=js&"
export * from "./EditarProposta.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports