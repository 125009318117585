export const Dictionary = {
    pt_BR: {
        attributes: {
            novaSenha: '"Nova senha"',
            confirmeSenha: '"Confirme a sua nova senha"',
            descricaoProduto: '"Descrição do produto"',
            cep: "CEP",
            endereco: "Endereço",
            prazo: "Prazo do contrato",
            valorTotalEquipamentos: "Valor total dos equipamentos",
            taxa: "Taxa de juros",
            valorAdicional: "Valores adicionais",
            valorInstalacao: "Valor total da instalação",
        },
        custom: {
            novaSenha: {
                excluded: "A sua nova senha não pode ser igual a anterior"
            },
        },
    },
}