<template>
  <div v-if="validado" class="container-view-validar-email col-md-8 offset-md-2">
    <h1>Seu e-mail foi validado!</h1>
    <p>Agora nós iremos analisar a sua solicitação e entraremos em contato com você em breve.</p>
    <p>Caso tenha dúvidas, envie um e-mail para <a :href="'mailto:' + $emailContato">{{$emailContato}}</a>.</p>
    <div class="d-flex flex-row justify-content-end mt-2">
      <router-link class="d-flex" to="/login"><CustomIcon :name="'chevron-left'"></CustomIcon>Voltar para o login</router-link>
    </div>
  </div>
  <div v-else-if="!loading" class="container-view-validar-email col-md-8 offset-md-2">
    <h1>Ops, parece que temos um problema!</h1>
    <p>{{message}}</p>
    <div class="d-flex flex-row justify-content-end mt-2">
      <router-link class="d-flex" to="/login"><CustomIcon :name="'chevron-left'"></CustomIcon>Voltar para o login</router-link>
    </div>
  </div>
  <div v-else class="container-view-validar-email col-md-8 offset-md-2">
    carregando
  </div>
</template>

<script>
import AuthService from '@/services/creditPlus/auth.service.js'

export default {
  name: 'ValidarEmail',
  data() {
      return {
        loading: true,
        validado: false,
        token: null,
        message: "",
      }
  },
  methods: {
  },
  mounted() {
    this.token = this.$route.query.token;
    if (this.token) {
      AuthService.validarEmail({token: this.token}).then(result => {
        this.loading = false;
        this.validado = result && result.data && result.data.validado;
        if (!this.validado) {
          this.message = result.message
        }
      });
    }
  },
}
</script>

<style scoped lang="scss">
.container-view-validar-email {
   @media (max-width: $min-sm) {
        flex: 1 !important;
    }
    @media (min-width: $min-sm) {
      min-height: 60%;
      justify-content: center;
    }
    display: flex;
    flex-direction: column;
}
</style>
