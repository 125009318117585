<template>
    <div class="d-contents">
        <img class="d-none d-print-block logo" src="img/lgo-allugg-color.svg">
        <b-navbar toggleable="md" variant="white" class="shadow-sm rounded mb-2 header fixed-top">
            <b-navbar-brand class="py-0" href="/">
                <img class="logo" src="img/lgo-allugg-color.svg">
            </b-navbar-brand>
            <b-navbar-toggle target="nav-collapse">
                <template #default="{ expanded }">
                    <CustomIcon v-if="expanded" :name="'menu'"></CustomIcon>
                    <CustomIcon v-else :name="'menu'"></CustomIcon>
                </template>
            </b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav class="justify-content-between">
            <b-navbar-nav :class="'item-menu'">
                <b-nav-item class="text-center d-flex align-items-center" :class="{'active': item.destaque, 'd-md-none': item.menuUsuario, 'd-block': item.menuUsuario }" 
                    v-for="item in menu.filter(d => d.visivel)" :key="item.key" :href="item.url">
                    {{item.nome}}
                </b-nav-item>
            </b-navbar-nav>
            <b-navbar-nav class="d-none d-md-block">
                <b-nav-item-dropdown right>
                <template #button-content>
                    <CustomIcon :name="'user'"></CustomIcon>
                </template>
                <b-dropdown-item :href="item.url" v-for="item in menu.filter(d => d.menuUsuario && d.visivel)" :key="item.key">{{item.nome}}</b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
    export default {
        name: "CommomHeader",
        components: {},
        data() {
            return {
                auth: null,
                menu: [],
            };
        },
        mounted() {
            this.auth = this.$store.getters.auth;
            document.querySelector("body").classList.add("body-header")
            this.carregarMenu()
        },
        methods: {
            carregarMenu() {
                this.menu.push({ nome: "Início", url: "/", visivel: true, menuUsuario: false })
                this.menu.push({ nome: "Nova simulação", url: "/nova-simulacao", visivel: this.auth.perfil == this.$enums.perfis.DEFAULT.key, menuUsuario: false })
                this.menu.push({ nome: "Simulações gravadas", url: "/simulacoes", visivel: this.auth.perfil == this.$enums.perfis.DEFAULT.key, menuUsuario: false })
                this.menu.push({ nome: "Propostas", url: "/propostas", visivel: this.auth.perfil == this.$enums.perfis.DEFAULT.key, menuUsuario: false })
                this.menu.push({ nome: "Parceiros", url: "/parceiros", visivel: this.auth.perfil == this.$enums.perfis.ADMIN.key || this.auth.perfil == this.$enums.perfis.SUPERADMIN.key, menuUsuario: false })
                this.menu.push({ nome: "Propostas", url: "/propostas-parceiros", visivel: this.auth.perfil == this.$enums.perfis.ADMIN.key || this.auth.perfil == this.$enums.perfis.SUPERADMIN.key, menuUsuario: false })
                this.menu.push({ nome: "Perfil", url: "/minha-conta", visivel: true, menuUsuario: true })
                this.menu.push({ nome: "Logout", url: "/logout", visivel: true, menuUsuario: true })

                this.menu.forEach((element) => {
                    element.destaque = element.url == this.$route.path
                })
            },
        },
        watch: {
            "$route.path"(val) {
                if (this && this.menu) {
                    this.menu.forEach((element) => {
                        element.destaque = element.url == val
                    })
                }
            },
        },
    }
</script>

<style lang="scss">
    body.body-header {
        padding-top: 60px;
    }
    .navbar {
        padding: 0 1rem !important;
    }
    .navbar-collapse.show {
        padding: 2rem 0 1rem;
    }
    .navbar-collapse.show li {
        margin: 0 !important;
        padding: .25rem;
        border-bottom: 1px solid $light-gray;
        &:last-child {
            border-bottom: none;
        }
    }
    .nav-item.active {
        //text-decoration: underline;
        font-weight: bold;
        border-bottom: 3px solid $dark-gray;
    }
    .header {
        .logo {
            width: 80px;
            @media (min-width: $min-lg) {
                .logo {
                    width: 150px;
                }
            }
            @media (max-width: $min-sm) {
                .logo {
                    max-width: 120px;
                }
            }
        }
        .item-menu {
            li {
                margin: 0 1rem;
                padding: .7rem .35rem;
            }
        }
        .nav-link, .dropdown-item {
            text-decoration: none !important;
        }
        .dropdown-toggle {
            //outline-color: $light-gray;
            &::after{
                content: none;
            }
        }
        .dropdown {
            margin-left: 3rem;
        }
    }
    @media (max-width: $min-sm) {
        .header.shadow-sm {
            box-shadow: none !important;
        }
        .navbar {
            padding: 0.5rem 20px !important;
        }
        .navbar.mb-2 {
            margin-bottom: 0 !important;
        }
    }
    @media (max-width: $min-md) {
        .navbar {
            padding: .75rem 1rem !important;
        }
        .navbar-collapse {
            padding: 2rem 0 1rem;
        }
        .navbar-collapse li {
            margin: 0 !important;
            padding: .7rem .35rem;
        }
        .nav-item.active {
            border-bottom: none;
        }
    }
</style>