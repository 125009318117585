<template>
    <div class="container-card view-ver-simulacao container">
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
        <div v-if="!alert && simulacao.id" class="card custom-card">
            <div class="card-header d-flex flex-row justyify-content-between align-items-center">
                <div class="d-flex flex-row card-title justyify-content-between w-100">
                  <div class="flex-grow-1"><h5>Visualizar simulação</h5></div>
                  <div class="d-print-none" @click="$uteis.print"><CustomIcon :name="'print'" class="pointer"></CustomIcon></div>
                </div>
            </div>
            <div class="card-body">
                <h3 class="mb-2 mb-sm-3">Dados cadastrais</h3>
                <div class="d-flex flex-xl-row flex-column">
                    <div class="flex-2 mb-xl-0 mb-3">
                        <label>CNPJ</label>
                        <p class="m-0 font-weight-bold">{{$uteis.formatCnpj(this.simulacao.cnpj) || ''}}</p>
                    </div>
                    <div class="flex-2 mb-xl-0 mb-3">
                        <label>Razão social</label>
                        <p class="m-0 font-weight-bold">{{this.simulacao.razaoSocial || ''}}</p>
                    </div>
                    <button v-if="simulacao.altoRisco" id="botao-alto-risco" class="flex-1 btn btn-custom secondary danger events-none" variant="primary">
                        <CustomIcon class="mr-2" :name="'alert'"/>Alto índice de rejeição
                    </button>
                    <!--<b-tooltip target="botao-alto-risco" title="Online!" variant="danger" placement="bottom">Lorem lorem, Lero lero</b-tooltip>-->
                </div>
                <h3 class="titulo-dados-simulacao mb-2 mb-sm-3">Dados do contrato</h3>
                <div class="mt-3">
                    <label>Valor total dos bens</label>
                    <p class="m-0 font-weight-bold">{{ 'R$ '+ $uteis.formatNumber(this.simulacao.totalRecebido) || ''}}</p>
                </div>
                <div class="mt-3">
                    <label>Prazo do contrato</label>
                    <p class="m-0 font-weight-bold">{{ this.simulacao.prazoContrato || ''}}</p>
                </div>
                <div class="mt-3">
                    <label>Valor da parcela mensal ({{$nome}})</label>
                    <p class="m-0 font-weight-bold">{{ 'R$ ' + $uteis.formatNumber(this.parcelaCalculada) || ''}}</p>
                </div>
                <div v-if="simulacao.seguro" class="mt-3">
                    <label>Valor estimado de Proteção e Danos</label>
                    <p class="m-0 font-weight-bold">{{ 'R$ ' + $uteis.formatNumber(this.valorProtecaoeDanos) || ''}}</p>
                </div>
                <div class="mt-3">
                    <p v-if="simulacao.seguro" class="m-0 font-weight-bold"><CustomIcon :name="'check'"/> Proteção e Danos incluído</p>
                    <div v-else class="text-custom danger col-12 col-md-10 px-0 my-1">
                        <p class="m-0 font-weight-bold"><CustomIcon :name="'x'"/>Proteção e Danos não incluído</p>
                        <p class="text-custom danger">Prezado cliente, como você optou pela contratação sem proteção e danos, as condições do projeto poderão sofrer alterações em razão do risco envolvido. Caso queira incluir este item, clique em editar no botão abaixo.</p>
                    </div>
                </div>
                <div class="container-btns-simulacao d-print-none">
                    <router-link class="btn btn-custom secondary default mr-3" type="button" :to="'/editar-simulacao?id=' + simulacao.id">Editar</router-link>
                    <button class="btn btn-custom primary default" type="button" @click="registrar()">Registrar</button>
                </div>
                <div class="d-flex justify-content-between d-print-none">
                    <router-link class="font-weight-bold" to="/simulacoes">Voltar</router-link>
                    <a class="font-weight-bold text-custom danger pointer" v-b-modal.modal-excluir-simulacao>Excluir</a>
                </div>
            </div>
        </div>
        <CustomAlert :alert="alert"></CustomAlert>
        <b-modal id="modal-excluir-simulacao" centered footerClass="d-flex justify-content-between">
          <template #modal-header>
            <h5 class="modal-title text-custom danger">Exclusão de simulação</h5>
            <button type="button" aria-label="Close" class="close" @click="$bvModal.hide('modal-excluir-simulacao')">
              <CustomIcon :name="'x'" />
            </button>
          </template>
          <p>Você tem certeza que deseja excluir a simulação?</p>
          <p class="text-custom danger font-weight-bold">Essa operação é irreversível!</p>
          <template #modal-footer>
            <button class="btn btn-custom secondary dark" @click="$bvModal.hide('modal-excluir-simulacao')">Cancelar</button>
            <button class="btn btn-custom primary danger" @click="excluir()">Excluir</button>
          </template>
        </b-modal>
        <b-modal id="modal-excluir-simulacao-sucesso" centered footerClass="d-flex justify-content-between" hide-header-close hide-header @hidden="$router.push('/simulacoes')">
          <div class="text-success text-center mt-5 mb-3">
              <CustomIcon :name="'check-circle'" class="mb-2 text-success" :height="'34'" :width="'34'"></CustomIcon>
              <h5 class="text-success">Simulação excluída com sucesso!</h5>
          </div>
          <template #modal-footer>
              <div class="mx-auto">
                  <button type="button" class="btn btn-custom secondary default" @click="$bvModal.hide('modal-excluir-simulacao-sucesso')">Voltar para simulações gravadas</button>
              </div>
          </template>
        </b-modal>
    </div>
</template>

<script>
import SimulacaoService from '@/services/creditPlus/simulacao.service.js'

export default {
  name: 'VerSimulacao',
  components: {
  },
  data() {
  return {
      alert: null,
      parcelaCalculada: 0,
      valorProtecaoeDanos: 0,
      simulacao: {
            id : '',
            data : '',
            cnpj : '',
            razaoSocial : '',
            altoRisco: false,
            PrazoContrato: 0,
            valorTotalEquipamentos : 0,
            valorDoServicoMensal : 0,
            totalRecebido : 0,
      },
      breadcrumb: [
        { text: 'Simulações gravadas', href: '/simulacoes' },
        { text: 'Visualizar simulação', active: true }
      ],
    }
  },
  mounted() {
      this.dadosSimulacao();
  },
  methods: {
    excluir() {
      SimulacaoService.excluir({id: this.simulacao.id}).then(result => {
        if (result.success && result.data && result.data.id > 0) {
          this.$bvModal.hide('modal-excluir-simulacao')
          this.$bvModal.show('modal-excluir-simulacao-sucesso')
        }
      });
    },
    registrar() {
      this.$router.push('/nova-proposta?id=' + this.simulacao.id)
    },
    dadosSimulacao() {
      SimulacaoService.dadosSimulacao(this.$route.query.id).then(result => {
        if (result.data) {
                this.simulacao.data = this.$uteis.formatDate(result.data.dataCriacao)
                this.simulacao.id = result.data.id
                this.simulacao.cnpj = this.$uteis.formatCnpj(result.data.cnpj)
                this.simulacao.razaoSocial = result.data.razaoSocial
                this.simulacao.prazoContrato = result.data.prazoContrato + " meses"
                this.simulacao.seguro = result.data.incluirSeguro
                this.simulacao.valorTotalEquipamentos = result.data.valorTotalEquipamentos
                this.simulacao.valorDoServicoMensal = result.data.valorDoServicoMensal
                this.simulacao.totalRecebido = result.data.valorTotalEquipamentos + result.data.valorAdicional + result.data.valorInstalacao
                this.simulacao.altoRisco = result.data.analiseItem.ratingDescricao ? this.$uteis.calculaRisco(result.data.analiseItem.ratingDescricao) : false
                this.parcelaCalculada = this.$uteis.calculoParcela(result.data.taxa, this.simulacao.totalRecebido, result.data.prazoContrato, result.data.incluirSeguro)
                this.valorProtecaoeDanos = this.$uteis.calculoProtecao(this.simulacao.totalRecebido, result.data.prazoContrato)
            
        } else {
          this.alert = {
            type: 'info',
            message: result ? 'Nenhum dado encontrado' : ''
          };
        }
      });
    },
  },
}
</script>
<style lang="scss">
    .titulo-dados-simulacao {
        border-top: 1px solid $light-gray;
        margin-top: 1rem;
        padding-top: 1rem;
    }
    .container-btns-simulacao {
        border-top: 1px solid $light-gray;
        padding: 1rem 0;
        margin: 1rem 0 0 0;
    }
</style>