<template>
    <div class="d-contents">
        <div class="container-print-button">
            <div class="d-print-none" @click="keyPrint++; $uteis.print()"><CustomIcon :name="'print'" class="pointer" /></div>
        </div>
        <div class="container-card view-impressao-proposta container">
            <div v-if="!alert && proposta.id" class="card custom-card">
                <div class="card-header d-flex flex-column justyify-content-between align-items-center">
                    <img class="" src="img/lgo-allugg-color.svg">
                    <div class="d-flex flex-row card-title justyify-content-between w-100">
                        <div class="flex-grow-1"><h5>Proposta {{proposta.identificador}}</h5></div>
                        <p>{{dataHora}}</p>
                    </div>
                </div>
                <div class="card-body pt-2 flex-column">
                    <div class="mb-2">
                        <div v-html="proposta.textoIntrodutorio"></div>
                        <hr v-if="proposta.textoIntrodutorio"/>
                    </div>
                    <div class="flex-2">
                        <h3>Dados do contrato</h3>
                        <div class="bg-dark-white mt-2 p-2 d-flex flex-row">
                            <div class="flex-1">
                                <div class="mt-3">
                                    <label>Valores adicionais (Software, etc)</label>
                                    <p class="m-0 font-weight-bold">{{ 'R$ ' + $uteis.formatNumber(this.proposta.simulacao.valorAdicional) || ''}}</p>
                                </div>
                                <div class="mt-3">
                                    <label>Valor total da instalação dos equipamentos</label>
                                    <p class="m-0 font-weight-bold">{{ 'R$ ' + $uteis.formatNumber(this.proposta.simulacao.valorInstalacao) || ''}}</p>
                                </div>
                                <div class="mt-3">
                                    <label>Prazo do contrato</label>
                                    <p class="m-0 font-weight-bold">{{this.proposta.simulacao.prazoContrato || ''}} meses</p>
                                </div>
                            </div>
                            <div class="flex-1">
                                <div class="mt-3">
                                    <label>Valor estimado da parcela mensal ({{$nome}})</label>
                                    <p class="font-weight-bold">
                                        {{'R$ ' + $uteis.formatNumber($uteis.calculoParcela(proposta.simulacao.taxa, proposta.simulacao.totalRecebido, proposta.simulacao.prazoContrato, proposta.simulacao.incluirSeguro))}}
                                    </p>
                                </div>
                                <div v-if="proposta.simulacao.incluirSeguro" class="mt-3">
                                    <label>Valor estimado de Proteção e Danos</label>
                                    <p class="font-weight-bold">{{'R$ ' + $uteis.formatNumber($uteis.calculoProtecao(proposta.simulacao.totalRecebido, proposta.simulacao.prazoContrato))}}</p>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <h3>Dados complementares</h3>
                        <div class="d-flex flex-row">
                            <div class="flex-1">
                                <div class="mt-3">
                                    <label>CNPJ</label>
                                    <p class="m-0 font-weight-bold">{{ $uteis.formatCnpj(this.proposta.simulacao.cnpj) || ''}}</p>
                                </div>
                                <div class="mt-3">
                                    <label>CEP</label>
                                    <p class="m-0 font-weight-bold">{{ this.proposta.cep || ''}}</p>
                                </div>
                                <div class="mt-3">
                                    <label>Cidade</label>
                                    <p class="m-0 font-weight-bold">{{ this.proposta.cidade || ''}}</p>
                                </div>
                                <div class="mt-3">
                                    <label>Telefone</label>
                                    <p class="m-0 font-weight-bold">{{ $uteis.formatTelefone(this.proposta.telefone) || ''}}</p>
                                </div>
                            </div>
                            <div class="flex-1">
                                <div class="mt-3">
                                    <label>Razão Social</label>
                                    <p class="m-0 font-weight-bold">{{ this.proposta.simulacao.razaoSocial || ''}}</p>
                                </div>
                                <div class="mt-3">
                                    <label>Estado</label>
                                    <p class="m-0 font-weight-bold">{{ this.proposta.estado || ''}}</p>
                                </div>
                                <div class="mt-3">
                                    <label>Endereço completo</label>
                                    <p class="m-0 font-weight-bold">{{ this.proposta.endereco || ''}}</p>
                                </div>
                                <div class="mt-3">
                                    <label>E-mail</label>
                                    <p class="m-0 font-weight-bold">{{ this.proposta.email || ''}}</p>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <h3>Descrição dos produtos</h3>
                        <p>{{proposta.descricaoProduto}}</p>
                        <hr />
                        <h3>Documentos</h3>
                        <div class="d-flex flex-row">
                            <div class="d-flex flex-column flex-1 mr-2">
                                <div class="d-contents" v-for="item in proposta.documentos.filter(d => proposta.documentos.indexOf(d) % 2 == 0 && d.model != null && d.fileModel != null )" :key="item.key">
                                    <p class="mb-1">Tipo: {{item.model.value}}</p>
                                    <p class="mb-0 font-weight-bold">{{item.fileModel.name}}</p>
                                </div>
                            </div>
                            <div class="d-flex flex-column flex-1">
                                <div class="d-contents" v-for="item in proposta.documentos.filter(d => proposta.documentos.indexOf(d) % 2 == 1 && d.model != null && d.fileModel != null )" :key="item.key">
                                    <p class="mb-1">Tipo: {{item.model.value}}</p>
                                    <p class="mb-0 font-weight-bold">{{item.fileModel.name}}</p>
                                </div>
                            </div>
                        </div>
                        <div v-if="proposta.justificativa" class="d-contents">
                            <hr />
                            <h3>Informações adicionais</h3>
                            <p>{{proposta.justificativa}}</p>
                        </div>
                    </div>
                    <div class="mb-2">
                        <hr v-if="proposta.textoFechamento"/>
                        <div v-html="proposta.textoFechamento"></div>
                    </div>
                </div>
            </div>
            <CustomAlert :alert="alert"></CustomAlert>
        </div>
    </div>
</template>

<script>
    import PropostaService from "@/services/creditPlus/proposta.service.js"
    import moment from 'moment'

    export default {
        name: "ImpressaoProposta",
        components: {},
        data() {
            return {
                alert: null,
                keyPrint: 0,
                proposta: {
                    cep: '',
                    cidade: '',
                    dataCriacao: '',
                    dataUltimaEdicao: '',
                    descricaoProduto: '',
                    email: '',
                    endereco: '',
                    estado: '',
                    id: '',
                    idSimulacao: '',
                    justificativa: '',
                    status: '',
                    telefone: '',
                    identificador: '',
                    simulacao: null,
                    documentos: [],
                    textoIntrodutorio: '',
                    textoFechamento: '',
                },
            }
        },
        mounted() {
            this.dadosProposta()
        },
        methods: {
            dadosProposta() {
                PropostaService.dadosProposta(this.$route.query.id).then((result) => {
                    if (result.data) {
                        this.proposta.cep = result.data.cep
                        this.proposta.cidade = result.data.cidade
                        this.proposta.dataCriacao = result.data.dataCriacao
                        this.proposta.dataUltimaEdicao = result.data.dataUltimaEdicao
                        this.proposta.descricaoProduto = result.data.descricaoProduto
                        this.proposta.email = result.data.email
                        this.proposta.endereco = result.data.endereco
                        this.proposta.estado = result.data.estado
                        this.proposta.id = result.data.id
                        this.proposta.idSimulacao = result.data.idSimulacao
                        this.proposta.justificativa = result.data.justificativa
                        this.proposta.status = result.data.status
                        this.proposta.telefone = result.data.telefone
                        this.proposta.simulacao = result.data.simulacao
                        this.proposta.simulacao.totalRecebido = result.data.simulacao.valorTotalEquipamentos + result.data.simulacao.valorAdicional + result.data.simulacao.valorInstalacao
                        this.proposta.identificador = result.data.identificador
                        this.proposta.textoIntrodutorio = result.data.textoIntrodutorio
                        this.proposta.textoFechamento = result.data.textoFechamento

                        result.data.documentos.forEach((element) => {
                            this.proposta.documentos.push({ fileModel: { name: element.nome, id: element.id }, model: this.$enums.getObjectByKey(this.$enums.tipoDocumento, element.tipo) })
                        })
                    } else {
                        this.alert = {
                            type: 'info',
                            message: result ? 'Nenhum dado encontrado' : '',
                        }
                    }
                })
            },
        },
        computed: {
            dataHora() {
                this.keyPrint // Menção para forçar computed
                let d = moment(new Date(), 'YYYY-MM-DD')
                return d.format('DD/MM - HH:mm')
            }
        }
    }
</script>
<style scoped lang="scss">
    .view-impressao-proposta {
        background-color: #F5FAFC !important;
        margin: 0 !important;
        padding: 10px 0 30px !important;
    }
    .container-print-button {
        background-color: #F5FAFC !important;
        > div {
            width: 27cm;
            margin: 0 auto;
            padding-top: 20px;
            display: flex;
            justify-content: flex-end;
        }
    }
    .view-impressao-proposta {
        //Configuração para o PDF
        .card {
            width: 27cm;
            margin: 0 auto;
            .card-body, .card-header {
                @media print {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }
                img {
                    max-height: 60px;
                    margin-bottom: 20px;
                }
            }
        }
        .card-body {
            display: flex;
            .status-proposta {
                border: 2px solid;
                border-radius: 10px;
                padding: 10px 15px;
                margin: 1.2rem 0;
                p {
                    margin-bottom: 05px;
                }
                h6 {
                    font-weight: bold;
                }
                &.status {
                    border-color: $light-gray;
                    h6 {
                        color: $light-gray;
                    }
                    p {
                        font-weight: bold;
                    }
                }
                &.status-vadu {
                    border-color: $warning;
                    h6 {
                        color: $warning;
                    }
                }
                &.status-serasa {
                    border-color: $danger;
                    h6 {
                        color: $danger;
                    }
                }
            }
        }
    }
</style>