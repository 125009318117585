<template>
    <b-modal id="modal-loading" centered no-close-on-esc no-close-on-backdrop hide-header-close hide-header hide-footer>
        <div v-if="message">
            <div class="d-flex justify-content-center mb-4" :class="message.trim() ? ' mt-5' : 'mt-4' ">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Carregando...</span>
                </div>
            </div>
            <div class="my-4 text-center" v-html="message"></div>
        </div>
    </b-modal>
</template>

<script>
    export default {
        name: "ModalLoading",
        props: { message: String },
        data() {
            return {

            }
        },
        methods: {

        },
        computed: {

        },
        watch: {
            message: {
                handler: function () {
                    if (this.message != null && this.message != "") {
                        this.$bvModal.show("modal-loading")
                    } else {
                        this.$bvModal.hide("modal-loading")
                    }
                },
                deep: true,
            },
        },
    }
</script>
<style lang="scss"></style>
