<template>
    <DateRangePicker
        ref="picker"
        control-container-class="form-control"
        :opens="opens"
        :locale-data="localeData"
        :minDate="minDate" :maxDate="maxDate"
        :singleDatePicker="singleDatePicker"
        :timePicker="timePicker"
        :timePicker24Hour="timePicker24Hour"
        :showWeekNumbers="showWeekNumbers"
        :showDropdowns="showDropdowns"
        :autoApply="autoApply"
        v-model="dateRange"
        :ranges="ranges"
        :linkedCalendars="linkedCalendars"
        :timePickerIncrement="timePickerIncrement"
        :timePickerSeconds="timePickerSeconds"
        :closeOnEsc="closeOnEsc"
        :readonly="readonly">
        <div slot="input" slot-scope="data" class="slot input-slot">
            <span v-if="data.rangeText && data.rangeText.trim() != '-'">{{ data.rangeText }}</span>
            <span v-else class="placeholder-color">Todos</span>
        </div>
        <div slot="footer" slot-scope="data" class="slot footer-slot">
            <div class="d-flex flex-row mb-3 mx-3">
                <p class="ml-auto mr-3"> {{ data.rangeText && data.rangeText.trim() != '-' ? data.rangeText: 'Nenhuma data selecionada' }} </p>
                <button class="btn btn-custom primary default" :disabled="!dateRange.startDate && !dateRange.endDate" @click="dateRange.startDate = null; dateRange.endDate = null">Limpar</button>
            </div>
        </div>
    </DateRangePicker>
</template>
<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'


let today = new Date()
today.setHours(0, 0, 0, 0)

let month = today.getMonth()
let monthStart =  new Date(today.getFullYear(), month, 1)
let monthEnd =  new Date(today.getFullYear(), month + 1, 0)

export default {
    name: 'CustomDatePicker',
    props: {
        model: Object,
    },
    components: {
        DateRangePicker
    },
    data() {
        return {
            opens: 'center',
            localeData: {
                firstDay: 0,
                format: 'dd/mm/yyyy',
                applyLabel: 'Aplicar',
                cancelLabel: 'Cancelar',
                weekLabel: '',
                customRangeLabel: 'Custom Range',
                daysOfWeek: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
                monthNames: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            },
            minDate: null,
            maxDate: null,
            singleDatePicker: false,
            timePicker: false,
            timePicker24Hour: false,
            showWeekNumbers: true,
            showDropdowns: true,
            autoApply: true,
            dateRange: {
                startDate: this.model.datatFim,
                endDate: this.model.datatFim,
            },
            linkedCalendars: false,
            timePickerIncrement: 10,
            timePickerSeconds: false,
            closeOnEsc: true,
            readonly: false,
            ranges: {
                'Hoje': [today, today],
                'Última Semana': [new Date(today.getFullYear(), today.getMonth(), today.getDay() - 6), today],
                'Esse Mês': [monthStart, monthEnd],
                'Mês passado': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
                'Ano atual': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
            }
        }
    },
    methods: {
    },
    computed: {
    },
    watch: {
        'dateRange': {
            handler: function () {
                if (this.$listeners && this.$listeners.dataSelecionada) {
                    this.$emit('dataSelecionada', {
                        dataFim: this.dateRange.endDate,
                        dataInicio: this.dateRange.startDate
                    })
                }
            },
            deep: true,
        }
    }
}
</script>

<style lang="scss">
.vue-daterange-picker {
    width: 100%;
    .form-control > div {
        display: flex !important;
        height: 100%;
        span {
            align-self: center;
        }
    }
}
.daterangepicker td.in-range {
    background-color: $dark-white;
}
.daterangepicker .ranges li.active {
    background-color: $primary;
}
.daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: $primary;
}
.footer-slot {
    p {
        align-self: center;
        margin-bottom: 0;
    }
}
</style>