import Vue from 'vue'
import VeeValidate from "vee-validate"
import {Dictionary} from '@/dictionary'
import pt_BR from 'vee-validate/dist/locale/pt_BR.js'
import { cnpj } from 'cpf-cnpj-validator';

Vue.use(VeeValidate, { fieldsBagName: 'veeValidateFields' });

VeeValidate.Validator.localize('pt_BR', pt_BR);
VeeValidate.Validator.localize(Dictionary);

VeeValidate.Validator.extend("nomeSobrenome", {
  getMessage: `Digite um nome e sobrenome.`,
  validate: (value) => {
      return value.trim().split(" ").length > 1
  }
});
VeeValidate.Validator.extend("telefone", {
  getMessage: `Digite um telefone fixo ou celular válido.`,
  validate: (value) => {
      return value.length >= 10
  }
});
VeeValidate.Validator.extend("cnpj", {
  getMessage: `CNPJ inválido, digite um CNPJ válido.`,
  validate: (value) => {
      return cnpj.isValid(value)
  }
});