const TokenModule = {
    state: {
        token: null,
        perfil: null,
        altS: null,
    },
    mutations: {
        setAuth(state, auth) {
            if (auth != null) {
                state.token = auth.token;
                state.perfil = auth.perfil;
                state.altS = auth.altS;
            } else {
                state.token = null;
                state.perfil = null;
                state.altS = null;
            }
        },
    },
    getters: {
        auth(state) {
            return {
                token: state.token,
                perfil: state.perfil,
                altS: state.altS,
            };
        },
        token(state) {            
            return state.token;
        },
        perfil(state) {            
            return state.perfil;
        },
        altS(state) {            
            return state.altS;
        },
    },
}
 
 export default TokenModule;