<template>
    <div class="background-layout-container">
        <Header></Header>
        <div class="background" v-bind:style="{'background-image': image}">
            <h1 v-if="showTitle">Nova <br/> Simulação</h1>
        </div>
        <div class="layout-content layout-content-nova-simulacao d-flex flex-column">
            <div class="container-card view-home container">
                <div v-if="!analisado" class="card custom-card">
                    <div class="card-header">
                        <h5 class="card-title">Nova simulação</h5>
                    </div>
                    <div class="card-body">
                        <form class="fh-form" @submit.prevent="gravar()" data-vv-scope="formulario">
                            <h3 class="mb-2 mb-sm-3">Dados Cadastrais</h3>
                            <div class="d-flex flex-column flex-md-row">
                                <div class="col-12 col-md-6 col-lg-6 p-0 pr-md-5">
                                    <div class="form-group mb-2">
                                        <label for="cnpj">CNPJ</label>
                                        <TheMask :class="{'form-control': true, 'is-invalid': errors.has('formulario.cnpj')}" placeholder="Digite o CNPJ da empresa cliente" 
                                            v-model="formulario.cnpj"  v-validate="'required|min:14|cnpj'" type="text" name="cnpj" autocomplete="off" :mask="'##.###.###/####-##'"/>
                                        <span v-show="errors.has('formulario.cnpj')" class="invalid-feedback">{{ errors.first('formulario.cnpj') }}</span>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-6 p-0 pl-md-5">
                                    <div class="form-group mb-2">
                                        <label for="razaoSocial">Razão social</label>
                                        <input :class="{'form-control': true, 'is-invalid': errors.has('formulario.razaoSocial')}" placeholder="A razão social será carregada automaticamente" 
                                            v-model="formulario.razaoSocial"  type="text" name="razaoSocial" autocomplete="off" :disabled="true"/>
                                        <!-- <p v-show="bloquearRazaoSocial != ''" class="text-custom warning small">{{ bloquearRazaoSocial }}</p> -->
                                        <span v-show="errors.has('formulario.razaoSocial')" class="invalid-feedback">{{ errors.first('formulario.razaoSocial') }}</span>
                                    </div>
                                </div>
                            </div>
                            <h3 class="mt-3 mb-2 mb-sm-3">Dados do contrato</h3>
                            <div class="d-flex flex-column flex-md-row">
                                <div class="flex-column flex-1 pr-md-5 pr-0">
                                    <div class="form-group">
                                        <label for="valorTotalEquipamentos">Valor total dos equipamentos</label>
                                            <money v-bind="money" :class="{'form-control': true, 'is-invalid': errors.has('formulario.valorTotalEquipamentos')}"
                                            v-model="formulario.valorTotalEquipamentos"  v-validate="'required|min_value:1'" type="text" name="valorTotalEquipamentos" autocomplete="off"/>
                                        <span v-show="errors.has('formulario.valorTotalEquipamentos')" class="invalid-feedback">{{ errors.first('formulario.valorTotalEquipamentos') }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label for="valorAdicional">Valores adicionais (Software, etc)</label>
                                            <money v-bind="money" :class="{'form-control': true, 'is-invalid': errors.has('formulario.valorAdicional')}"
                                            v-model="formulario.valorAdicional"  v-validate="'required|min_value:0'" type="text" name="valorAdicional" autocomplete="off"/>
                                        <span v-show="errors.has('formulario.valorAdicional')" class="invalid-feedback">{{ errors.first('formulario.valorAdicional') }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label for="prazo">Prazo do contrato</label>
                                        <multiselect v-model="formulario.prazo.model" :options="formulario.prazo.options" placeholder="Selecione a quantidade de meses" label="value" track-by="key">
                                            <template slot="option" slot-scope="props">
                                                <span class="">{{props.option.value}}</span>
                                            </template>
                                            <template slot="noResult">Nenhum resultado encontrado.</template>
                                        </multiselect>
                                        <span v-show="errors.has('formulario.prazo')" class="invalid-feedback">{{ errors.first('formulario.prazo') }}</span>
                                    </div>
                                </div>
                                <div class="flex-column flex-1 pl-md-5 pr-0">
                                    <div class="form-group">
                                        <label for="valorInstalacao">Valor total da instalação (Máximo 10%)
                                            <CustomIcon :name="'info'" v-b-tooltip.hover :title="'Valor total da instalação dos equipamentos (Máximo 10%)'" :width="'19'" :height="'19'"/> 
                                        </label>
                                        <money v-bind="money" :class="{'form-control': true, 'is-invalid': errors.has('formulario.valorInstalacao')}"
                                        v-model="formulario.valorInstalacao"  v-validate="'required|min_value:0|max_value:' + (formulario.valorTotalEquipamentos / 10).toFixed(2)" type="text" name="valorInstalacao" autocomplete="off"/>
                                        <span v-show="errors.has('formulario.valorInstalacao')" class="invalid-feedback">{{ errors.first('formulario.valorInstalacao') }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label for="totalRecebido">Total a ser recebido 
                                            <CustomIcon :name="'info'" v-b-tooltip.hover :title="'Total a ser recebido pela ' + $nome" :width="'19'" :height="'19'"/> 
                                        </label>
                                        <money v-bind="money" :class="{'form-control': true, 'is-invalid': errors.has('formulario.totalRecebido')}" disabled="true"
                                        v-model="formulario.totalRecebido" v-validate="'required'" type="text" name="totalRecebido" autocomplete="off"/>
                                        <span v-show="errors.has('formulario.totalRecebido')" class="invalid-feedback">{{ errors.first('formulario.totalRecebido') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="custom-control custom-checkbox my-2">
                                <input class="custom-control-input" type="checkbox" id="checkbox" v-model="formulario.seguro">
                                <label class="custom-control-label" for="checkbox">
                                    Incluir Proteção e Danos 
                                    <CustomIcon class="text-custom primary" :name="'info'" v-b-tooltip.hover title="Prezado cliente, caso você opte pela contratação sem proteção e danos, as condições do projeto poderão sofrer alterações em razão do risco envolvido."/>
                                </label>
                            </div>
                            <CustomAlert :alert="alert"></CustomAlert>
                            <CustomAlert v-if="bloquearRazaoSocial !== '' && alert == null" :alert="{
                                message: 'Estamos consultando algumas informações para fazer a sua simulação, por favor aguarde. Essa consulta pode demorar alguns minutos.', 
                                type: 'primary'}">
                            </CustomAlert>
                            <div class="container-botoes-form mt-3">
                                <button class="btn btn-custom primary default" type="submit" :disabled="!validForm || !!bloquearRazaoSocial">Simular</button>
                            </div>
                        </form>
                    </div>
                </div>        
                <div v-else class="card custom-card">
                    <div class="card-header">
                        <h5 class="card-title">Resultado da simulação</h5>
                    </div>
                    <div class="card-body pt-2">
                        <div class="d-flex flex-column flex-sm-row">
                            <div class="flex-2 align-self-start align-self-sm-center mb-3 mb-sm-0 pr-md-5 pr-0">
                                <label>CNPJ</label>
                                <p class="m-0 font-weight-bold">{{$uteis.formatCnpj(this.formulario.cnpj) || ''}}</p>
                            </div>
                            <div class="flex-2 align-self-center mb-3 mb-sm-0 pl-md-5 pl-0">
                                <label>Razão social</label>
                                <p class="m-0 font-weight-bold">{{this.formulario.razaoSocial || 'Estamos consultado a informação'}}</p>
                            </div>
                            <button v-if="altoRisco" id="botao-alto-risco" class="flex-1 btn btn-custom secondary danger events-none" variant="primary">
                                <CustomIcon class="mr-2" :name="'alert'"/>Alto índice de rejeição
                            </button>
                            <!--<b-tooltip target="botao-alto-risco" title="Online!" variant="danger" placement="bottom">Lorem lorem, Lero lero</b-tooltip>-->
                        </div>
                        <h3 class="titulo-dados-resultado-simulacao">Dados do contrato</h3>
                        <div class="mt-3">
                            <label>Valor total dos bens</label>
                            <p class="m-0 font-weight-bold">{{ 'R$ '+ $uteis.formatNumber(this.formulario.totalRecebido) || ''}}</p>
                        </div>
                        <div class="mt-3">
                            <label>Prazo do contrato</label>
                            <p class="m-0 font-weight-bold">{{ this.formulario.prazo.model && this.formulario.prazo.model.value || ''}}</p>
                        </div>
                        <div class="mt-3">
                            <label>Valor da parcela mensal ({{$nome}})</label>
                            <p class="m-0 font-weight-bold">{{ 'R$ ' + $uteis.formatNumber(this.parcelaCalculada) || ''}}</p>
                        </div>
                        <div v-if="formulario.seguro" class="mt-3">
                            <label>Valor estimado de Proteção e Danos</label>
                            <p class="m-0 font-weight-bold">{{ 'R$ ' + $uteis.formatNumber(this.valorProtecaoeDanos) || ''}}</p>
                        </div>
                        <div class="my-3">
                            <p v-if="formulario.seguro" class="m-0 font-weight-bold"><CustomIcon :name="'check'"/>Proteção e Danos incluído</p>
                            <div v-else class="text-custom danger col-12 col-md-10 px-0 my-1">
                                <p class="m-0 font-weight-bold"><CustomIcon :name="'x'"/>Proteção e Danos não incluído</p>
                                <p class="text-custom danger mt-2">Prezado cliente, como você optou pela contratação sem proteção e danos, as condições do projeto poderão sofrer alterações em razão do risco envolvido. Caso queira incluir este item, clique em editar no botão abaixo.</p>
                            </div>
                        </div>
                        <CustomAlert :alert="alert"></CustomAlert>
                        <div class="container-btns-simulacao-analisada">
                            <router-link class="btn btn-custom secondary default mr-3" type="button" :to="'/editar-simulacao?id=' + id">Editar</router-link>
                            <button id="botao-registrar" class="btn btn-custom primary default" type="button" @click="registrar()" :disabled="bloquearRazaoSocial !== ''">Enviar Proposta Completa</button>
                            <b-tooltip target="botao-registrar" variant="primary" placement="bottom">Continuar com a criação da proposta.</b-tooltip>
                        </div>
                    </div>
                </div>
                <ModalLoading :message="loadingMessage"></ModalLoading>
                <AlertModal :alertModal="alertModal" @fechar="resetPagina()"></AlertModal>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { TheMask } from 'vue-the-mask'
import Header from '@/components/layout/Header.vue'
import SimulacaoService from '@/services/creditPlus/simulacao.service.js'
import AnaliseService from '@/services/creditPlus/analise.service.js'
import { cnpj } from 'cpf-cnpj-validator'

export default {
  name: 'NovaSimulacao',
  components: {
      Multiselect,
      TheMask,
      Header
  },
  data() {
  return {
        image: 'url("img/simulacao-nova-1.jpg")',
        alert: null,
        alertModal: null,
        analisado: false,
        altoRisco: false,
        showTitle: true,
        id: null,
        taxa: 0,
        parcelaCalculada: 0,
        valorProtecaoeDanos: 0,
        formulario: {
            analiseId: null,
            seguro: true,
            cnpj: '',
            razaoSocial: '',
            valorTotalEquipamentos: 0,
            valorAdicional: 0,
            valorDoServicoMensal: 0,
            valorInstalacao: 0,
            totalRecebido: 0,
            prazo: {
                model: null,
                options: [
                    {key: 12, value: '12 meses'},
                    {key: 24, value: '24 meses'},
                    {key: 36, value: '36 meses'},
                    {key: 48, value: '48 meses'},
                    {key: 60, value: '60 meses'},
                ]
            }
        },
        loadingMessage: '',
        bloquearRazaoSocial: '',
        money: {
            decimal: ',',
            thousands: '.',
            prefix: 'R$ ',
            suffix: '',
            precision: 2,
            masked: false
        }
    }
  },
  beforeMount() {
    document.querySelector('body').removeAttribute('class')
  },
  mounted() {
    document.querySelector('body').classList.add("base-layout")
  },
  methods: {
    resetPagina() {
        this.alertModal = null;
        this.$router.go()
    },
    /*buscarResultadoAnalise() {
        this.alert = null
        AnaliseService.resultadoAnalise({analiseId: this.formulario.analiseId}).then(result => {
            if (result.success && result.data) {
                this.altoRisco = this.$uteis.calculaRisco(result.data.ratingDescricao)
                this.analiseResultado = true
            } else {
                this.alert = {
                    type: 'danger',
                    message: result ? result.message : ''
                };
            }
        })
    },*/
    gravar() {
        let data = {
            cnpj: this.formulario.cnpj,
            razaoSocial: this.formulario.razaoSocial,
            valorTotalEquipamentos: this.formulario.valorTotalEquipamentos,
            valorAdicional: this.formulario.valorAdicional,
            valorInstalacao: this.formulario.valorInstalacao,
            valorDoServicoMensal: this.formulario.valorDoServicoMensal,
            prazoContrato: this.formulario.prazo.model.key,
            incluirSeguro: this.formulario.seguro,
            analiseId: this.formulario.analiseId,
        }
        this.loadingMessage = "Gravando simulação"
        SimulacaoService.criar(data).then(result => {
            this.loadingMessage = ""
            if (result.data && result.data.success) {
                this.id = result.data.id
                this.analisado = true
                this.image = 'url("img/simulacao-resultados-1.jpg")'
                this.showTitle = false
                this.parcelaCalculada = this.$uteis.calculoParcela(this.taxa, this.formulario.totalRecebido, this.formulario.prazo.model.key, this.formulario.seguro)
                this.valorProtecaoeDanos = this.$uteis.calculoProtecao(this.formulario.totalRecebido, this.formulario.prazo.model.key)
                // this.alertModal = {
                //     type: "success", 
                //     title: "Simulação gravada com sucesso!", 
                //     htmlBody: "<a class='btn btn-custom secondary default mt-2' href='/ver-simulacao?id=" + result.data.id + "' >Visualizar informações</a>", 
                //     botaoFechar: ""
                // }
            } else {
                this.alert = {
                    type: 'danger',
                    message: result ? result.message : ''
                };
            }
        });
    },
    registrar() {
        if (this.id) {
            this.$router.push('/nova-proposta?id=' + this.id)
        }
    },
  },
  watch: {
    // 'formulario.analiseId': {
    //     handler: function () {
    //         if (this.formulario.analiseId && this.analisado) {
    //             this.buscarResultadoAnalise()
    //         }
    //     },
    //   deep: false
    // },
    'formulario': {
        handler: function () {
            this.formulario.totalRecebido = this.formulario.valorTotalEquipamentos + this.formulario.valorAdicional + this.formulario.valorInstalacao
        },
      deep: true
    },
    'formulario.cnpj': {
        handler: function (value) {
            this.alert = null
            this.formulario.razaoSocial = ""
            if (cnpj.isValid(value) && this.bloquearRazaoSocial == '') {
                this.formulario.analiseId = null
                this.bloquearRazaoSocial = 'Aguarde um instante, estamos preparando algumas informações com o CNPJ informado.'
                AnaliseService.consultarCNPJ({cnpj: value}).then(result => {
                    this.bloquearRazaoSocial = ''
                    if (result.success && result.data) {
                        this.formulario.razaoSocial = result.data.nome
                        this.formulario.analiseId = result.data.id
                        this.taxa = result.data.taxa
                        AnaliseService.abrirAnalise({analiseId: result.data.id}).then(result => {
                            if (result.success && result.data) {
                                this.altoRisco = this.$uteis.calculaRisco(result.data.ratingDescricao)
                            }
                        })
                    }
                })
            }
        },
    }
  },
  computed: {
      validForm() {
            return this.formulario.cnpj != "" //&& this.formulario.razaoSocial != ""
            && this.formulario.valorTotalEquipamentos > 0 && this.formulario.prazo.model && this.formulario.prazo.model.key != ""
            && this.errors.items.length == 0;
      }
  },
}
</script>
<style lang="scss">
     @media (max-width: $min-sm) {
        .background-layout-container .background {
            display: none;
        }
        .logo {
            max-width: 120px;
        }
    } 
    @media (max-width: $min-md) and (min-width: $min-sm) {
        .background-layout-container .background {
            max-width: 32% !important;
             h1 {
                left: 3% !important;
            }
        }
    }
    .background-layout-container {
        height: 100%;
        min-height: 100%;
        display: flex;
        flex-direction: row;
        .background {
            flex: 1;
            max-width: 35%;
            background-size: cover;
            background-position: 58% 20%;
            background-repeat: no-repeat;
            h1 {
                color: $green;
                position: absolute;
                bottom: 20%;
                left: 2%;
                font-weight: normal !important;
              @media (max-width: $min-lg) {
                display: none;
              }
            }
        }
    }
    .titulo-dados-resultado-simulacao {
        border-top: 1px solid $light-gray;
        margin-top: 1rem;
        padding-top: 1rem;
    }
    .container-btns-simulacao-analisada {
        border-top: 1px solid $light-gray;
        border-bottom: 1px solid $light-gray;
        padding: 1rem 0;
        margin: 1rem 0;
    }
    .layout-content-nova-simulacao {
        //media para efeito de sobreprosição do background
        @media (max-width: $min-sm) {
            margin-left: 0;
        }
        @media (max-width: $min-md) and (min-width: $min-sm) {
            margin-left: -60px;
        }
        @media (max-width: $min-lg) and (min-width: $min-md) {
            margin-left: -250px;
        }
        @media (min-width: $min-lg) {
            margin-left: -250px;
        }
        flex: 1;
    }
</style>