<template>
    <div class="container-card view-parceiros">
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
        <div class="card custom-card">
            <div class="card-header">
                <h5 class="card-title">Parceiros inativos</h5>
            </div>
            <div class="card-body">
                <div class="d-flex flex-row">
                    <p class="align-self-center mb-0 card-text">Visualize abaixo os parceiros inativos da {{$nome}}.</p>
                </div>
                <div class="table-container">
                    <div :class="{'table-filter': true, 'open': openFilter}">
                        <p class="align-self-center" @click="openFilter = !openFilter">Filtros: <span></span></p>
                        <div class="table-filter-content">
                            <div class="d-flex">
                                <button class="btn btn-custom secondary default small ml-auto" type="button" :disabled="!temFiltros" @click="limparFiltros"> Limpar filtros </button>
                            </div>
                            <form class="mt-1 fh-form flex-row" @submit.prevent data-vv-scope="formulario">
                                <div class="d-flex flex-1 flex-column mr-4">
                                    <div class="form-group">
                                        <label for="status">Filtrar por data</label>
                                        <CustomDatePicker :model="formulario.datePicker.model" @dataSelecionada="dataSelecionada" :key="formulario.datePicker.key"></CustomDatePicker>
                                    </div>
                                    <div class="form-group">
                                        <label for="status">Filtrar por nome completo</label>
                                        <multiselect v-model="formulario.nomes.model" :options="formulario.nomes.options" placeholder="Todos" label="value" track-by="key" @search-change="buscarNome">
                                            <template slot="option" slot-scope="props">
                                                <span class="">{{props.option.value}}</span>
                                            </template>
                                            <template slot="noResult">Nenhum resultado encontrado.</template>
                                            <template slot="noOptions">Digite ao menos 3 caracteres para iniciar a busca.</template>
                                        </multiselect>
                                    </div>
                                </div>
                                <div class="d-flex flex-1 flex-column ml-4">
                                    <div class="form-group flex-1">
                                        <label for="status">Filtrar por razão social</label>
                                        <multiselect v-model="formulario.nomesEmpresa.model" :options="formulario.nomesEmpresa.options" placeholder="Todos" label="value" track-by="key" @search-change="buscarNomeEmpresa">
                                            <template slot="option" slot-scope="props">
                                                <span class="">{{props.option.value}}</span>
                                            </template>
                                            <template slot="noResult">Nenhum resultado encontrado.</template>
                                            <template slot="noOptions">Digite ao menos 3 caracteres para iniciar a busca.</template>
                                        </multiselect>
                                    </div>
                                    <!--<div class="form-group flex-1">
                                        <label for="status">Filtre por status</label>
                                        <multiselect v-model="formulario.status.model" :options="formulario.status.options" placeholder="Todos" label="value" track-by="key">
                                            <template slot="option" slot-scope="props">
                                                <span class="">{{props.option.value}}</span>
                                                <template slot="noResult">Nenhum resultado encontrado.</template>
                                            </template>
                                        </multiselect>
                                    </div> -->
                                </div>
                            </form>
                        </div>
                    </div>
                    <b-table class="custom-table mt-4" hover :items="itens" :fields="fields" show-empty emptyText="Nenhum dado encontrado." sort-icon-right :busy="loading"
                        no-local-sorting  :sort-by.sync="sort.sortBy" :sort-desc.sync="sort.sortDesc" @sort-changed="reordenar">
                        <template #cell(acoes)="row">
                            <div class="d-flex justify-content-around">
                                <router-link v-if="row.item.perfil != $enums.perfis.SUPERADMIN.key" :to="{ 'path' : '/editar-parceiro', query: { 'id' : row.item.id }}">
                                    <CustomIcon :name="'edit'" />
                                </router-link>
                            </div>
                        </template>
                        <template #table-busy>
                            <div class="text-center text-dark-gray my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong class="ml-2">Carregando informações...</strong>
                            </div>
                        </template>
                    </b-table>
                    <b-pagination v-if="pagination.perPage <= pagination.rows" @change="alterarPagina" v-model="pagination.currentPage"
                        :total-rows="pagination.rows" :per-page="pagination.perPage" aria-controls="table-propostas-parceiros"></b-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UsuarioService from "@/services/creditPlus/usuario.service.js"
import Multiselect from 'vue-multiselect'
import CustomDatePicker from '@/components/CustomDatePicker.vue'

export default {
    name: "ParceirosListagemInativos",
    components: {
        Multiselect,
        CustomDatePicker,
    },
    data() {
        return {
            fields: [
                { key: "dataCriacaoConta", label: "Data", sortable: true },
                { key: "nomeEmpresa", label: "Razão Social", sortable: true },
                { key: "nome", label: "Nome completo", sortable: true },
                { key: "status", label: "Status", sortable: true },
                { key: "acoes", label: "", sortable: false, tdClass: "w-60-px", thClass: "w-60-px" },
            ],
            itens: [],
            sort: {
                sortBy: "dataCriacaoConta",
                sortDesc: true,
            },
            pagination: {
                rows: 0,
                perPage: 20,
                currentPage: 1,
            },
            loading: false,
            openFilter: false,
            breadcrumb: [
                { text: 'Parceiros', href: '/parceiros' },
                { text: 'Inativos', active: true }
            ],
            formulario: {
                status: {
                    model: null,
                    options: []
                },
                nomes: {
                    model: null,
                    options: []
                },
                nomesEmpresa: {
                    model: null,
                    options: []
                },
                datePicker: {
                    key: 0,
                    model: {
                        dataInicio: '',
                        datatFim: '',
                    }
                }
            },
        }
    },
    mounted() {
        this.formulario.status.options = [this.$enums.statusUsuario.INATIVO].map(function(status) {
            return { key: status.key, value: status.value }
        })
        this.formulario.status.model = this.formulario.status.options.find(() => true)
        this.listarUsuarios()
    },
    methods: {
        dataSelecionada(val) {
            this.formulario.datePicker.model = val
            this.listarUsuarios()
        },
        reordenar(ctx) {
            this.sort = {
                sortBy: ctx.sortBy,
                sortDesc: ctx.sortDesc,
            }
            this.listarUsuarios()
        },
        alterarPagina(value) {
            this.pagination.currentPage = value
            this.listarUsuarios()
        },
        listarUsuarios() {
            this.loading = true
            var dados = {
                perPage: this.pagination.perPage,
                currentPage: this.pagination.currentPage,
                status: this.formulario.status.model?.key,
                nome: this.formulario.nomes.model?.value,
                nomeEmpresa: this.formulario.nomesEmpresa.model?.value,
                "sort.sortBy": this.sort?.sortBy,
                "sort.sortDesc": this.sort?.sortDesc,
                ...this.formulario.datePicker.model,
            }

            this.itens = []
            UsuarioService.listar(dados).then((result) => {
                if (result.success && result.data.itens) {
                    this.pagination.rows = result.data.totalItens
                    this.itens = []
                    result.data.itens.forEach((element) => {
                        this.itens.push({
                            dataCriacaoConta: this.$uteis.formatDate(element.dataCriacaoConta),
                            status: this.$enums.getValueByKey(this.$enums.statusUsuario, element.status),
                            id: element.id,
                            nome: element.nome,
                            nomeEmpresa: element.nomeEmpresa,
                            perfil: element.perfil,
                        })
                    })
                }
                this.loading = false
            })
        },
        limparFiltros() {
            this.pagination.currentPage = 1
            // this.formulario.status.model = null
            this.formulario.nomesEmpresa.model = null
            this.formulario.nomes.model = null
            this.formulario.nomesEmpresa.options = []
            this.formulario.nomes.options = []
            this.formulario.datePicker.model.dataInicio = ''
            this.formulario.datePicker.model.dataFim = ''
            this.formulario.datePicker.key++
            this.listarUsuarios()
        },
        buscarNome(nome) {
            this.formulario.nomes.options = []
            if (nome.length >= 3) {
                UsuarioService.buscarPorNome({nome: nome}).then((result) => {
                    if (result.success && result.data.itens) {
                        this.formulario.nomes.options = result.data.itens.map( element => {
                            return {
                                key: element.id,
                                value: element.nome,
                            }
                        }).sort((a, b) => a.value > b.value)
                        this.formulario.nomes.options = this.$uteis.uniqueByProprety(this.formulario.nomes.options, 'value')
                    }
                })
            }
        },
        buscarNomeEmpresa(nome) {
            this.formulario.nomesEmpresa.options = []
            if (nome.length >= 3) {
                UsuarioService.buscarPorNomeEmpresa({nomeEmpresa: nome}).then((result) => {
                    if (result.success && result.data.itens) {
                         this.formulario.nomesEmpresa.options = result.data.itens.map( element => {
                            return {
                                key: element.id,
                                value: element.nomeEmpresa,
                            }
                        }).sort((a, b) => a.value > b.value)
                        this.formulario.nomesEmpresa.options = this.$uteis.uniqueByProprety(this.formulario.nomesEmpresa.options, 'value')
                    }
                })
            }
        },
    },
    watch: {
        // 'formulario.status.model': {
        //     handler: function () {
        //         this.pagination.currentPage = 1
        //         this.listarUsuarios()
        //     },
        //     deep: true
        // },
        'formulario.nomes.model': {
            handler: function () {
                this.pagination.currentPage = 1
                this.listarUsuarios()
            },
            deep: true
        },
        'formulario.nomesEmpresa.model': {
            handler: function () {
                this.pagination.currentPage = 1
                this.listarUsuarios()
            },
            deep: true
        },
    },
    computed: {
        temFiltros() {
            return this.formulario.nomesEmpresa.model != null || this.formulario.nomes.model != null
            || this.formulario.datePicker.model.dataInicio || this.formulario.datePicker.modeldatatFim
        }
    },
}
</script>