<template>
    <div class="container-card view-taxas-parceiros">
        <div class="card custom-card">
            <div class="card-header">
                <h5 class="card-title">Editar taxas de juros</h5>
            </div>
            <div class="card-body">
                <div class="d-flex flex-row">
                    <p class="align-self-center mb-0 card-text">Edite as taxas de juros dos parceiros.</p>
                    <button class="btn btn-custom primary default ml-auto" :disabled="!alterouTaxas" @click="salvarTaxas()">Salvar</button>
                </div>
                <div class="table-container">
                    <b-table class="custom-table mt-4" hover :items="itens" :fields="fields" show-empty emptyText="Nenhum dado encontrado." sort-icon-right :busy="loading"
                        no-local-sorting  :sort-by.sync="sort.sortBy" :sort-desc.sync="sort.sortDesc" @sort-changed="reordenar">
                        <template #cell(taxa)=row>
                            <money class="form-control" v-model="itens[row.index].taxa" v-bind="money" type="text" name="taxa" autocomplete="off"/>
                        </template>
                        <template #table-busy>
                            <div class="text-center text-dark-gray my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong class="ml-2">Carregando informações...</strong>
                            </div>
                        </template>
                    </b-table>
                    <b-pagination v-if="pagination.perPage <= pagination.rows" @change="alterarPagina" v-model="pagination.currentPage"
                        :total-rows="pagination.rows" :per-page="pagination.perPage" aria-controls="table-propostas-parceiros"></b-pagination>
                </div>
                <div class="d-flex flex-row">
                    <div>
                        <router-link class="btn btn-custom secondary dark small" to="/parceiros">Voltar</router-link>
                    </div>
                    <button class="btn btn-custom primary default ml-auto" :disabled="!alterouTaxas" @click="salvarTaxas()">Salvar</button>
                </div>
            </div>
        </div>
          <AlertModal :alertModal="alertModal" @fechar="alertModal = null;"></AlertModal>
    </div>
</template>

<script>
import UsuarioService from "@/services/creditPlus/usuario.service.js"

export default {
    name: "TaxasParceiros",
    components: {
    },
    data() {
        return {
            fields: [
                { key: "nomeEmpresa", label: "Razão Social", sortable: true },
                { key: "nome", label: "Nome completo", sortable: true },
                { key: "taxa", label: "Taxa de juros (%)", sortable: false },
            ],
            itens: [
                {
                    id: 0,
                    nome:' element.nome',
                    nomeEmpresa:' element.nomeEmpresa',
                    taxa: 200,
                }
            ],
            sort: {
                sortBy: "nomeEmpresa",
                sortDesc: true,
            },
            pagination: {
                rows: 0,
                perPage: 20,
                currentPage: 1,
            },
            loading: false,
            openFilter: false,
            formulario: {
                taxas: [],
            },
            taxasAntigas: [],
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '%',
                precision: 2,
                masked: false
            },
            alertModal: null,
        }
    },
    mounted() {
        this.listarUsuarios()
    },
    methods: {
        reordenar(ctx) {
            this.sort = {
                sortBy: ctx.sortBy,
                sortDesc: ctx.sortDesc,
            }
            this.listarUsuarios()
        },
        alterarPagina(value) {
            this.pagination.currentPage = value
            this.listarUsuarios()
        },
        listarUsuarios() {
            this.loading = true
            var dados = {
                perPage: this.pagination.perPage,
                currentPage: this.pagination.currentPage,
                taxas: this.formulario.taxas.model?.key,
                "sort.sortBy": this.sort?.sortBy,
                "sort.sortDesc": this.sort?.sortDesc,
                perfil: this.$enums.perfis.DEFAULT.key,
            }
            this.itens = []
            UsuarioService.listar(dados).then((result) => {
                if (result.success && result.data.itens) {
                    this.pagination.rows = result.data.totalItens
                    result.data.itens.forEach((element) => {
                        this.itens.push({
                            id: element.id,
                            nome: element.nome,
                            nomeEmpresa: element.nomeEmpresa,
                            taxa: element.taxa,
                        })
                    })
                    this.taxasAntigas = this.itens.map(item => {
                        return item.taxa
                    })
                }
                this.loading = false
            })
        },
        salvarTaxas() {
            let dados = []
            let taxaInvalida = false
            this.itens.forEach((item, index) => {
                if (!item.taxa || item.taxa > 100 || item.taxa < 0.1) {
                    taxaInvalida = true
                }
                if (item.taxa != this.taxasAntigas[index]) {
                    dados.push({
                        id: item.id,
                        taxa: item.taxa,
                    })
                }
            })
            if (!taxaInvalida) {
                UsuarioService.alterarLote({usuarios: dados}).then((result) => {
                    if (result.success && result.data.success) {
                        this.taxasAntigas = this.itens.map(item => {
                            return item.taxa
                        })
                        this.alertModal = {
                            type: "success",
                            title: "Alterações salvas com sucesso",
                            htmlBody: `As taxas de juros dos parceiros foram alteradas e a partir de agora serão utilizadas nos cálculos das simulações e propostas.` ,
                            botaoFechar: "Fechar janela"
                        }
                    }
                })
            } else {
                this.alertModal = {
                    type: "danger",
                    title: "Falha ao salvar taxas",
                    htmlBody: `Todas as taxas devem ser devidamente preenchidas e deve estar entre os valores <b>0,1% e 100%</b>. Por favor verifique os campos e tente novamente.` ,
                    botaoFechar: "Fechar janela"
                }
            }
        }
    },
    watch: {
    },
    computed: {
        alterouTaxas() {
            let retorno = false
            this.itens.forEach((item, index) => {
                if (item.taxa != this.taxasAntigas[index]) {
                    retorno = true
                }
            })
            return retorno
        }
    },
}
</script>