<template>
    <div class="container-card">
        <div class="card custom-card">
            <div class="card-header">
                <h5 class="card-title">Patch note {{$version}}</h5>
            </div>
            <div class="card-body">
            </div>
        </div>
    </div>
</template>
<script>
import DevService from '@/services/creditPlus/dev.service.js'

export default {
  name: 'PatchNote',
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
  },
  mounted() {
      DevService.echo().then(result => {
          console.log(result)
      })
  },
}
</script>
