import ApiService from '@/services/creditPlus/creditplus.api.service.js'
import store from '@/store/index.js'

const UsuarioService = {
    api: new ApiService("/usuario"),

    cadastro: async function (data) {
        this.api.cleanToken()
        return await this.api.post("/cadastro", data).then((response) => {
            return { success: true, data: response.data }
        }).catch(this.api.defaultCatch)
    },
    criar: async function (data) {
        this.api.setToken(store.getters.token)
        return await this.api.post("/criar", data).then((response) => {
            return { success: true, data: response.data }
        }).catch(this.api.defaultCatch)
    },
    dadosUsuario: async function (id) {
        this.api.setToken(store.getters.token)
        return await this.api.get("/dadosUsuario", { id: id }).then((response) => {
            return { success: true, data: response.data }
        }).catch(this.api.defaultCatch)
    },
    dadosUsuarioLogado: async function () {
        this.api.setToken(store.getters.token)
        return await this.api.get("/dadosUsuarioLogado").then((response) => {
            return { success: true, data: response.data }
        }).catch(this.api.defaultCatch)
    },
    editarMeusDados: async function (data) {
        this.api.setToken(store.getters.token)
        return await this.api.post("/editarMeusDados", data).then((response) => {
            return { success: true, data: response.data }
        }).catch(this.api.defaultCatch)
    },
    editarUsuario: async function (data) {
        this.api.setToken(store.getters.token)
        return await this.api.post("/editarUsuario", data).then((response) => {
            return { success: true, data: response.data }
        }).catch(this.api.defaultCatch)
    },
    alterarStatus: async function (data) {
        this.api.setToken(store.getters.token)
        return await this.api.post("/alterarStatus", data).then((response) => {
            return { success: true, data: response.data }
        }).catch(this.api.defaultCatch)
    },
    inativar: async function (data) {
        this.api.setToken(store.getters.token)
        return await this.api.post("/inativar", data).then((response) => {
            return { success: true, data: response.data }
        }).catch(this.api.defaultCatch)
    },
    alterarSenha: async function(data) {
        this.api.setToken(store.getters.token)
        return await this.api.post("/alterarSenha", data).then((response) => {
            return { success: true, data: response.data }
        }).catch(this.api.defaultCatch)
    },
    alterarSenhaUsuario: async function(data) {
        this.api.setToken(store.getters.token)
        return await this.api.post("/alterarSenhaUsuario", data).then((response) => {
            return { success: true, data: response.data }
        }).catch(this.api.defaultCatch)
    },
    solicitarTrocaEmail: async function(data) {
        this.api.setToken(store.getters.token)
        return await this.api.post("/solicitarTrocaEmail", data).then((response) => {
            return { success: true, data: response.data }
        }).catch(this.api.defaultCatch)
    },
    confirmeEditarEmail: async function(data) {
        this.api.setToken(store.getters.token)
        return await this.api.post("/confirmeEditarEmail", data).then((response) => {
            return { success: true, data: response.data }
        }).catch(this.api.defaultCatch)
    },
    listar: async function (dados) {
        this.api.setToken(store.getters.token)
        return await this.api.get("/listar", dados).then((response) => {
            return { success: true, data: response.data }
        }).catch(this.api.defaultCatch)
    },
    consultarCNPJ: async function (dados) {
        this.api.setToken(store.getters.token)
        return await this.api.get("/consultarCNPJ", dados).then((response) => {
            return { success: true, data: response.data }
        }).catch(this.api.defaultCatch)
    },
    buscarPorNomeEmpresa: async function (dados) {
        this.api.setToken(store.getters.token)
        return await this.api.get("/buscarPorNomeEmpresa", dados).then((response) => {
            return { success: true, data: response.data }
        }).catch(this.api.defaultCatch)
    },
    buscarPorNome: async function (dados) {
        this.api.setToken(store.getters.token)
        return await this.api.get("/buscarPorNome", dados).then((response) => {
            return { success: true, data: response.data }
        }).catch(this.api.defaultCatch)
    },
    alterarLote: async function(data) {
        this.api.setToken(store.getters.token)
        return await this.api.post("/alterarLote", data).then((response) => {
            return { success: true, data: response.data }
        }).catch(this.api.defaultCatch)
    },
}

export default UsuarioService