import ApiService from '@/services/creditPlus/creditplus.api.service.js'
import store from '@/store/index.js'

const SimulacaoService = {
    api: new ApiService("/simulacao"),

    criar: async function (data) {
        this.api.setToken(store.getters.token);
        return await this.api.post("/criar", data).then((response) => {
            return { success: true, data: response.data };
        }).catch(this.api.defaultCatch);
    },
    editar: async function (data) {
        this.api.setToken(store.getters.token);
        return await this.api.post("/editar", data).then((response) => {
            return { success: true, data: response.data };
        }).catch(this.api.defaultCatch);
    },
    dadosSimulacao: async function (id) {
        this.api.setToken(store.getters.token);
        return await this.api.get("/dadosSimulacao", { id: id }).then((response) => {
            return { success: true, data: response.data };
        }).catch(this.api.defaultCatch);
    },
    listar: async function () {
        this.api.setToken(store.getters.token);
        return await this.api.get("/listar").then((response) => {
            return { success: true, data: response.data };
        }).catch(this.api.defaultCatch);
    },
    listarUltimas: async function () {
        this.api.setToken(store.getters.token);
        return await this.api.get("/listar?ultimas=3").then((response) => {
            return { success: true, data: response.data };
        }).catch(this.api.defaultCatch);
    },
    excluir: async function (data) {
        this.api.setToken(store.getters.token);
        return await this.api.delete("/excluir", data).then((response) => {
            return { success: true, data: response.data };
        }).catch(this.api.defaultCatch);
    },
}

export default SimulacaoService;