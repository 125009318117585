<template>
    <div class="container-card view-ver-proposta container">
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
        <div v-if="!alert && proposta.id" class="card custom-card">
            <div class="card-header d-flex flex-row justyify-content-between align-items-center">
                <div class="d-flex flex-row card-title justyify-content-between w-100">
                    <div class="flex-grow-1"><h5>Proposta {{proposta.identificador}}</h5></div>
                    <router-link v-if="proposta.status != $enums.statusProposta.EXCLUIDAADMIN.key" :to="'/impressao-proposta-parceiro?id=' + $route.query.id" target="_blank"><CustomIcon :name="'print'" class="pointer" /></router-link>
                </div>
            </div>
            <div class="card-body pt-2 flex-column flex-md-column flex-lg-row">
                <div class="flex-1 ml-lg-5 ml-md-0 flex-row flex-lg-column infos-propostas">
                    <div v-if="status" class="status-proposta status mt-0">
                        <div v-if="status.key != $enums.statusProposta.ANALISE.key">
                            <div class="mb-3">
                                <h6 class="">Status da proposta</h6>
                                <p class="text-uppercase" :style="'color: ' + status.cor">{{status.value}}</p>
                            </div>
                            <div class="mb-3">
                                <p class="font-weight-bold">Comentário do Analista de Crédito</p>
                                <span>{{proposta.anotacoes}}</span>
                            </div>
                        </div>
                        <div v-else class="d-flex flex-column">
                            <div class="form-group">
                                <h6>Comentário do Analista de Crédito</h6>
                                <textarea name="anotacoes" cols="30" rows="3" v-model="formulario.anotacoes" class="form-control"></textarea>
                            </div>
                            <multiselect class="multiselect-status-proposta" v-model="formulario.status.model" :options="formulario.status.options" placeholder="Todos" label="value" track-by="key">
                                <template slot="option" slot-scope="props">
                                    <span class="">{{props.option.value}}</span>
                                </template>
                                <template slot="noResult">Nenhum resultado encontrado.</template>
                            </multiselect>
                            <div class="text-center pt-3">
                                <button class="btn btn-custom default primary" :disabled="!validForm" @click="alterarStatus()">Alterar status da proposta</button>
                            </div>
                        </div>
                        <div v-if="status.key == $enums.statusProposta.NEGADA.key" class="bg-dark-white p-2">
                            <p class="font-weight-bold">Justificativa enviada para o usuário</p>
                            <p class="font-weight-normal">{{proposta.justificativaPropostaNegada}}</p>
                        </div>
                    </div>
                    <div class="d-contents status-pontuacao">
                        <div :class="'status-proposta ' + statusVadu">
                            <h6>Score Motor de crédito</h6>
                            <h3 class="font-weight-bold mb-0">{{proposta.ratingSigla}}</h3>
                        </div>
                        <div :class="'status-proposta ' + statusVadu">
                            <h6>Score Serasa</h6>
                            <div class="d-flex justify-content-between">
                                <h3 class="font-weight-bold my-auto">{{proposta.analise.pontuacaoSerasa || '-'}}</h3>
                                <button v-if="proposta.analise.linhasRelatorioSerasa && proposta.analise.linhasRelatorioSerasa.length" class="btn btn-custom dark secondary small" @click="$bvModal.show('modal-relato-serasa')">
                                    Visualizar Relato
                                </button>
                            </div>
                        </div>
                    </div>
                    <div :class="'status-proposta ' + statusVadu">
                        <h6>Informações VADU</h6>
                        <div v-if="!apontamentosVadu.length" class="d-contents">
                            <p><CustomIcon :name="'check-circle'" /> Nenhum apontamento na VADU.</p>
                        </div>
                        <div v-else v-for="item in apontamentosVadu.slice(0, 3)" :key="item.key">
                            <label class="font-weight-bold">{{item.regraDescricao}}</label>
                        </div>
                        <div v-if="apontamentosVadu.length" class="d-flex justify-content-center">
                            <button class="btn btn-custom dark secondary small my-2" @click="$bvModal.show('modal-apontamentos-vadu')">Visualizar todos os dados</button>
                        </div>
                    </div>
                </div>
                <div class="flex-2">
                    <h3>Dados da empresa</h3>
                    <div class="d-flex flex-column flex-xl-row">
                        <div class="d-flex flex-column flex-1 mr-xl-4 mr-lg-0">
                            <div class="mt-3">
                                <label>CNPJ</label>
                                <p class="m-0 font-weight-bold">{{ $uteis.formatCnpj(this.proposta.simulacao.cnpj) || ''}}</p>
                            </div>
                            <div class="mt-3">
                                <label>Estado</label>
                                <p class="m-0 font-weight-bold">{{ this.proposta.estado || ''}}</p>
                            </div>
                            <div class="mt-3">
                                <label>Endereço completo</label>
                                <p class="m-0 font-weight-bold">{{ this.proposta.endereco || ''}}</p>
                            </div>
                            <div class="mt-3">
                                <label>E-mail</label>
                                <p class="m-0 font-weight-bold">{{ this.proposta.email || ''}}</p>
                            </div>
                        </div>
                        <div class="d-flex flex-column flex-1">
                            <div class="mt-3">
                                <label>Razão Social</label>
                                <p class="m-0 font-weight-bold">{{ this.proposta.simulacao.razaoSocial || ''}}</p>
                            </div>
                            <div class="mt-3">
                                <label>CEP</label>
                                <p class="m-0 font-weight-bold">{{ this.proposta.cep || ''}}</p>
                            </div>
                            <div class="mt-3">
                                <label>Cidade</label>
                                <p class="m-0 font-weight-bold">{{ this.proposta.cidade || ''}}</p>
                            </div>
                            <div class="mt-3">
                                <label>Telefone</label>
                                <p class="m-0 font-weight-bold">{{ $uteis.formatTelefone(this.proposta.telefone) || ''}}</p>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="d-flex flex-column flex-xl-row">
                        <div class="d-flex flex-column flex-1 mr-xl-4 mr-lg-0">
                            <div>
                                <label>Site:</label><br />
                                <p class="m-0 font-weight-bold">{{this.proposta.analise.site || '-'}}</p>
                            </div>
                            <div class="mt-3">
                                <label>Situação na Receita:</label><br />
                                <p class="m-0 font-weight-bold">{{this.proposta.analise.situacaoReceita || '-'}}</p>
                            </div>
                            <div class="mt-3">
                                <label>Motivo da Situação na Receita:</label><br />
                                <p class="m-0 font-weight-bold">{{this.proposta.analise.motivoSituacaoReceita || '-'}}</p>
                            </div>
                            <div class="mt-3">
                                <label>Tipo:</label><br />
                                <p class="m-0 font-weight-bold">{{this.proposta.analise.tipo || '-'}}</p>
                            </div>
                            <div class="mt-3">
                                <label>Data de Abertura:</label><br />
                                <p class="m-0 font-weight-bold">{{$uteis.formatDate(this.proposta.analise.dataAbertura) || '-'}}</p>
                            </div>
                            <div class="mt-3">
                                <label>Natureza Jurídica:</label><br />
                                <p class="m-0 font-weight-bold">{{this.proposta.analise.naturezaJuridica || '-'}}</p>
                            </div>
                            <div class="mt-3">
                                <label>Simples:</label><br />
                                <p class="m-0 font-weight-bold">{{this.proposta.analise.simples ? 'Sim' : 'Não'}}</p>
                            </div>
                        </div>
                        <div class="d-flex flex-column flex-1">
                            <div class="mt-xl-0 mt-3">
                                <label>Simei:</label><br />
                                <p class="m-0 font-weight-bold">{{this.proposta.analise.simei ? 'Sim' : 'Não'}}</p>
                            </div>
                            <div class="mt-3">
                                <label>Porte:</label><br />
                                <p class="m-0 font-weight-bold">{{this.proposta.analise.porte || '-'}}</p>
                            </div>
                            <div class="mt-3">
                                <label>Atividade:</label><br />
                                <p class="m-0 font-weight-bold">{{this.proposta.analise.atividade || '-'}}</p>
                            </div>
                            <div class="mt-3">
                                <label>CNAE:</label><br />
                                <p class="m-0 font-weight-bold">{{this.proposta.analise.cnae || '-'}}</p>
                            </div>
                            <div class="mt-3">
                                <label>Capital Social:</label><br />
                                <p class="m-0 font-weight-bold">{{ 'R$ ' + this.$uteis.formatNumber(this.proposta.analise.capitalSocial) || '-'}}</p>
                            </div>
                            <div class="mt-3">
                                <label>Capital Social Matriz:</label><br />
                                <p class="m-0 font-weight-bold">{{ 'R$ ' + this.$uteis.formatNumber(this.proposta.analise.capitalSocialMatriz) || '-'}}</p>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <h3>Dados do contrato</h3>
                    <div class="mt-3">
                        <label>Valor total dos equipamentos</label>
                        <p class="m-0 font-weight-bold">{{ 'R$ ' + $uteis.formatNumber(this.proposta.simulacao.valorTotalEquipamentos) || ''}}</p>
                    </div>
                    <div class="mt-3">
                        <label>Valores adicionais (Software, etc)</label>
                        <p class="m-0 font-weight-bold">{{ 'R$ ' + $uteis.formatNumber(this.proposta.simulacao.valorAdicional) || ''}}</p>
                    </div>
                    <div class="mt-3">
                        <label>Valor total da instalação dos equipamentos (Máximo 10%)</label>
                        <p class="m-0 font-weight-bold">{{ 'R$ ' + $uteis.formatNumber(this.proposta.simulacao.valorInstalacao) || ''}}</p>
                    </div>
                    <div class="mt-3">
                        <label>Valor Total a ser recebido pela {{$nome}}</label>
                        <p class="m-0 font-weight-bold">
                            {{ 'R$ ' + $uteis.formatNumber(this.proposta.simulacao.valorTotalEquipamentos + this.proposta.simulacao.valorAdicional + this.proposta.simulacao.valorDoServicoMensal + this.proposta.simulacao.valorInstalacao) ||
                            ''}}
                        </p>
                    </div>
                    <div class="mt-3">
                        <label>Prazo do contrato</label>
                        <p class="m-0 font-weight-bold">{{this.proposta.simulacao.prazoContrato || ''}} meses</p>
                    </div>
                    <div class="bg-dark-white mt-2 p-2">
                        <div class="mt-3">
                            <label>Valor estimado da parcela mensal ({{$nome}})</label>
                            <p class="font-weight-bold">
                                {{'R$ ' + $uteis.formatNumber($uteis.calculoParcela(proposta.simulacao.taxa, proposta.simulacao.totalRecebido, proposta.simulacao.prazoContrato, proposta.simulacao.incluirSeguro))}}
                            </p>
                        </div>
                        <div v-if="proposta.simulacao.incluirSeguro" class="mt-3">
                            <label>Valor estimado de Proteção e Danos</label>
                            <p class="font-weight-bold">{{'R$ ' + $uteis.formatNumber($uteis.calculoProtecao(proposta.simulacao.totalRecebido, proposta.simulacao.prazoContrato))}}</p>
                        </div>
                    </div>
                    <hr />
                    <h3>Descrição dos produtos</h3>
                    <p>{{proposta.descricaoProduto}}</p>
                    <hr />
                    <h3>Documentos</h3>
                    <div class="d-flex flex-row">
                        <div class="d-flex flex-column flex-1 mr-2">
                            <div class="d-contents" v-for="item in proposta.documentos.filter(d => proposta.documentos.indexOf(d) % 2 == 0 && d.model != null && d.fileModel != null )" :key="item.key">
                                <p class="mb-1">Tipo: {{item.model.value}}</p>
                                <p class="mb-0 font-weight-bold pointer" @click="abrirDocumento(item.fileModel.id)">{{item.fileModel.name}} <CustomIcon :name="'external-link'" :width="'18'" class="ml-2" /></p>
                                <textarea class="form-control my-2" placeholder="Escreva uma descrição (opcional)" rows="1" v-model="item.descricao" :disabled="proposta.status == $enums.statusProposta.EXCLUIDAADMIN.key" />
                            </div>
                        </div>
                        <div class="d-flex flex-column flex-1">
                            <div class="d-contents" v-for="item in proposta.documentos.filter(d => proposta.documentos.indexOf(d) % 2 == 1 && d.model != null && d.fileModel != null )" :key="item.key">
                                <p class="mb-1">Tipo: {{item.model.value}}</p>
                                <p class="mb-0 font-weight-bold pointer" @click="abrirDocumento(item.fileModel.id)">{{item.fileModel.name}} <CustomIcon :name="'external-link'" :width="'18'" class="ml-2" /></p>
                                <textarea class="form-control my-2" placeholder="Escreva uma descrição (opcional)" rows="1" v-model="item.descricao" :disabled="proposta.status == $enums.statusProposta.EXCLUIDAADMIN.key"/>
                            </div>
                        </div>
                    </div>
                    <div v-if="proposta.status != $enums.statusProposta.EXCLUIDAADMIN.key" class="d-flex justify-content-end">
                        <button class="btn btn-custom default primary mt-3" :disabled="!descricaoAlterada" @click="salvarDescricoes">Salvar descrições</button>
                    </div>
                    <div v-if="proposta.justificativa" class="d-contents">
                        <hr />
                        <h3>Informações adicionais</h3>
                        <p>{{proposta.justificativa}}</p>
                    </div>
                    <hr class="d-print-none" />
                </div>
            </div>
            <div class="d-flex flex-row justify-content-between rodape-editar-proposta-parceiro">
                <router-link class="btn btn-custom small secondary dark" :to="proposta.status != $enums.statusProposta.EXCLUIDAADMIN.key ? '/propostas-parceiros' : '/propostas-parceiros-excluidas'">Voltar</router-link>
                <button v-if="proposta.status != $enums.statusProposta.EXCLUIDAADMIN.key" class="btn btn-custom small secondary dark" @click="$bvModal.show('modal-excluir-proposta')">
                    Excluir Proposta
                </button>
            </div>
        </div>
        <CustomAlert :alert="alert"></CustomAlert>
        <AlertModal :alertModal="alertModal" @fechar="alertModal = null"></AlertModal>
        <b-modal id="modal-aprovar-proposta" centered title="Aprovar proposta" footerClass="d-flex justify-content-between">
            <template #modal-header-close><CustomIcon :name="'x'"></CustomIcon></template>
            <p>Você tem certeza que deseja aprovar a proposta nº <strong>{{proposta.identificador}}</strong> ?</p>
            <p>Nós enviaremos um e-mail para o parceiro <strong>{{proposta.parceiro}}</strong> informando sobre a aprovação.</p>
            <template #modal-footer>
                <button type="button" class="btn btn-link" @click="$bvModal.hide('modal-aprovar-proposta')">Cancelar</button>
                <button type="button" class="btn btn-custom primary default" @click="confirmarAlterarStatus(true)">Aprovar proposta</button>
            </template>
        </b-modal>
        <b-modal id="modal-negar-proposta" centered footerClass="d-flex justify-content-between">
            <template #modal-header>
                <h5 class="modal-title text-custom danger">Negar proposta</h5>
                <button type="button" aria-label="Close" class="close" @click="$bvModal.hide('modal-negar-proposta')">
                    <CustomIcon :name="'x'" />
                </button>
            </template>
            <p>Você tem certeza que deseja negar a proposta nº <strong>{{proposta.identificador}}</strong> ?</p>
            <p>Nós enviaremos um e-mail para o parceiro <strong>{{proposta.parceiro}}</strong> informando sobre o cancelamento.</p>
            <div class="form-group">
                <label for="justificativa">Justificativa</label>
                <textarea
                    :class="{'form-control': true, 'is-invalid': errors.has('formulario.justificativa')}"
                    placeholder="Descreva os motivos pelos quais a proposta está sendo negada. Essa mensagem será enviada ao parceiro."
                    v-model="formulario.justificativa"
                    v-validate="'required'"
                    type="text"
                    name="justificativa"
                    rows="7"
                />
                <span v-show="errors.has('formulario.justificativa')" class="invalid-feedback">{{ errors.first('formulario.justificativa') }}</span>
            </div>
            <template #modal-footer>
                <button type="button" class="btn btn-link" @click="$bvModal.hide('modal-negar-proposta')">Cancelar</button>
                <button type="button" class="btn btn-custom primary danger" @click="confirmarAlterarStatus(false)" :disabled="!validFormNegarProposta">Cancelar proposta</button>
            </template>
        </b-modal>
        <b-modal id="modal-apontamentos-vadu" title="Apontamentos Vadu" centered :hide-footer="true" size="lg">
            <template #modal-header-close><CustomIcon :name="'x'"></CustomIcon></template>
            <div class="d-contents" v-for="item in apontamentosVadu" :key="item.key">
                <p>
                    <CustomIcon :name="'alert'" :class="{'text-custom danger': item.bloqueio, 'text-custom warning': item.alerta}" v-b-tooltip.hover :title="item.alerta ? 'Alerta' : 'Bloqueio'" :width="'20'" :height="'20'" />
                    {{item.regraDescricao}}
                </p>
            </div>
        </b-modal>
        <b-modal id="modal-relato-serasa" title="Relato Serasa" centered :hide-footer="true" size="lg">
            <template #modal-header-close><CustomIcon :name="'x'"></CustomIcon></template>
            <pre class="mb-1">
            <div v-for="linha in proposta.analise.linhasRelatorioSerasa" :key="linha.key">{{linha}}<br></div>
          </pre>
        </b-modal>
        <b-modal id="modal-excluir-proposta" centered footerClass="d-flex justify-content-between">
            <template #modal-header>
                <h5 class="modal-title text-custom danger">Exclusão de proposta</h5>
                <button type="button" aria-label="Close" class="close" @click="$bvModal.hide('modal-excluir-proposta')">
                    <CustomIcon :name="'x'" />
                </button>
            </template>
            <p>Você tem certeza que deseja excluir essa proposta? Ela não será mais exibida na listagem de propostas.</p>
            <p class="text-custom danger font-weight-bold">Essa operação é irreversível!</p>
            <template #modal-footer>
                <button class="btn btn-link font-weight-bold" @click="$bvModal.hide('modal-excluir-proposta')">Cancelar</button>
                <button class="btn btn-custom primary danger" @click="excluir()">Excluir</button>
            </template>
        </b-modal>
        <ModalLoading :message="loadingMessage"></ModalLoading>
    </div>
</template>

<script>
    import PropostaService from "@/services/creditPlus/proposta.service.js"
    import Multiselect from "vue-multiselect"

    export default {
        name: "VerProposta",
        components: {
            Multiselect,
        },
        data() {
            return {
                alert: null,
                alertModal: null,
                loadingMessage: "",
                formulario: {
                    anotacoes: "",
                    justificativa: "",
                    status: {
                        model: null,
                        options: [],
                    },
                },
                statusVadu: "status",
                apontamentosVadu: [],
                proposta: {
                    cep: "",
                    cidade: "",
                    dataCriacao: "",
                    dataUltimaEdicao: "",
                    descricaoProduto: "",
                    email: "",
                    endereco: "",
                    estado: "",
                    id: "",
                    idSimulacao: "",
                    justificativa: "",
                    justificativaPropostaNegada: "",
                    status: "",
                    telefone: "",
                    identificador: "",
                    anotacoes: "",
                    simulacao: null,
                    parceiro: "",
                    documentos: [],
                    descricoes: [],
                    ratingSigla: "",
                    ratingSerasa: "",
                    analise: {},
                },
                status: null,
            }
        },
        mounted() {
            this.formulario.status.options = [this.$enums.statusProposta.ANALISE, this.$enums.statusProposta.NEGADA, this.$enums.statusProposta.APROVADA].map(function (status) {
                return { key: status.key, value: status.value }
            })
            this.dadosProposta()
        },
        computed: {
            validForm() {
                return (
                    this.formulario.status.model != null &&
                    this.formulario.anotacoes &&
                    this.formulario.anotacoes.trim() &&
                    (this.formulario.status.model.key == this.$enums.statusProposta.APROVADA.key || this.formulario.status.model.key == this.$enums.statusProposta.NEGADA.key)
                )
            },
            validFormNegarProposta() {
                return this.formulario.justificativa != null && this.formulario.justificativa != ""
            },
            descricaoAlterada() {
                var retorno = false
                if (this.proposta.documentos && this.proposta.documentos.length > 0) {
                    this.proposta.documentos.forEach((element) => {
                        if (this.proposta.descricoes[element.fileModel.id] != element.descricao) {
                            retorno = true
                        }
                    })
                }
                return retorno
            },
            breadcrumb () {
                if (this.proposta.status != this.$enums.statusProposta.EXCLUIDAADMIN.key) {
                    return [
                        { text: "Propostas", href: "/propostas-parceiros" },
                        { text: "Proposta "  + this.proposta.identificador, active: true },
                    ]
                }
                return [
                    { text: "Propostas", href: "/propostas-parceiros" },
                    { text: "Excluídas", href: "/propostas-parceiros-excluidas" },
                    { text: "Proposta " + this.proposta.identificador, active: true },
                ]
            }
        },
        methods: {
            excluir() {
                this.loadingMessage = "Excluindo proposta..."
                PropostaService.excluir({id: this.proposta.id}).then(resultado => {
                    if (resultado.success && resultado.data.success) {
                        this.alertModal = {
                            type: "success",
                            title: "Proposta excluida com sucesso!",
                            htmlBody: "",
                            botaoFechar: "Fechar janela"
                        }
                        this.dadosProposta()
                    } else {
                        this.alertModal = {
                            type: "danger",
                            title: "Falha ao excluir proposta",
                            htmlBody: "Atualize a página e tente novamente mais tarde.",
                            botaoFechar: "Fechar janela"
                        }
                    }
                }).finally(() => {
                    this.$bvModal.hide("modal-excluir-proposta")
                    this.loadingMessage = ""
                })
            },
            salvarDescricoes() {
                var data = {
                    idProposta: parseInt(this.$route.query.id),
                    documentos: [],
                }
                this.proposta.documentos.forEach((element) => {
                    data.documentos.push({
                        id: element.fileModel.id,
                        descricao: element.descricao,
                    })
                })
                this.loadingMessage = "Salvando informações..."
                PropostaService.alterarDescricaoDocumentos(data).then((result) => {
                    this.loadingMessage = ""
                    if (result && result.success && result.data.success) {
                        this.dadosProposta()
                    }
                })
            },
            dadosProposta() {
                PropostaService.dadosPropostaParceiro(this.$route.query.id).then((result) => {
                    if (result.data) {
                        this.proposta.cep = result.data.cep
                        this.proposta.cidade = result.data.cidade
                        this.proposta.dataCriacao = result.data.dataCriacao
                        this.proposta.dataUltimaEdicao = result.data.dataUltimaEdicao
                        this.proposta.descricaoProduto = result.data.descricaoProduto
                        this.proposta.email = result.data.email
                        this.proposta.endereco = result.data.endereco
                        this.proposta.estado = result.data.estado
                        this.proposta.id = result.data.id
                        this.proposta.idSimulacao = result.data.idSimulacao
                        this.proposta.justificativa = result.data.justificativa
                        this.proposta.justificativaPropostaNegada = result.data.justificativaPropostaNegada
                        this.proposta.status = result.data.status
                        this.proposta.telefone = result.data.telefone
                        this.proposta.simulacao = result.data.simulacao
                        this.proposta.simulacao.totalRecebido = result.data.simulacao.valorTotalEquipamentos + result.data.simulacao.valorAdicional + result.data.simulacao.valorInstalacao
                        this.proposta.identificador = result.data.identificador
                        this.proposta.anotacoes = result.data.anotacoes
                        this.proposta.parceiro = result.data.parceiro
                        this.proposta.ratingSigla = result.data.ratingSigla
                        this.proposta.analise = result.data.analiseItem
                        this.apontamentosVadu = result.data.logs
                        this.statusVadu = this.apontamentosVadu.filter((d) => d.bloqueio).length > 0 ? "status-danger" : "status-warning"

                        this.status = this.$enums.getObjectByKey(this.$enums.statusProposta, this.proposta.status)
                        this.formulario.status.model = { key: this.status.key, value: this.status.value }
                        this.formulario.anotacoes = this.proposta.anotacoes
                        this.proposta.documentos = []
                        result.data.documentos.forEach((element) => {
                            this.proposta.descricoes[element.id] = element.descricao
                            this.proposta.documentos.push({
                                fileModel: { name: element.nome, id: element.id },
                                model: this.$enums.getObjectByKey(this.$enums.tipoDocumento, element.tipo),
                                descricao: element.descricao,
                            })
                        })
                    } else {
                        this.alert = {
                            type: "info",
                            message: result ? "Nenhum dado encontrado" : "",
                        }
                    }
                })
            },
            alterarStatus() {
                this.$bvModal.show(this.formulario.status.model.key == this.$enums.statusProposta.APROVADA.key ? "modal-aprovar-proposta" : "modal-negar-proposta")
            },
            abrirDocumento(id) {
                this.loadingMessage = "Carregando arquivo..."
                PropostaService.baixarDocumento({ id: id })
                    .then((result) => {
                        if (result.success && result.data && result.data.success) {
                            let a = document.createElement("a")
                            a.href = result.data.documento
                            a.download = result.data.nome
                            a.click()
                        } else {
                            this.alertModal = {
                                type: "danger",
                                title: "Falha ao realizar download de documento!",
                                htmlBody: "<p>" + result.data.message + "</p>",
                                botaoFechar: "Fechar janela",
                            }
                        }
                        this.loadingMessage = ""
                    })
                    .catch(() => {
                        this.alertModal = {
                            type: "danger",
                            title: "Falha ao realizar download de documento!",
                            botaoFechar: "Fechar janela",
                        }
                        this.loadingMessage = ""
                    })
            },
            confirmarAlterarStatus(aprovada) {
                var dados = {
                    id: this.proposta.id,
                    status: aprovada ? this.$enums.statusProposta.APROVADA.key : this.$enums.statusProposta.NEGADA.key,
                    justificativaPropostaNegada: this.formulario.justificativa,
                    anotacoes: this.formulario.anotacoes,
                }
                PropostaService.alterarStatus(dados).then((result) => {
                    if (result.success && result.data && result.data.success) {
                        this.$bvModal.hide("modal-aprovar-proposta")
                        this.$bvModal.hide("modal-negar-proposta")
                        if (aprovada) {
                            this.alertModal = {
                                type: "success",
                                title: "Proposta aprovada com sucesso!",
                                htmlBody:
                                    "<p>A proposta nº <strong>" +
                                    this.proposta.identificador +
                                    "</strong> foi aprovada.</p> <p>O parceiro <strong>" +
                                    this.proposta.parceiro +
                                    "</strong> receberá um e-mail informando sobre a aprovação.</p>",
                                botaoFechar: "Fechar janela",
                            }
                        } else {
                            this.alertModal = {
                                type: "success",
                                title: "Proposta negada com sucesso!",
                                htmlBody:
                                    "<p>A proposta nº <strong>" +
                                    this.proposta.identificador +
                                    "</strong> foi negada.</p> <p>O parceiro <strong>" +
                                    this.proposta.parceiro +
                                    "</strong> receberá um e-mail informando sobre o cancelamento.</p>",
                                botaoFechar: "Fechar janela",
                            }
                        }
                        this.dadosProposta()
                    } else {
                        this.alertModal = {
                            type: "danger",
                            title: "Falha ao alterar status da proposta!",
                            htmlBody: "<p>" + result.data.message + "</p>",
                            botaoFechar: "Fechar janela",
                        }
                    }
                })
            },
        },
    };
</script>
<style lang="scss">
    .view-ver-proposta {
        .card-body {
            display: flex;
            .status-proposta {
                border: 2px solid;
                border-radius: 10px;
                padding: 10px 15px;
                margin: 1.2rem 0;
                p {
                    margin-bottom: 05px;
                }
                h6 {
                    font-weight: bold;
                }
                &.status {
                    border-color: $light-gray;
                    h6 {
                        color: $dark-gray;
                    }
                    p {
                        font-weight: bold;
                    }
                }
                &.status-warning {
                    border-color: $warning;
                    h6 {
                        color: $warning;
                    }
                }
                &.status-danger {
                    border-color: $danger;
                    h6 {
                        color: $danger;
                    }
                }
            }
        }
        .multiselect-status-proposta {
            .multiselect-tags {
                border: 2px solid #e8e8e8;
            }
        }
    }
    .infos-propostas {
        margin-left: auto;
        order: 2;
        @media (max-width: $min-lg) {
            margin-left: 0;
            order: 0;
        }
    }
    @media (min-width: $min-sm) {
        .rodape-editar-proposta-parceiro {
            margin: 0 38px 20px;
        }
    }
</style>