<template>
    <b-modal id="alert-modal" centered @hidden="limparDados" :hide-footer="alertModal && alertModal.botaoFechar == ''">
        <template #modal-header-close><CustomIcon :name="'x'"></CustomIcon></template>
        <div v-if="alertModal" :class="'text-' + alertModal.type">
            <CustomIcon :name="iconName" :class="'mb-2 text-' + alertModal.type" :height="'34'" :width="'34'"></CustomIcon>
            <h5 :class="'text-' + alertModal.type">{{alertModal.title}}</h5>
        </div>
        <div v-if="alertModal && alertModal.htmlBody != ''" class="my-4" v-html="alertModal.htmlBody"></div>
        <div v-else class="py-2"></div>
        <template #modal-footer>
            <div v-if="alertModal && alertModal.botaoFechar != ''" class="mx-auto">
                <button type="button" class="btn btn-custom secondary default" @click="$bvModal.hide('alert-modal')">{{alertModal.botaoFechar}}</button>
            </div>
        </template>
    </b-modal>
</template>

<script>

export default {
  name: 'AlertModal',
  props:{ alertModal: Object },
  //type: String, title: String, htmlBody: String, botaoFechar: String
  data() {
    return {

    }
  },
  methods: {
      limparDados() {
        if (this.$listeners && this.$listeners.fechar) {
            this.$emit('fechar');
        }
      }
  },
  computed: {
      iconName() {
          return this.$uteis.iconName(this.alertModal ? this.alertModal.type : '', true)
      }
  },
  watch: {
    'alertModal': {
      handler: function () {
       if (this.alertModal !== null && this.alertModal.type) {
           this.$bvModal.show('alert-modal')
        }
      },
      deep: true
    }
  }
}
</script>
<style lang="scss">
#alert-modal {
    .modal-header {
        border: none;
        margin: 0.5rem 1rem 0 1rem !important;
    }
    .modal-body {
        text-align: center;
    }
}
</style>
