const UserModule = {
    state: {
        usuario: {
            id: 0,
            nomeCliente: '',
        }
    },

    getters: {
        usuario(state) {
            if (state.usuario == null || state.usuario === undefined) {
                return { id: 0, nomeCliente: '', };
            }

            return state.usuario;
        },
    },

    mutations: {
        setUsuario(state, usuario) {
            state.usuario = usuario;
        },
    }
}

export default UserModule;