import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router/router.js'
import store from '@/store/index.js'
import '@/vee-validate-config.js'
import { BootstrapVue } from 'bootstrap-vue'
import Icon from "@/components/Icon.vue"
import Alert from "@/components/Alert.vue"
import AlertModal from "@/components/AlertModal.vue"
import ModalLoading from "@/components/ModalLoading.vue"
import {Uteis} from '@/services/uteis.service.js'
import {Enums} from '@/enums.js'
import money from 'v-money'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.use(money)
Vue.component('CustomIcon', Icon)
Vue.component('CustomAlert', Alert)
Vue.component('AlertModal', AlertModal)
Vue.component('ModalLoading', ModalLoading)


Vue.prototype.$uteis = Uteis
Vue.prototype.$enums = Enums
Vue.prototype.$emailContato = "admin@allugg.com.br"
Vue.prototype.$nome = "Allugg"
Vue.prototype.$version = '1.9.0'

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')