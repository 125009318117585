import ApiService from '@/services/creditPlus/creditplus.api.service.js'

const DevService = {
    api: new ApiService("/dev"),

    echo: async function() {
        this.api.cleanToken();
        return await this.api.get("/echo").then((response) => {
            return { success: true, data: response.data };
        });
    },

}

export default DevService