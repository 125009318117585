<template>
   <div class="container-card view-simulacoes">
        <div class="card custom-card">
            <div class="card-header">
                <h5 class="card-title">Simulações gravadas</h5>
            </div>
            <div class="card-body">
                <p class="card-text">Visualize abaixo todas as simulações feitas por você.</p>
                <div class="table-container">
                    <b-table class="custom-table" hover :items="items" :fields="fields" show-empty emptyText="Nenhum dado encontrado." sort-icon-left  :busy="loading">
                    <template #cell(acoes)=row>
                        <router-link :to="{ 'path' : '/ver-simulacao', query: { 'id' : row.item.id }}">
                        <CustomIcon class="text-custom primary" :name="'show'"/>
                        </router-link>
                        <router-link class="mx-2" :to="{ 'path' : '/editar-simulacao', query: { 'id' : row.item.id }}">
                        <CustomIcon :name="'edit'"/>
                        </router-link>
                        <div class="d-contents" @click="excluirSimulacao(row.item)">
                        <CustomIcon :name="'x'" class="text-custom danger pointer"/>
                        </div>
                    </template>
                    <template #table-busy>
                        <div class="text-center text-dark-gray my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong class="ml-2">Carregando informações...</strong>
                        </div>
                    </template>
                    </b-table>
                </div>
            </div>
        </div>
        <b-modal id="modal-excluir-simulacao" centered footerClass="d-flex justify-content-between">
          <template #modal-header>
            <h5 class="modal-title text-custom danger">Exclusão de simulação</h5>
            <button type="button" aria-label="Close" class="close" @click="$bvModal.hide('modal-excluir-simulacao')">
              <CustomIcon :name="'x'" />
            </button>
          </template>
          <p>Você tem certeza que deseja excluir a simulação?</p>
          <p class="text-custom danger font-weight-bold">Essa operação é irreversível!</p>
          <template #modal-footer>
            <button class="btn btn-custom secondary dark" @click="$bvModal.hide('modal-excluir-simulacao')">Cancelar</button>
            <button class="btn btn-custom primary danger" @click="excluir()">Excluir</button>
          </template>
        </b-modal>
        <b-modal id="modal-excluir-simulacao-sucesso" centered footerClass="d-flex justify-content-between" hide-header-close hide-header>
          <div class="text-success text-center mt-5 mb-3">
              <CustomIcon :name="'check-circle'" class="mb-2 text-success" :height="'34'" :width="'34'"></CustomIcon>
              <h5 class="text-success">Simulação excluída com sucesso!</h5>
          </div>
          <template #modal-footer>
              <div class="mx-auto">
                  <button type="button" class="btn btn-custom secondary default" @click="$bvModal.hide('modal-excluir-simulacao-sucesso')">Fechar janela</button>
              </div>
          </template>
        </b-modal>
    </div>
</template>

<script>

import SimulacaoService from '@/services/creditPlus/simulacao.service.js'

export default {
  name: 'SimulacoesListagem',
  components: {
  },
  data() {
    return {
      fields: [
          { key: 'data', label: 'Data', sortable: false },
          { key: 'cnpj', label: 'CNPJ', sortable: false },
          { key: 'razaoSocial', label: 'Razão Social', sortable: false },
          { key: 'totalBens', label: 'Total dos bens', sortable: false },
          { key: 'parcelaMensal', label: 'Parcela mensal', sortable: false },
          // { key: 'totalMensal', label: 'Total mensal', sortable: false },
          { key: 'valorProtecaoDanos', label: 'Proteção e Danos', sortable: false },
          { key: 'acoes', label: '', sortable: false, tdClass:"text-right"},

      ],
      idExcluir: null,
      items: [],
      loading: false,
    }
  },    
  mounted() {
    this.listarSimulacoes();
  },
  methods: {
    excluirSimulacao(data) {
      if (data) {
        this.idExcluir = data.id;
        this.$bvModal.show('modal-excluir-simulacao')
      }
    },
    excluir() {
      SimulacaoService.excluir({id: this.idExcluir}).then(result => {
        if (result.success && result.data && result.data.id > 0) {
          this.listarSimulacoes();
          this.$bvModal.hide('modal-excluir-simulacao')
          this.$bvModal.show('modal-excluir-simulacao-sucesso')
        }
      });
    },
    listarSimulacoes() {
      this.loading = true
      this.items = []
      SimulacaoService.listar().then(result => {
        this.loading = false
        if (result.data && result.data.itens.length > 0) {
            result.data.itens.forEach(element => {
              var total = element.valorTotalEquipamentos + element.valorAdicional + element.valorInstalacao
              this.items.push({
                data: this.$uteis.formatDate(element.dataCriacao),
                id: element.id,
                cnpj: this.$uteis.formatCnpj(element.cnpj),
                razaoSocial: element.razaoSocial,
                totalBens: 'R$ ' + this.$uteis.formatNumber(total),
                totalMensal: 'R$ ' + this.$uteis.formatNumber(this.$uteis.calculoParcela(element.taxa, total, element.prazoContrato, element.incluirSeguro) * element.prazoContrato),
                valorProtecaoDanos: 'R$ ' + this.$uteis.formatNumber(this.$uteis.calculoProtecao(total, element.prazoContrato)),
                parcelaMensal: 'R$ ' + this.$uteis.formatNumber(this.$uteis.calculoParcela(element.taxa, total, element.prazoContrato, element.incluirSeguro)),
              })
            });
        } else {
          this.alert = {
            type: 'danger',
            message: result ? result.message : ''
          };
        }
      });
    },
  }
}
</script>
