<template>
    <i v-if="isFontAwesome" :class="'icone ' + name"></i>
    <inline-svg v-else class="icone"
        :src="(img ? 'img/' : 'img/icons/ico-') + name + '.svg'"
        :transformSource="transformSvg"
        :width="width || 24"
        :height="height || (img ? 0 : (width || 24))"
        :fill="fill || 'black'"
        :style="{ color: fill }"
        :aria-label="label || 'Icon'"
        @loaded="$emit('loaded')"
        @unloaded="$emit('unloaded')"
        @error="$emit('loaderror')"
    />
</template>

<script>
import InlineSvg from "vue-inline-svg"

export default {
    name: 'CustomIcon',
    components: {
        "inline-svg": InlineSvg
    },
    props: {
        name: String,
        transformSvg: Function,
        width: String,
        height: String,
        fill: String,
        label: String,
        img: Boolean
    },
    computed: {
        isFontAwesome() {
            return this.name == null || this.name.match(/fa\w?\s+fa-/)
        }
    }
}
</script>

<style>

</style>