<template>
    <div class="container-card view-minha-conta">
        <div class="card custom-card">
            <div class="card-header">
                <h5 class="card-title">Minha conta</h5>
            </div>
            <div class="card-body">
                <p class="card-text">Edite as informações da sua conta.</p>
                <form class="mt-1 fh-form" @submit.prevent="submitForm()" data-vv-scope="formulario">
                    <div class="container-minha-conta-form col-12 col-md-7 col-lg-5 p-0">
                        <div class="form-group">
                            <label for="empresa">Empresa</label>
                            <input :class="{'form-control': true, 'is-invalid': errors.has('formulario.empresa')}" placeholder="Que empresa você representa?" 
                                v-model="formulario.empresa"  v-validate="'required|min:4'" type="text" name="empresa" autocomplete="off"/>
                            <span v-show="errors.has('formulario.empresa')" class="invalid-feedback">{{ errors.first('formulario.empresa') }}</span>
                        </div>
                        <div class="form-group">
                            <label for="nome">Usuário (Nome)</label>
                            <input :class="{'form-control': true, 'is-invalid': errors.has('formulario.nome')}" placeholder="Nome Completo" 
                                v-model="formulario.nome"  v-validate="'required|nomeSobrenome|min:4'" type="text" name="nome" autocomplete="off"/>
                            <span v-show="errors.has('formulario.nome')" class="invalid-feedback">{{ errors.first('formulario.nome') }}</span>
                        </div>
                        <div class="form-group">
                            <label for="telefone">Telefone</label>
                            <TheMask :class="{'form-control': true, 'is-invalid': errors.has('formulario.telefone')}" placeholder="(00) 00000-0000" :mask="['(##) ####-####', '(##) #####-####']"
                                v-model="formulario.telefone"  v-validate="'required|telefone'" type="text" name="telefone" autocomplete="off"/>
                            <span v-show="errors.has('formulario.telefone')" class="invalid-feedback">{{ errors.first('formulario.telefone') }}</span>
                        </div>
                        <div class="form-group">
                            <label for="email">E-mail</label>
                            <input :class="{'form-control': true, 'is-invalid': errors.has('formulario.email')}" placeholder="Digite seu e-mail" 
                               v-model="formulario.email"  v-validate="'required|email'" type="email" name="email" autocomplete="off"/>
                            <span v-show="errors.has('formulario.email')" class="invalid-feedback">{{ errors.first('formulario.email') }}</span>
                        </div>
                        <div class="form-group">
                            <label>CNPJ</label>
                            <div class="d-flex align-items-center">
                                <p class="m-0">{{usuario.cnpj ? $uteis.formatCnpj(usuario.cnpj) : '-'}}</p>
                            </div>
                        </div>
                        <button class="mt-2 btn btn-custom secondary default small" type="button" v-b-modal.modal-alterar-senha>Alterar senha</button>
                    </div>
                    <CustomAlert :alert="alert"></CustomAlert>
                    <div class="container-botoes-form">
                        <button class="btn btn-custom primary default" type="submit" :disabled="!validForm">Salvar alterações</button>
                        <button class="btn btn-link font-weight-bold pointer p-0 mt-3 mt-sm-0" @click="logout()">Sair</button>
                    </div>
                </form>
            </div>
        </div>
        <b-modal id="modal-alterar-email" centered title="Alterar endereço de e-mail">
          <template #modal-header-close><CustomIcon :name="'x'"></CustomIcon></template>
          <form class="mt-1 fh-form" @submit.prevent="submitFormEmail()" data-vv-scope="formularioEmail">
            <div v-if="formularioEmail.exibirToken" class="d-contents">
              <div class="form-group">
                  <p>Digite abaixo o código de 6 dígitos que enviamos para o seu novo endereço de e-mail e confirme a sua senha</p>
                  <label for="email">Código de 6 digitos</label>
                  <the-mask mask="X-X-X-X-X-X" :class="{'form-control input-token': true, 'is-invalid': errors.has('formularioEmail.token')}" placeholder="0-0-0-0-0-0"
                      v-model="formularioEmail.token"  v-validate="'required|length:6'" type="text" name="token" autocomplete="off"/>
                  <span v-show="errors.has('formularioEmail.token')" class="invalid-feedback">{{ errors.first('formularioEmail.token') }}</span>
              </div>
              <div class="form-group">
                <label for="senha">Senha</label>
                <div class="input-icon">
                  <input :class="{'form-control':true, 'is-invalid': errors.has('formularioEmail.senha')}" placeholder="Digite sua senha" v-model="formularioEmail.senha" 
                          v-validate="'required'" :type="senhaVisivel ? 'text' : 'password'" name="senha" ref="senha" autocomplete="new-password"/>
                  <div class="icon" @click="senhaVisivel = !senhaVisivel">
                  <CustomIcon :name="senhaVisivel ? 'hide' : 'show'"/>
                  </div>
                  <span v-show="errors.has('formularioEmail.senha')" class="invalid-feedback">{{ errors.first('formularioEmail.senha') }}</span>
                </div>
              </div>
            </div>
            <div v-else class="form-group">
                <label for="email">Novo e-mail</label>
                <input :class="{'form-control': true, 'is-invalid': errors.has('formularioEmail.email')}" placeholder="Digite o seu novo e-mail" 
                    v-model="formularioEmail.email"  v-validate="'required|email'" type="text" name="email" autocomplete="off" :disabled="formularioEmail.exibirToken"/>
                <span v-show="errors.has('formularioEmail.email')" class="invalid-feedback">{{ errors.first('formularioEmail.email') }}</span>
                <p class="mt-4 mb-0">Nós enviaremos uma mensagem para o seu novo endereço de e-mail com um código de 6 dígitos.</p>
            </div>
          </form>
          <CustomAlert class="mt-3" :alert="alert3"></CustomAlert>
          <template #modal-footer>
            <div class="mx-auto">
              <button type="button" class="btn btn-custom primary default" :disabled="!validFormEmail" @click="submitFormEmail()">{{formularioEmail.exibirToken ? 'Trocar e-mail' : 'Enviar código'}}</button>
            </div>
          </template>
        </b-modal>
        <b-modal id="modal-alterar-senha" centered title="Alterar senha">
          <template #modal-header-close><CustomIcon :name="'x'"></CustomIcon></template>
          <form class="mt-1 mb-3 fh-form" @submit.prevent="submitFormSenha()" data-vv-scope="formularioSenha">
            <div class="form-group">
              <label for="senhaAtual">Senha atual</label>
              <div class="input-icon">
                <input :class="{'form-control':true, 'is-invalid': errors.has('formularioSenha.senhaAtual')}" placeholder="Digite sua nova senha" v-model="formularioSenha.senhaAtual" 
                        v-validate="'required'" :type="senhaVisivel ? 'text' : 'password'" name="senhaAtual" ref="senhaAtual" autocomplete="new-password" />
                <div class="icon" @click="senhaVisivel = !senhaVisivel">
                <CustomIcon :name="senhaVisivel ? 'hide' : 'show'"/>
                </div>
                <span v-show="errors.has('formularioSenha.senhaAtual')" class="invalid-feedback">{{ errors.first('formularioSenha.senhaAtual') }}</span>
              </div>
            </div>
            <div class="form-group">
              <label for="novaSenha">Nova senha</label>
              <div class="input-icon">
                <input :class="{'form-control':true, 'is-invalid': errors.has('formularioSenha.novaSenha')}" placeholder="Digite sua nova senha" v-model="formularioSenha.novaSenha"
                        v-validate="'required|min:6'" :type="novaSenhaVisivel ? 'text' : 'password'" name="novaSenha" ref="novaSenha" autocomplete="new-password"/>
                <div class="icon" @click="novaSenhaVisivel = !novaSenhaVisivel">
                <CustomIcon :name="novaSenhaVisivel ? 'hide' : 'show'"/>
                </div>
                <span v-show="errors.has('formularioSenha.novaSenha')" class="invalid-feedback">{{ errors.first('formularioSenha.novaSenha') }}</span>
              </div>
            </div>
            <div class="form-group">
              <label for="confirmeSenha">Confirme a nova senha</label>
              <input :class="{'form-control':true, 'is-invalid': errors.has('formularioSenha.confirmeSenha')}" placeholder="Confirme sua senha" v-model="formularioSenha.confirmeSenha"
                      v-validate="'required|confirmed:novaSenha'" :type="'password'" name="confirmeSenha" autocomplete="new-password"/>
              <span v-show="errors.has('formularioSenha.confirmeSenha')" class="invalid-feedback">{{ errors.first('formularioSenha.confirmeSenha') }}</span>
            </div>
          </form>
          <CustomAlert :alert="alert2"></CustomAlert>
          <template #modal-footer>
            <div class="mx-auto">
              <button type="button" class="btn btn-custom primary default" :disabled="!validFormSenha" @click="submitFormSenha()">Salvar alterações</button>
            </div>
          </template>
        </b-modal>
        <AlertModal :alertModal="alertModal" @fechar="alertModal = null"></AlertModal>
    </div>
</template>

<script>
import { TheMask } from 'vue-the-mask'
import UsuarioService from '@/services/creditPlus/usuario.service.js'

export default {
  name: 'MinhaConta',
  components: {
    TheMask
  },
  data() {
    return {
      editado: false,
      formulario: {
        empresa: '',
        nome: '',
        telefone: '',
        email: '',
      },
      formularioEmail: {
        email: '',
        token: '',
        senha: '',
        exibirToken: false,
      },
      formularioSenha: {
        senhaAtual: '',
        novaSenha: '',
        confirmeSenha: ''
      },
      senhaVisivel: false,
      novaSenhaVisivel: false,
      usuario: {},
      alert: null,
      alert2: null,
      alert3: null,
      alertModal: null,
    }
  },
  methods: {
    submitForm() {
      let data = {
        nome: this.formulario.nome,
        nomeEmpresa: this.formulario.empresa,
        telefone: this.formulario.telefone,
        email: this.formulario.email
      }

      UsuarioService.editarMeusDados(data).then(result => {
        if (result.data && result.data.success) {
          this.usuario.nome = data.nome
          this.usuario.nomeEmpresa = data.nomeEmpresa
          this.usuario.telefone = data.telefone
          this.usuario.email = data.email
          this.alert = {
            type: 'success',
            message: 'As alterações foram salvas com sucesso.',
            layout: 2
          };
        } else {
          this.alert = {
            type: 'danger',
            message: result ? result.message : ''
          };
        }
      });
    },
    solicitarEdicaoEmail() {
      let data = {
        email: this.formularioEmail.email
      }
      UsuarioService.solicitarTrocaEmail(data).then(result => {
        if (result.data && result.data.success) {          
         this.formularioEmail.exibirToken = true
        } else {
          this.alert3 = {
            type: 'danger',
            message: result.data != null ? result.data.message : result.message
          };
        }
      });
    },
    confirmeEditarEmail() {
       let data = {
        email: this.formularioEmail.email,
        senha: this.formularioEmail.senha,
        token: this.formularioEmail.token,
      }
      UsuarioService.confirmeEditarEmail(data).then(result => {
        if (result.data && result.data.success) {
         this.$bvModal.hide('modal-alterar-email')
         this.formularioEmail.exibirToken = false;
         this.formularioEmail.senha = '';
         this.usuario.email = this.formularioEmail.email;
          this.alertModal = {
            type: "success",
            title: "Email alterado com sucesso",
            htmlBody: "<p>O seu e-mail foi alterado. Da próxima vez que fizer o login, utilize seu novo e-mail para entrar no sistema.</p>", 
            botaoFechar: "Fechar janela"
          }
        } else {
          this.alert3 = {
            type: 'danger',
            message: result.data != null ? result.data.message : result.message
          };
        }
      });
    },
    submitFormEmail() {
      if (this.formularioEmail.exibirToken) {
        this.confirmeEditarEmail();
      } else {
        this.solicitarEdicaoEmail();
      }
    },
    submitFormSenha() {
      let data = {
        senha: this.formularioSenha.novaSenha,
        senhaAtual: this.formularioSenha.senhaAtual
      }
      UsuarioService.alterarSenha(data).then(result => {
        if (result.data && result.data.success) {
          this.$bvModal.hide('modal-alterar-senha')
          this.alertModal = {
            type: "success", 
            title: "Senha alterada com sucesso", 
            htmlBody: "<p>A sua senha foi alterada. Da próxima vez que fizer o login, utilize sua nova senha para entrar no sistema.</p>", 
            botaoFechar: "Fechar janela"
          }
        } else {
          this.alert2 = {
            type: 'danger',
            message: result.data != null ? result.data.message : result.message
          };
        }
      });
    },
    logout() {
        this.$router.push(this.$uteis.logout())
    },
    dadosUsuario() {
      UsuarioService.dadosUsuarioLogado().then(result => {
        if (result.success && result.data.id > 0) {
          this.usuario = {
            alterarSenha: result.data.alterarSenha,
            dataCriacaoConta: result.data.dataCriacaoConta,
            email: result.data.email,
            id: result.data.id,
            nome: result.data.nome,
            nomeEmpresa: result.data.nomeEmpresa,
            perfil: result.data.perfil,
            telefone: result.data.telefone,
            cnpj: result.data.cnpj,
          }
          this.formulario.empresa = this.usuario.nomeEmpresa;
          this.formulario.nome = this.usuario.nome;
          this.formulario.telefone = this.usuario.telefone;
          this.formulario.email = this.usuario.email;
        } else {
          this.alert = {
            type: 'danger',
            message: result ? result.message : ''
          };
        }
      });
    },
  },
  mounted() {
    this.dadosUsuario();
  },
  computed: {
      validForm() {
        return this.formulario.empresa != "" 
          && this.formulario.nome != "" 
          && this.formulario.telefone != ""
          && this.formulario.email != ""
          && (this.formulario.empresa !== this.usuario.nomeEmpresa  || this.formulario.nome !== this.usuario.nome  || this.formulario.telefone !== this.usuario.telefone || this.formulario.email !== this.usuario.email)
      },
      validFormEmail() {
        return (this.formularioEmail.email != "" && this.formularioEmail.email !== this.usuario.email) && (!this.formularioEmail.exibirToken || (this.formularioEmail.token != '' && this.formularioEmail.senha != ''))
      },
      validFormSenha() {
        return this.formularioSenha.novaSenha != "" 
          && this.formularioSenha.senhaAtual != "" 
          && this.formularioSenha.confirmeSenha != ""          
          && this.formularioSenha.confirmeSenha === this.formularioSenha.novaSenha
      }
  },
  watch: {
    'formulario' : {
      handler: function () {
        this.alert = null
      },
      deep: true
    },
    'formularioSenha' : {
      handler: function () {
        this.alert2 = null
      },
      deep: true
    },
    'formularioEmail' : {
      handler: function () {
        this.alert3 = null
      },
      deep: true
    }
  }
}
</script>
<style lang="scss">
  .view-minha-conta {
      .container-minha-conta-form {
          margin-bottom: 1rem;
      }
      .container-botoes-form {
          padding-top: 1rem;
          border-top: 1px solid $light-gray;
      }
  }
</style>
