<template>
   <div v-if="items.length" class="table-container">
      <b-table class="custom-table" hover :items="items" :fields="fields" show-empty emptyText="Você não possui nenhuma simulação gravada." sort-icon-left :busy="loading">
        <template #table-busy>
            <div class="text-center text-dark-gray my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Carregando informações...</strong>
            </div>
        </template>
      </b-table>
     <div class="d-flex justify-content-end">
       <router-link to="/simulacoes">Ver detalhes das simulações</router-link>
     </div>
  </div>
  <div v-else>
    <p v-if="loading">...</p>
    <p v-else>Você não possui nenhuma simulação gravada.</p>
  </div>
</template>

<script>

import SimulacaoService from '@/services/creditPlus/simulacao.service.js'

export default {
  name: 'SimulacoesDestaque',
  components: {
  },
  data() {
    return {
      fields: [
          { key: 'data', label: 'Data', sortable: false },
          { key: 'razaoSocial', label: 'Razão Social', sortable: false },
          { key: 'totalBens', label: 'Total dos bens', sortable: false },
      ],
      items: [],
      loading: false,
    }
  },    
  mounted() {
    this.listarSimulacoes();
  },
  methods: {
    listarSimulacoes() {
      this.loading = true;  
      this.items = [];
      SimulacaoService.listarUltimas().then(result => {
        this.loading = false
        if (result.data && result.data.itens.length > 0) {
            result.data.itens.forEach(element => {
              this.items.push({
                data: this.$uteis.formatDate(element.dataCriacao),
                id: element.id,
                razaoSocial: element.razaoSocial,
                totalBens: 'R$ ' + this.$uteis.formatNumber(element.valorTotalEquipamentos + element.valorAdicional + element.valorInstalacao),
              })
            });
        } else {
          this.alert = {
            type: 'danger',
            message: result ? result.message : ''
          };
        }
      });
    },
  }
}
</script>
