import ApiService from '@/services/creditPlus/creditplus.api.service.js'
import store from '@/store/index.js'

const AuthService = {
    api: new ApiService("/auth"),

    validarEmail: async function(data) {
        this.api.cleanToken();
        return await this.api.post("/validarEmail", data).then((response) => {
            return { success: true, data: response.data };
        }).catch(this.api.defaultCatch);
    },
    alterarSenha: async function(data) {
        this.api.cleanToken();
        return await this.api.post("/alterarSenha", data).then((response) => {
            return { success: true, data: response.data };
        }).catch(this.api.defaultCatch);
    },
    recuperarSenha: async function(data) {
        this.api.cleanToken();
        return await this.api.post("/recuperarSenha", data).then((response) => {
            return { success: true, data: response.data };
        }).catch(this.api.defaultCatch);
    },
    login: async function(data) {
        this.api.cleanToken();
        return await this.api.post("/login", data).then((response) => {
            return { success: true, data: response.data };
        }).catch(this.api.defaultCatch);
    },
    logout: async function () {
        this.api.setToken(store.getters.token);
        return await this.api.get("/logout").then((response) => {
            return { success: true, data: response.data };
        }).catch(this.api.defaultCatch);
    },
}

export default AuthService