<template>
    <div class="steppers">
        <div class="step pointer active" @click="step(1)">
            <div class="step-icon mx-auto">1</div>
            <p class="step-title">Dados complementares</p>
        </div>
        <div class="step pointer" @click="step(2)">
            <div class="step-icon mx-auto">2</div>
            <p class="step-title">Descreva os produtos</p>
        </div>
        <div class="step pointer" @click="step(3)">
            <div class="step-icon mx-auto">3</div>
            <p class="step-title">Upload de documentos</p>
        </div>
        <div class="step pointer" @click="step(4)">
            <div class="step-icon mx-auto">4</div>
            <p class="step-title">Finalizar proposta</p>
        </div>
        <span class="line"></span>
    </div>
</template>

<script>

export default {
  name: 'SteppersProposta',
  props:{ },
  components: {
  },
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
        step(numero) {
            if (this.$listeners && this.$listeners.steps) {
                this.$emit('steps', numero);
            }
        }
    },
}
</script>
<style lang="scss">
    .steppers {
        display: flex;
        justify-content: space-between;
        position: relative;
        border-bottom: 1px solid $light-gray;
        padding-bottom: 1rem;
        .step {
            display: flex;
            flex-direction: column;
            text-align: center;
            color: $primary;
            background-color: $white;
            z-index: 2;
            p.step-title {
                color: $primary;
                margin: 0;
                width: 100%;
                font-size: 12px;
            }
            .step-icon {
                border: 2px solid;
                border-radius: 50%;
                padding: 2px 10px;
                font-size: 14px;
                margin-bottom: 3px;
                min-width: 2rem;
                min-height: 2rem;
                line-height: 1.5rem;
            }
            &.active {
                .step-icon {
                    color: $white;
                    background-color: $black;
                    border-color: $black;
                }
                p.step-title {
                    color: $black;
                    font-weight: bold;
                }
            }
            &.active ~ .step {
                cursor: auto;
                pointer-events: none;
                color: $gray;
                p.step-title {
                    color: $gray;
                }
            }
        }
        span.line {
            position: absolute;
            width: 100%;
            background: $primary;
            height: 1px;
            margin: 0;
            top: 25%;
            z-index: 1;
        }
    }
    @media (max-width: $min-md) {
        .steppers {
            span.line {
                display: none;
            }
        }
    }
    @media (max-width: $min-sm) {
        .steppers {
            flex-direction: column;
            padding: 0;
            .step {
                padding-bottom: 10px;
                flex-direction: row !important;
                .step-title {
                    display: inline;
                    text-align: initial;
                    padding-left: 10px;
                    padding-top: 7px;
                }
            }
        }
    }
</style>