<template>
    <div class="base-layout-container">
        <Header></Header>
        <router-view class="container" />
    </div>
</template>

<script>
    import Header from "@/components/layout/Header.vue"
    export default {
        name: "BaseLayout",
        components: {
            Header
        },
        data() {
            return {

            }
        },
        beforeMount() {
            document.querySelector("body").removeAttribute("class")
        },
        mounted() {
            document.querySelector("body").classList.add("base-layout")
        },
    }
</script>
<style lang="scss">
    .base-layout {
        background-color: #F5FAFC !important;
    }
    .base-layout-container {
        min-height: 100%;
        .container {
            min-height: 100%;
        }
    }
    body.body-header {
        .base-layout-container {
            min-height: calc(100% - 75px);
            .container {
                min-height: calc(100% - 75px);
            }
        }
    }
    @media (max-width: $min-sm) {
        .base-layout {
            background-color: #FFF !important;
        }
    }
</style>