<template>
  <div class="fh-container container-view-nova-senha col-md-8 offset-md-2 ajuste-form-auth">
    <h1>Recadastre sua senha</h1>
    <p>Digite uma nova senha nos campos abaixo. Tente escolher uma segura, com letras, números e caracteres especiais (#, @, !, *).</p>
      <form class="mt-1 fh-form" @submit.prevent="submitForm()" data-vv-scope="formulario">
        <div class="form-group">
              <label for="senhaAtual">Senha atual</label>
              <div class="input-icon">
                <input :class="{'form-control':true, 'is-invalid': errors.has('formulario.senhaAtual')}" placeholder="Digite sua nova senha" v-model="formulario.senhaAtual"
                        v-validate="'required'" :type="senhaVisivel ? 'text' : 'password'" name="senhaAtual" ref="senhaAtual" autocomplete="new-password" />
                <div class="icon" @click="senhaVisivel = !senhaVisivel">
                    <CustomIcon :name="senhaVisivel ? 'hide' : 'show'"/>
                </div>
              <span v-show="errors.has('formulario.senhaAtual')" class="invalid-feedback">{{ errors.first('formulario.senhaAtual') }}</span>
          </div>
        </div>
        <div class="form-group">
          <label for="novaSenha">Nova senha</label>
          <div class="input-icon">
            <input :class="{'form-control':true, 'is-invalid': errors.has('formulario.novaSenha')}" placeholder="Digite sua nova senha" v-model="formulario.novaSenha"
                    v-validate="'required|min:6|excluded:' + formulario.senhaAtual" :type="novaSenhaVisivel ? 'text' : 'password'" name="novaSenha" ref="novaSenha" />
            <div class="icon" @click="novaSenhaVisivel = !novaSenhaVisivel">
             <CustomIcon :name="novaSenhaVisivel ? 'hide' : 'show'"/>
            </div>
            <span v-show="errors.has('formulario.novaSenha')" class="invalid-feedback">{{ errors.first('formulario.novaSenha') }}</span>
          </div>
        </div>
        <div class="form-group">
          <label for="confirmeSenha">Confirme a nova senha</label>
          <input :class="{'form-control':true, 'is-invalid': errors.has('formulario.confirmeSenha')}" placeholder="Confirme sua senha" v-model="formulario.confirmeSenha"
                  v-validate="'required|confirmed:novaSenha'" :type="'password'" name="confirmeSenha" />
          <span v-show="errors.has('formulario.confirmeSenha')" class="invalid-feedback">{{ errors.first('formulario.confirmeSenha') }}</span>
        </div>
        <CustomAlert :alert="alert"></CustomAlert>
        <div class="container-botoes-form">
          <button class="btn btn-custom primary default" type="submit" :disabled="!validForm">Criar nova senha</button>
        </div>
      </form>
  </div>
</template>

<script>
import UsuarioService from '@/services/creditPlus/usuario.service.js'

export default {
  name: 'RecadastrarSenha',
  data() {
      return {
          formulario: {
              senhaAtual: '',
              novaSenha: '',
              confirmeSenha: ''
          },
          loading: false,
          alert: null,
          senhaVisivel: false,
          novaSenhaVisivel: false,
      }
  },
  methods: {
    submitForm() {
      let data = {
        senha: this.formulario.novaSenha,
        senhaAtual: this.formulario.senhaAtual
      }
      UsuarioService.alterarSenha(data).then(result => {
        this.loading = false;
        if (result && result.data.success) {
          this.$store.commit("setAuth", {token: this.$store.getters.auth.token, perfil: this.$store.getters.auth.perfil, altS: false});
          this.$router.push("/");
        } else {
          this.alert = {
            type: 'danger',
            message: result ? result.data.message : ''
          };
        }
      });
    }
  },
  computed: {
      validForm() {
        return this.formulario.senhaAtual != ''
            && this.formulario.novaSenha != ''
            && this.formulario.confirmeSenha != ''
            && this.formulario.confirmeSenha === this.formulario.novaSenha
            && this.errors.items.length == 0
      }
  },
}
</script>

<style scoped lang="scss">
.container-view-nova-senha {
   @media (max-width: $min-sm) {
        flex: 1 !important;
    }
    @media (min-width: $min-sm) {
      min-height: 60%;
      justify-content: center;
    }
    display: flex;
    flex-direction: column;
}
</style>
