<template>
    <div>
        <div v-show="tela == 'form'" class="view-form-proposta">
            <steppers-proposta @steps="steps"/>
            <form class="mt-1 fh-form" @submit.prevent="submitForm()" data-vv-scope="formulario">
                <div class="mt-3 mb-2" :class="{'d-none': step != 1}">
                    <h3 class="mb-3">Dados complementares</h3>
                    <div class="d-flex flex-column flex-md-row">
                        <div class="flex-2 pr-md-5 pr-0">
                            <label>CNPJ</label>
                            <p class="m-0 font-weight-bold">{{$uteis.formatCnpj(this.simulacao.cnpj) || ''}}</p>
                        </div>
                        <div class="flex-2 pl-md-5 pl-0 pt-3 pt-md-0">
                            <label>Razão social</label>
                            <p class="m-0 font-weight-bold">{{this.simulacao.razaoSocial || ''}}</p>
                        </div>
                    </div>
                    <div class="d-flex flex-column flex-md-row mt-4">
                        <div class="flex-column flex-1 pr-md-5 pr-0">
                            <div class="form-group">
                                <label for="cep">CEP</label>
                                    <TheMask :class="{'form-control': true, 'is-invalid': errors.has('formulario.cep')}" :mask="'#####-###'" placeholder="00000-000"
                                    v-model="formulario.cep" v-validate="'required'" type="text" name="cep" autocomplete="off"/>
                                <span v-show="errors.has('formulario.cep')" class="invalid-feedback">{{ errors.first('formulario.cep') }}</span>
                            </div>
                            <div class="form-group">
                                <label for="cidade">Cidade</label>
                                <input :class="{'form-control': true, 'is-invalid': errors.has('formulario.cidade')}" placeholder="Digite o nome da cidade"
                                v-model="formulario.cidade" v-validate="'required'" type="text" name="cidade" autocomplete="off"/>
                                <span v-show="errors.has('formulario.cidade')" class="invalid-feedback">{{ errors.first('formulario.cidade') }}</span>
                            </div>
                            <div class="form-group">
                                <label for="estado">Estado</label>
                                    <input :class="{'form-control': true, 'is-invalid': errors.has('formulario.estado')}" placeholder="Digite um estado"
                                    v-model="formulario.estado" v-validate="'required'" type="text" name="estado" autocomplete="off"/>
                                <span v-show="errors.has('formulario.estado')" class="invalid-feedback">{{ errors.first('formulario.estado') }}</span>
                            </div>
                        </div>
                        <div class="flex-column flex-1 pl-md-5 pl-0">
                            <div class="form-group">
                                <label for="endereço">Endereço completo</label>
                                    <input :class="{'form-control': true, 'is-invalid': errors.has('formulario.endereco')}" placeholder="Bairro, Rua / Av, Nº, Complemento"
                                    v-model="formulario.endereco" v-validate="'required'" type="text" name="endereco" autocomplete="off" id="endereco"/>
                                <span v-show="errors.has('formulario.endereco')" class="invalid-feedback">{{ errors.first('formulario.endereco') }}</span>
                            </div>
                            <div class="form-group">
                                <label for="telefone">Telefone</label>
                                <TheMask :class="{'form-control': true, 'is-invalid': errors.has('formulario.telefone')}" placeholder="(00) 00000-0000" :mask="['(##) ####-####', '(##) #####-####']"
                                    v-model="formulario.telefone" v-validate="'required|telefone'" type="text" name="telefone" autocomplete="off"/>
                                <span v-show="errors.has('formulario.telefone')" class="invalid-feedback">{{ errors.first('formulario.telefone') }}</span>
                            </div>
                            <div class="form-group">
                                <label for="email">E-mail</label>
                                <input :class="{'form-control': true, 'is-invalid': errors.has('formulario.email')}" placeholder="Digite um endereço de e-mail" 
                                    v-model="formulario.email" v-validate="'required|email'" type="text" name="email" autocomplete="off"/>
                                <span v-show="errors.has('formulario.email')" class="invalid-feedback">{{ errors.first('formulario.email') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="container-btns-simulacao-form-proposta">
                        <button class="btn btn-custom secondary default mr-3" type="button" @click="salvar()" :disabled="!validFormDadosComplementares">Salvar</button>
                        <button class="btn btn-custom primary default" type="button" @click="steps(2)" :disabled="!validFormDadosComplementares">Próximo</button>
                    </div>
                </div>
                <div class="mt-3 mb-2" :class="{'d-none': step != 2}">
                    <h3 class="mb-3">Descreva os produtos</h3>
                    <p>Detalhe abaixo os produtos a serem incluídos nesta proposta.</p>
                    <div class="form-group mt-3">
                        <label for="descricaoProduto">Descreva os produtos</label>
                        <textarea rows="7" :class="{'form-control': true, 'is-invalid': errors.has('formulario.descricaoProduto')}" placeholder="Digite aqui os produtos que serão incluídos nesta proposta."
                        v-model="formulario.descricaoProduto" v-validate="'required|min:30'" type="text" name="descricaoProduto" autocomplete="off"/>
                        <span v-show="errors.has('formulario.descricaoProduto')" class="invalid-feedback">{{ errors.first('formulario.descricaoProduto') }}</span>
                    </div>
                    <div class="container-btns-simulacao-form-proposta">
                        <button class="btn btn-custom secondary default mr-3" type="button" @click="salvar()" :disabled="!validFormDescricaoProduto">Salvar</button>
                        <button class="btn btn-custom primary default" type="button" @click="steps(3)" :disabled="!validFormDescricaoProduto">Próximo</button>
                    </div>
                </div>
                <div class="mt-3 mb-2" :class="{'d-none': step != 3}">
                    <h3 class="mb-3">Upload de documentos</h3>
                    <p>Carregue os documentos necessários para o prosseguimento da proposta.</p>
                    <p class="font-weight-bold small">O tamanho limite de cada arquivo é de 2MB e os formatos aceitos são: JPG, JPEG, PNG e PDF.</p>
                    <div v-if="dadosProposta && dadosProposta.documentos && dadosProposta.documentos.length > 0">
                        <h5 class="mb-3">Documentos salvos</h5>
                        <div class="d-flex flex-row">
                            <div class="d-flex flex-column flex-1" v-for="i in [0,1]" :key="i.key">
                                <div class="d-contents" v-for="item in dadosProposta.documentos.filter(d => dadosProposta.documentos.indexOf(d) % 2 == i && d.model != null && d.fileModel != null )" :key="item.key">
                                    <div class="d-flex flex-row justify-content-start mb-1">
                                        <CustomIcon :name="'check-circle'" :class="'text-custom success mr-1'"
                                        v-b-tooltip.hover :title="'Documento salvo'"/>
                                        <p class="mb-0 documento-tipo">Tipo: {{item.model.value}}</p>
                                    </div>
                                    <p class="font-weight-bold">{{item.fileModel.name}} <CustomIcon :name="'external-link'" :width="'18'"/> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column flex-sm-row justify-content-between mt-2" v-for="(item, id) in formulario.documentos.itens" :key="item.key">
                        <div class="form-group d-flex flex-column flex-1 pr-md-5 pr-0">
                            <label :for="'fileModel' + id">Selecione um arquivo</label>
                            <b-form-file v-model="item.fileModel" placeholder="Selecione um arquivo" :name="'formulario.documentos.itens[' + id + '].fileModel'" accept=".jpg, .png, .pdf, .jpeg" v-validate="'required'">

                            </b-form-file>
                            <span v-show="errors.has('formulario.documentos.itens[' + id + '].fileModel')" class="invalid-feedback-custom">{{ errors.first('formulario.documentos.itens[' + id + '].fileModel') }}</span>
                        </div>
                        <div class="form-group d-flex flex-column flex-1 pl-sm-2 pl-0">
                            <label :for="'tipodocumento' + id">Tipo de documento</label>
                            <multiselect v-model="item.model" :options="formulario.documentos.options" placeholder="Selecione" label="value" track-by="key" :name="'formulario.documentos.itens[' + id + '].model'" v-validate="'required'">
                                <template slot="option" slot-scope="props">
                                    <span class="">{{props.option.value}}</span>
                                </template>
                            </multiselect>
                            <template slot="noResult">Nenhum resultado encontrado.</template>
                            <span v-show="errors.has('formulario.documentos.itens[' + id + '].model')" class="invalid-feedback-custom">{{ errors.first('formulario.documentos.itens[' + id + '].model') }}</span>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <p :class="formulario.documentos.itens.length < formulario.documentos.options.length ? 'pointer' : 'text-custom gray'" @click="adicionarDocumento"><CustomIcon :name="'circle-plus'"/> Adicionar documento</p>
                        <p v-if="formulario.documentos.itens.length > 1 || (dadosProposta && dadosProposta.documentos && dadosProposta.documentos.length > 0 && formulario.documentos.itens.length >= 1)" class="pointer" @click="formulario.documentos.itens.pop() "><CustomIcon :name="'x'" class="text-custom danger"/> Remover documento</p>
                    </div>
                    <div class="p-3 border border-dark">
                        <p class="small font-weight-bold">Documentos recomendados para a análise:</p>
                        <div class="d-flex flex-column flex-md-row">
                            <ul class="flex-1 mb-0 small">
                                <li>Contrato Social</li>
                                <li>Ficha Cadastral</li>
                                <li>Comprovante de endereço</li>
                                <li>CPF do(s) sócio(s)</li>
                            </ul>
                            <ul class="flex-1 small">
                                <li>RG do(s) sócio(s)</li>
                                <li>CNH do(s) sócio(s)</li>
                                <li>Comprovante de endereço do(s) sócio(s)</li>
                            </ul>
                        </div>
                        <p class="my-2 small">Não deixe de enviar outros documentos caso disponíveis. Outros documentos podem ser solicitados eventualmente dependendo da análise de cada empresa.</p>
                    </div>
                    <div class="container-btns-simulacao-form-proposta">
                        <button class="btn btn-custom secondary default mr-3" type="button" @click="salvar()" :disabled="!validFormAnexos">Salvar</button>
                        <button class="btn btn-custom primary default" type="button" @click="steps(4)" :disabled="!validFormAnexos">Próximo</button>
                    </div>
                </div>
                <div class="mt-3 mb-2" :class="{'d-none': step != 4}">
                    <h3 class="mb-3">Finalizar registro da proposta</h3>
                    <p>Verifique abaixo os dados da sua proposta e, caso esteja tudo certo, clique em “Enviar Proposta”.</p>
                    <hr>
                    <h5>Dados do contrato</h5>
                    <div class="d-flex flex-column flex-md-row">
                        <div class="d-flex flex-column flex-1">
                            <p class="mb-0">Valor total dos equipamentos</p>
                            <p class="font-weight-bold">{{'R$ ' + $uteis.formatNumber(simulacao.valorTotalEquipamentos)}}</p>
                            <p class="mb-0">Valores adicionais (Software, etc)</p>
                            <p class="font-weight-bold">{{'R$ ' + $uteis.formatNumber(simulacao.valorAdicional)}}</p>
                            <p class="mb-0">Prazo Contrato</p>
                            <p class="font-weight-bold">{{simulacao.prazoContrato}}</p>
                        </div>
                        <div class="d-flex flex-column flex-1">
                            <p class="mb-0">Valor total da instalação dos equipamentos (Máximo 10%)</p>
                            <p class="font-weight-bold">{{'R$ ' + $uteis.formatNumber(simulacao.valorInstalacao)}}</p>
                            <p class="mb-0">Valor total a ser recebido pela {{$nome}}</p>
                            <p class="font-weight-bold">{{'R$ ' + $uteis.formatNumber(simulacao.totalRecebido)}}</p>
                        </div>
                    </div>
                    <div class="d-flex flex-column flex-md-row bg-dark-white p-3">
                        <div class="d-flex flex-column flex-1">
                            <p class="mb-0">Valor estimado da parcela mensal ({{$nome}})</p>
                            <p class="font-weight-bold mb-0">{{'R$ ' + $uteis.formatNumber($uteis.calculoParcela(simulacao.taxa, simulacao.totalRecebido, simulacao.prazo, simulacao.seguro))}}</p>
                        </div>
                        <div v-if="simulacao.seguro" class="d-flex flex-column flex-1">
                            <p class="mb-0">Valor estimado de Proteção e Danos</p>
                            <p class="font-weight-bold mb-0">{{'R$ ' + $uteis.formatNumber($uteis.calculoProtecao(simulacao.totalRecebido, simulacao.prazo))}}</p>
                        </div>
                    </div>
                    <hr>
                    <h5>Dados complementares</h5>
                    <div class="d-flex flex-column flex-md-row">
                        <div class="d-flex flex-column flex-1">
                            <p class="mb-0">CNPJ</p>
                            <p class="font-weight-bold">{{$uteis.formatCnpj(simulacao.cnpj)}}</p>
                            <p class="mb-0">CEP</p>
                            <p class="font-weight-bold">{{formulario.cep}}</p>
                            <p class="mb-0">Cidade</p>
                            <p class="font-weight-bold">{{formulario.cidade}}</p>
                            <p class="mb-0">Telefone</p>
                            <p class="font-weight-bold">{{$uteis.formatTelefone(formulario.telefone)}}</p>
                        </div>
                        <div class="d-flex flex-column flex-1">
                            <p class="mb-0">Razão Social</p>
                            <p class="font-weight-bold">{{simulacao.razaoSocial}}</p>
                            <p class="mb-0">Estado</p>
                            <p class="font-weight-bold">{{formulario.estado}}</p>
                            <p class="mb-0">Endereço completo</p>
                            <p class="font-weight-bold">{{formulario.endereco}}</p>
                            <p class="mb-0">E-mail</p>
                            <p class="font-weight-bold">{{formulario.email}}</p>
                        </div>
                    </div>
                    <hr>
                    <h5>Descrição dos produtos</h5>
                    <p>{{formulario.descricaoProduto}}</p>
                    <hr>
                    <h5>Documentos</h5>
                    <div class="d-flex flex-column flex-md-row">
                        <div class="d-flex flex-column flex-1" v-for="i in [0,1]" :key="i.key">
                            <div class="d-contents" v-for="item in listarDocumentos.filter(d => listarDocumentos.indexOf(d) % 2 == i && d.model != null && d.fileModel != null )" :key="item.key">
                                <div class="d-flex flex-row justify-content-start mb-1">
                                    <CustomIcon :name="item.salvo ? 'check-circle' : 'info'" :class="item.salvo ? 'text-custom success' : 'text-custom warning'"
                                    v-b-tooltip.hover :title="item.salvo ? 'Documento salvo' : 'Documento não salvo'"/>
                                    <p class="mb-0 documento-tipo">Tipo: {{item.model.value}}</p>
                                </div>
                                <p class="font-weight-bold">{{item.fileModel.name}} <CustomIcon :name="'external-link'" :width="'18'"/> </p>
                            </div>
                        </div>
                    </div>
                    <div v-if="formulario.justificativa" class="d-contents">
                        <hr>
                        <h5>Informações adicionais</h5>
                        <p>{{formulario.justificativa}}</p>
                    </div>
                    <div class="container-btns-simulacao-form-proposta">
                        <button class="btn btn-custom secondary default mr-3" type="button" @click="salvar()" :disabled="!(validFormAnexos && validFormDadosComplementares && validFormDescricaoProduto)">Salvar</button>
                        <button v-if="!proposta || proposta.status != this.$enums.statusProposta.ANALISE.key" class="btn btn-custom primary default" type="button" @click="enviar()" :disabled="!(validFormAnexos && validFormDadosComplementares && validFormDescricaoProduto)">Enviar proposta</button>
                    </div>
                </div>
                <CustomAlert :alert="alert"></CustomAlert>
            </form>
        </div>
        <div v-show="tela == 'enviada'" class="view-form-proposta-enviada mx-auto">
            <div class="text-success">
                <CustomIcon :name="'check-circle'" class="mb-2 text-success" :height="'34'" :width="'34'"></CustomIcon>
                <h5 class="text-success">Proposta enviada com sucesso!</h5>
            </div>
            <div class="my-4">
                <p>A sua proposta {{dadosProposta ? dadosProposta.identificador : ''}} foi enviada para a análise dos especialistas da {{$nome}}.</p>
                <p>Você receberá um e-mail quando a análise for concluída.</p>
            </div>
            <div>
                <router-link to="/propostas" class="btn btn-custom secondary default">Consulte suas propostas</router-link>
            </div>
        </div>
        <div v-show="tela == 'pendencia'" class="view-form-proposta-pendencia">
            <h3 class="">Informações Adicionais</h3>
            <p class="">De forma a ajudar na análise de crédito desta empresa, insira no campo abaixo uma justificativa para ser analisada pelo time da Allugg.</p>
            <div class="d-flex flex-column flex-sm-row">
                <div class="flex-2 align-self-start align-self-sm-center pr-md-2 pr-0 mb-3 mb-sm-0">
                    <label>CNPJ</label>
                    <p class="m-0 font-weight-bold">{{$uteis.formatCnpj(this.simulacao.cnpj) || ''}}</p>
                </div>
                <div class="flex-2 align-self-center pl-md-2 pr-0 mb-3 mb-sm-0">
                    <label>Razão social</label>
                    <p class="m-0 font-weight-bold">{{this.simulacao.razaoSocial || ''}}</p>
                </div>
            </div>
            <div class="form-group">
                <label for="justificativa">Descreva os produtos</label>
                <textarea rows="7" :class="{'form-control': true, 'is-invalid': errors.has('formulario.justificativa')}" placeholder="Digite uma justificativa"
                v-model="formulario.justificativa" v-validate="'required|min:30'" type="text" name="justificativa" autocomplete="off"/>
                <span v-show="errors.has('formulario.justificativa')" class="invalid-feedback">{{ errors.first('formulario.justificativa') }}</span>
            </div>
            <div class="container-btns-simulacao-form-proposta">
                <button class="btn btn-custom secondary default mr-3" type="button" @click="$bvModal.show('modal-cancelar-proposta')">Cancelar</button>
                <button class="btn btn-custom primary default" type="button" @click="tela='form'; steps(4)" :disabled="formulario.justificativa.length < 30">Prosseguir</button>
            </div>
        </div>
        <div v-show="tela == 'cancelada'" class="view-form-proposta-cancelada mx-auto">
            <div class="text-success">
                <CustomIcon :name="'check-circle'" class="mb-2 text-success" :height="'34'" :width="'34'"></CustomIcon>
                <h5 class="text-success">Proposta cancelada com sucesso!</h5>
            </div>
            <div class="my-4">
                <p>A sua proposta {{dadosProposta ? dadosProposta.identificador : ''}} foi cancelada.</p>
                <p>Você poderá visualizar as suas propostas clicando no botão abaixo.</p>
            </div>
            <div>
                <router-link to="/propostas" class="btn btn-custom secondary default">Consulte suas propostas</router-link>
            </div>
        </div>
        <b-modal id="modal-cancelar-proposta" centered footerClass="d-flex justify-content-between">
            <template #modal-header>
                <h5 class="modal-title">Cancelar proposta</h5>
                <button type="button" aria-label="Close" class="close" @click="$bvModal.hide('modal-cancelar-proposta')">
                    <CustomIcon :name="'x'" />
                </button>
            </template>
            <p>Você tem certeza que deseja cancelar a proposta? </p>
            <p>Essa operação é irreversível e sua proposta assumirá o status "<strong>cancelada</strong>".</p>
            <CustomAlert :alert="alert2"></CustomAlert>
            <template #modal-footer>
                <button class="btn btn-link" @click="$bvModal.hide('modal-cancelar-proposta')">Voltar</button>
                <button class="btn btn-custom primary default" @click="cancelar()">Cancelar</button>
            </template>
        </b-modal>
        <ModalLoading :message="loadingMessage"></ModalLoading>
    </div>
</template>

<script>
import PropostaService from '@/services/creditPlus/proposta.service.js'
import { TheMask } from 'vue-the-mask'
import Multiselect from 'vue-multiselect'
import SteppersProposta from '@/views/Proposta/SteppersProposta.vue'

export default {
  name: 'FormProposta',
  props:{ simulacao: Object, proposta: Object },
  components: {
        TheMask,
        Multiselect,
        SteppersProposta,
  },
    data() {
        return {
            alert: null,
            alert2: null,
            step: 1,
            tela: 'form',
            loadingMessage: '',
            dadosProposta: null,
            formulario: {
                cep: '',
                cidade: '',
                telefone: '',
                estado: '',
                endereco: '',
                email: '',
                descricaoProduto: '',
                justificativa: '',
                documentos: {
                    options: [],
                    itens: [{ model: null, fileModel: null }]
                }
            }
        }
    },
    mounted() {
        this.formulario.documentos.options = this.$enums.getArray(this.$enums.tipoDocumento)
        this.steps(1)
    },
    updated() {
        if (this.proposta == null && this.simulacao && this.simulacao.analiseItem != null && this.formulario.cep == '') {
            this.formulario.cep = this.simulacao.analiseItem.cep
            this.formulario.telefone = this.simulacao.analiseItem.telefonePrincipal
            this.formulario.cidade = this.simulacao.analiseItem.municipioEndereco
            this.formulario.estado = this.simulacao.analiseItem.uf
            this.formulario.email = this.simulacao.analiseItem.emailPrincipal
            this.formulario.endereco = this.simulacao.analiseItem.bairroEndereco + ", " + this.simulacao.analiseItem.logradouro + ", " + this.simulacao.analiseItem.complementoEndereco 
        }
    },
    methods: {
        adicionarDocumento() {
            if (this.formulario.documentos.itens.length < this.formulario.documentos.options.length) {
                this.formulario.documentos.itens.push({ model: null, fileModel: null })
            }
        },
        steps (numero) {
            this.alert = null
            this.step = numero
            document.querySelector('.step.active').classList.remove('active')
            document.querySelector('.step:nth-child(' + numero + ')').classList.add('active')
        },
        cancelar() {
            PropostaService.cancelar({id: this.dadosProposta.id}).then(result => {
                if (result.success && result.data && result.data.success) {
                    this.$bvModal.hide('modal-cancelar-proposta')
                    this.tela = 'cancelada'
                    if (this.$listeners && this.$listeners.atualizarTitulo) {
                        this.$emit('atualizarTitulo', 'Proposta cancelada com sucesso')
                    }
                } else {
                    this.alert2 = {
                        type: 'danger',
                        message: 'Falha ao cancelar proposta, tente novamente.'
                    };
                }
            });
        },
        salvar() {
            this.submit(false)
        },
        enviar() {
            this.submit(true)
        },
        submit(propostaEnviadaParaAnalise) {
            let status = propostaEnviadaParaAnalise ? this.$enums.statusProposta.ANALISE.key : this.$enums.statusProposta.SALVA.key
            if (this.dadosProposta == null) {
                this.dadosProposta = {
                    status: status,
                    cep: this.formulario.cep,
                    cidade: this.formulario.cidade,
                    telefone: this.formulario.telefone,
                    estado: this.formulario.estado,
                    endereco: this.formulario.endereco,
                    email: this.formulario.email,
                    descricaoProduto: this.formulario.descricaoProduto,
                    justificativa: this.formulario.justificativa,
                    idSimulacao: this.simulacao.id,
                    documentos: []
                };
                var form = new FormData();
                Object.entries(this.dadosProposta).forEach(element => {
                    if (element[0] != "documentos") {
                        form.append(element[0], element[1])
                    }
                });
                this.formulario.documentos.itens.forEach((element, index) => {
                    form.append(`documentos[${index}].documento`, element.fileModel)
                    form.append(`documentos[${index}].tipo`, element.model.key)
                });
                this.loadingMessage = propostaEnviadaParaAnalise ? "Enviando proposta" : "Salvando proposta"

                PropostaService.criar(form).then(result => {
                    this.loadingMessage = ""
                    if (result.success && result.data.success) {
                        this.dadosProposta.id = result.data.id
                        this.dadosProposta.identificador = result.data.identificador
                        this.formulario.documentos.itens.forEach(element => {
                            this.dadosProposta.documentos.push({fileModel: element.fileModel, model: element.model})
                        });
                        this.formulario.documentos.itens = []
                        if (propostaEnviadaParaAnalise) {
                            if (result.data.pendente) {
                                this.tela = 'pendencia'
                            } else {
                                this.tela = 'enviada'
                                if (this.$listeners && this.$listeners.atualizarTitulo) {
                                    this.$emit('atualizarTitulo', 'Proposta enviada com sucesso')
                                }
                            }
                        } else {
                            this.alert = {
                                type: 'success',
                                message: 'Dados da proposta salvos, agora a sua simulação vai aparecer junto à <a href="/propostas">suas propostas</a>.'
                            };
                        }
                    } else {
                        this.dadosProposta = null
                        this.alert = {
                            type: 'danger',
                            message: result ? result.message : ''
                        };
                    }
                });
            } else {
                this.dadosProposta = {
                    id: this.dadosProposta.id,
                    identificador: this.dadosProposta.identificador,
                    status: status,
                    cep: this.formulario.cep,
                    cidade: this.formulario.cidade,
                    telefone: this.formulario.telefone,
                    estado: this.formulario.estado,
                    endereco: this.formulario.endereco,
                    email: this.formulario.email,
                    descricaoProduto: this.formulario.descricaoProduto,
                    justificativa: this.formulario.justificativa,
                    documentos: this.dadosProposta.documentos
                };
                var formEditar = new FormData();
                Object.entries(this.dadosProposta).forEach(element => {
                    if (element[0] != "documentos") {
                        formEditar.append(element[0], element[1])
                    }
                });
                this.formulario.documentos.itens.forEach((element, index) => {
                    formEditar.append(`documentos[${index}].documento`, element.fileModel)
                    formEditar.append(`documentos[${index}].tipo`, element.model.key)
                });
                this.loadingMessage = propostaEnviadaParaAnalise ? "Enviando proposta" : "Salvando proposta"

                PropostaService.editar(formEditar).then(result => {
                    this.loadingMessage = ""
                    if (result.success && result.data && result.data.success) {
                        this.formulario.documentos.itens.forEach(element => {
                            this.dadosProposta.documentos.push({fileModel: element.fileModel, model: element.model})
                        });
                        this.formulario.documentos.itens = []
                        if (propostaEnviadaParaAnalise) {
                            if (result.data.pendente) {
                                this.tela = 'pendencia'
                            } else {
                                this.tela = 'enviada'
                                if (this.$listeners && this.$listeners.atualizarTitulo) {
                                    this.$emit('atualizarTitulo', 'Proposta enviada com sucesso')
                                }
                            }
                        } else {
                            this.alert = {
                                type: 'success',
                                message: 'Dados da proposta salvos, confira suas propostas em <a href="/propostas" class="link-light">suas propostas</a>.'
                            };
                        }
                    } else {
                        this.alert = {
                            type: 'danger',
                            message: result ? result.message : ''
                        };
                    }
                });
            }
        },
    },
    computed: {
        validFormAnexos() {
            return this.formulario.documentos.itens.filter(d => d.model == null || d.fileModel == null).length == 0 
                && this.errors.items.length == 0
        },
        validFormDescricaoProduto() {
            return this.formulario.descricaoProduto != ''
                && this.errors.items.length == 0
        },
        validFormDadosComplementares() {
            return this.formulario.cep != ''
                && this.formulario.cidade != ''
                && this.formulario.telefone != ''
                && this.formulario.estado != ''
                && this.formulario.endereco != ''
                && this.formulario.email != ''
                && this.errors.items.length == 0
        },
        listarDocumentos() {
            var a = []
            if (this.formulario.documentos.itens.length > 0) {
                a = this.formulario.documentos.itens.map(d => {
                    return { fileModel: d.fileModel, model: d.model, salvo: false }
                })
            }
            var b = []
            if (this.dadosProposta && this.dadosProposta.documentos && this.dadosProposta.documentos.length > 0) {
                b = this.dadosProposta.documentos.map(d => {
                    return { fileModel: d.fileModel, model: d.model, salvo: true }
                })
            }
            return a.concat(b)
        }
    },
    watch: {
        'proposta' : {
            handler: function() {
                if (this.proposta && !this.dadosProposta) {
                    this.formulario.cep = this.proposta.cep
                    this.formulario.cidade = this.proposta.cidade
                    this.formulario.telefone = this.proposta.telefone
                    this.formulario.estado = this.proposta.estado
                    this.formulario.endereco = this.proposta.endereco
                    this.formulario.email = this.proposta.email
                    this.formulario.descricaoProduto = this.proposta.descricaoProduto
                    this.formulario.justificativa = this.proposta.justificativa

                    this.dadosProposta = {
                        id: this.proposta.id,
                        cep: this.formulario.cep,
                        cidade: this.formulario.cidade,
                        telefone: this.formulario.telefone,
                        estado: this.formulario.estado,
                        endereco: this.formulario.endereco,
                        email: this.formulario.email,
                        descricaoProduto: this.formulario.descricaoProduto,
                        justificativa: this.formulario.justificativa,
                        idSimulacao: this.simulacao.id,
                        documentos: this.proposta.documentos
                    };
                    if (this.dadosProposta.documentos.length > 0) {
                        this.formulario.documentos.itens = []
                    }
                }
            },
            deep: true,
        },
        'steps' : {
            handler: function () {
                this.alert = null
            },
            deep: true
        },
        'tela' : {
            handler: function () {
                this.alert = null
            },
            deep: true
        },
    }
}
</script>
<style lang="scss">
    .container-btns-simulacao-form-proposta {
        border-top: 1px solid $light-gray;
        padding: 1rem 0 0 0;
        margin: 1rem 0 0 0;
    }
    .view-form-proposta-enviada, .view-form-proposta-cancelada {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        max-width: 600px;
        min-height: 400px;
    }
    .documento-tipo {
        line-height: inherit;
    }
</style>