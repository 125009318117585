<template>
    <div class="fh-container container-view-login col-md-8 offset-md-2 ajuste-form-auth">
        <h1>Faça seu login</h1>
        <p>Digite seu e-mail e senha nos campos abaixo.</p>
        <form class="mt-1 fh-form" @submit.prevent="submitForm()" data-vv-scope="formulario">
            <div class="form-group">
                <label for="email">E-mail</label>
                <input :class="{'form-control': true, 'is-invalid': errors.has('formulario.email')}" placeholder="Digite seu e-mail" v-model="formulario.email" v-validate="'required|email'" type="email" name="email" />
                <span v-show="errors.has('formulario.email')" class="invalid-feedback">{{ errors.first('formulario.email') }}</span>
            </div>
            <div class="form-group">
                <label for="senha">Senha</label>
                <div class="input-icon">
                    <input
                        :class="{'form-control':true, 'is-invalid': errors.has('formulario.senha')}"
                        placeholder="Digite sua senha"
                        v-model="formulario.senha"
                        v-validate="'required'"
                        :type="senhaVisivel ? 'text' : 'password'"
                        name="senha" />
                    <div class="icon" @click="senhaVisivel = !senhaVisivel">
                        <CustomIcon :name="senhaVisivel ? 'hide' : 'show'" />
                    </div>
                    <span v-show="errors.has('formulario.senha')" class="invalid-feedback">{{ errors.first('formulario.senha') }}</span>
                </div>
            </div>
            <CustomAlert :alert="alert"></CustomAlert>
            <div class="container-botoes-form">
                <button class="btn btn-custom primary default" type="submit" :disabled="!validForm">Entrar</button>
                <router-link class="mt-2 mt-sm-0" to="/recuperar-senha">Esqueceu sua senha?</router-link>
            </div>
        </form>
        <ModalLoading :message="loadingMessage"></ModalLoading>
    </div>
</template>

<script>
    import AuthService from "@/services/creditPlus/auth.service.js"

    export default {
        name: "LoginForm",
        data() {
            return {
                formulario: {
                    email: "",
                    senha: "",
                },
                loadingMessage: "",
                alert: null,
                senhaVisivel: false,
            }
        },
        methods: {
            submitForm() {
                let data = {
                    email: this.formulario.email,
                    senha: this.formulario.senha,
                    configBrowser: this.$uteis.getBrowserData()
                }
                this.loadingMessage = " "
                AuthService.login(data).then((result) => {
                    this.alert = null
                    this.loadingMessage = ""
                    if (result && result.success) {
                        if (!result.data.token) {
                            this.alert = {
                                type: result.data.type || "info",
                                message: result ? result.data.message : "",
                            }
                        } else {
                            this.$store.commit("setAuth", { token: result.data.token, perfil: result.data.perfil, altS: result.data.resetarSenha })
                            this.$router.push(this.$uteis.goHome({ token: result.data.token, perfil: result.data.perfil }))
                        }
                    } else {
                        this.alert = {
                            type: "danger",
                            message: result ? result.message : "",
                        }
                    }
                })
            },
        },
        mounted() {

        },
        computed: {
            validForm() {
                return this.formulario.email != "" && this.formulario.senha != "" && this.errors.items.length == 0
            },
        },
        watch: {
            formulario: {
                handler: function () {
                    this.alert = null
                },
                deep: true,
            },
        },
    }
</script>

<style scoped lang="scss"></style>
