<template>
    <div class="container-card view-home">
        <div class="card custom-card">
            <div class="card-header">
                <h5 class="card-title">Início</h5>
            </div>
            <div class="card-body">
              <div class="d-flex flex-sm-row flex-column justify-content-between">
                <p class="card-text">Bem vindo <strong>{{usuario.nome}}</strong>.</p>
                <router-link to="/nova-simulacao" class="btn btn-custom primary default">Nova simulação</router-link>
              </div>
              <!-- <div class="avisos">
                <h3>Avisos</h3>
                <p>Você não possui nenhum aviso.</p>
              </div> -->
              <div class="ultimas-propostas mt-4 mt-md-4">
                <h3 class="mb-2 mb-sm-3">Últimas Propostas</h3>
                <PropostasDestaque/>
              </div>
              <div class="simulacoes-gravadas mt-4 mt-sm-5">
                <h3 class="mb-3">Últimas simulações gravadas</h3>
                <SimulacoesDestaque/>
              </div>
            </div>
        </div>
    </div>
</template>
<script>
import UsuarioService from '@/services/creditPlus/usuario.service.js'
import SimulacoesDestaque from '@/views/Simulacao/SimulacoesDestaque.vue'
import PropostasDestaque  from '@/views/Proposta/PropostasDestaque.vue'

export default {
  name: 'HomePage',
  components: {
    SimulacoesDestaque,
    PropostasDestaque
  },
  data() {
    return {
      editado: false,
      usuario: {
        empresa: '',
        nome: '',
        telefone: '',
      },
    }
  },
  methods: {
    dadosUsuario() {
      UsuarioService.dadosUsuarioLogado().then(result => {
        if (result.success && result.data.id > 0) {
          this.usuario = {
            alterarSenha: result.data.alterarSenha,
            dataCriacaoConta: result.data.dataCriacaoConta,
            email: result.data.email,
            id: result.data.id,
            nome: result.data.nome,
            nomeEmpresa: result.data.nomeEmpresa,
            perfil: result.data.perfil,
            telefone: result.data.telefone,
          }
        } else {
          this.alert = {
            type: 'danger',
            message: result ? result.message : ''
          };
        }
      });
    },
  },
  mounted() {
    this.dadosUsuario();
  },
}
</script>
