<template>
    <div class="container-card view-ver-simulacao container">
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
        <div v-if="!alert && simulacao.id" class="card custom-card">
            <div class="card-header">
                <h5 class="card-title">Visualizar simulação</h5>
            </div>
            <div class="card-body">
                <h3 class="mb-2 mb-sm-3">Dados cadastrais</h3>
                <div class="d-flex flex-xl-row flex-column">
                    <div class="flex-2 align-self-start mb-xl-0 mb-3 pr-md-5 pr-0">
                        <label>CNPJ</label>
                        <p class="m-0 font-weight-bold">{{$uteis.formatCnpj(this.simulacao.cnpj) || ''}}</p>
                    </div>
                    <div class="flex-2 mb-xl-0 mb-3 pl-md-5 pl-0">
                        <label>Razão social</label>
                        <p class="m-0 font-weight-bold">{{this.simulacao.razaoSocial || ''}}</p>
                    </div>
                    <button v-if="simulacao.altoRisco" id="botao-alto-risco" class="flex-1 btn btn-custom secondary danger mb-md-1 mb-sm-3 events-none" variant="primary">
                        <CustomIcon class="mr-2" :name="'alert'"/>Alto índice de rejeição
                    </button>
                </div>
                <form class="fh-form" @submit.prevent="atualizar()" data-vv-scope="formulario">
                    <h3 class="mt-3 mb-2 mb-sm-3">Dados do contrato</h3>
                    <div class="d-flex flex-column flex-md-row">
                        <div class="flex-column flex-1 pr-md-5 pr-0">
                            <div class="form-group">
                                <label for="valorTotalEquipamentos">Valor total dos equipamentos</label>
                                <money :class="{'form-control': true, 'is-invalid': errors.has('formulario.valorTotalEquipamentos')}" v-model="formulario.valorTotalEquipamentos"
                                    v-bind="money" v-validate="'required|min_value:1'" type="text" name="valorTotalEquipamentos" autocomplete="off"/>
                                <span v-show="errors.has('formulario.valorTotalEquipamentos')" class="invalid-feedback">{{ errors.first('formulario.valorTotalEquipamentos') }}</span>
                            </div>
                            <div class="form-group">
                                <label for="valorAdicional">Valores adicionais (Software, etc)</label>
                                <money :class="{'form-control': true, 'is-invalid': errors.has('formulario.valorAdicional')}" v-model="formulario.valorAdicional"
                                    v-bind="money" v-validate="'required|min_value:0'" type="text" name="valorAdicional" autocomplete="off"/>
                                <span v-show="errors.has('formulario.valorAdicional')" class="invalid-feedback">{{ errors.first('formulario.valorAdicional') }}</span>
                            </div>
                            <div class="form-group">
                                <label for="prazo">Prazo do contrato</label>
                                <multiselect v-model="formulario.prazo.model" :options="formulario.prazo.options" placeholder="Selecione a quantidade de meses" label="value" track-by="key">
                                    <template slot="option" slot-scope="props">
                                        <span class="">{{props.option.value}}</span>
                                    </template>
                                    <template slot="noResult">Nenhum resultado encontrado.</template>
                                </multiselect>
                                <span v-show="errors.has('formulario.prazo')" class="invalid-feedback">{{ errors.first('formulario.prazo') }}</span>
                            </div>
                        </div>
                        <div class="flex-column flex-1 pl-md-5 pr-0">
                            <div class="form-group">
                                <label for="valorInstalacao">Valor total da instalação dos equipamentos (Máximo 10%)</label>
                                <money :class="{'form-control': true, 'is-invalid': errors.has('formulario.valorInstalacao')}" v-model="formulario.valorInstalacao"
                                    v-bind="money" v-validate="'required|min_value:0|max_value:' + (formulario.valorTotalEquipamentos / 10).toFixed(2)"
                                    type="text" name="valorInstalacao" autocomplete="off" />
                                <span v-show="errors.has('formulario.valorInstalacao')" class="invalid-feedback">{{ errors.first('formulario.valorInstalacao') }}</span>
                            </div>
                            <div class="form-group">
                                <label for="totalRecebido">
                                    Total a ser recebido
                                    <CustomIcon :name="'info'" v-b-tooltip.hover :title="'Total a ser recebido pela ' + $nome" :width="'19'" :height="'19'" />
                                </label>
                                <money :class="{'form-control': true, 'is-invalid': errors.has('formulario.totalRecebido')}" v-model="formulario.totalRecebido"
                                    disabled="true" v-bind="money" v-validate="'required'" type="text" name="totalRecebido" autocomplete="off" />
                                <span v-show="errors.has('formulario.totalRecebido')" class="invalid-feedback">{{ errors.first('formulario.totalRecebido') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="custom-control custom-checkbox">
                        <input class="custom-control-input" type="checkbox" id="checkbox" v-model="formulario.seguro" />
                        <label class="custom-control-label" for="checkbox">
                            Incluir Proteção e Danos
                            <CustomIcon
                                class="text-custom primary"
                                :name="'info'"
                                v-b-tooltip.hover
                                title="Prezado cliente, caso você opte pela contratação sem proteção e danos, as condições do projeto poderão sofrer alterações em razão do risco envolvido."
                            />
                        </label>
                    </div>
                    <CustomAlert :alert="alert2"></CustomAlert>
                </form>
                <div class="container-btns-simulacao">
                    <button class="btn btn-custom secondary default mr-3" type="button" @click="atualizar()" :disabled="!changeForm">Atualizar</button>
                    <button class="btn btn-custom primary default" type="button" @click="registrar()" :disabled="!validForm">Enviar Proposta Completa</button>
                </div>
                <div class="d-flex justify-content-between">
                    <router-link class="font-weight-bold" to="/simulacoes">Voltar</router-link>
                    <a class="font-weight-bold text-custom danger pointer" v-b-modal.modal-excluir-simulacao>Excluir</a>
                </div>
            </div>
        </div>
        <CustomAlert :alert="alert"></CustomAlert>
        <b-modal id="modal-excluir-simulacao" centered footerClass="d-flex justify-content-between">
            <template #modal-header>
                <h5 class="modal-title text-custom danger">Exclusão de simulação</h5>
                <button type="button" aria-label="Close" class="close" @click="$bvModal.hide('modal-excluir-simulacao')">
                    <CustomIcon :name="'x'" />
                </button>
            </template>
            <p>Você tem certeza que deseja excluir a simulação?</p>
            <p class="text-custom danger font-weight-bold">Essa operação é irreversível!</p>
            <template #modal-footer>
                <button class="btn btn-custom secondary dark" @click="$bvModal.hide('modal-excluir-simulacao')">Cancelar</button>
                <button class="btn btn-custom primary danger" @click="excluir()">Excluir</button>
            </template>
        </b-modal>
        <b-modal id="modal-excluir-simulacao-sucesso" centered footerClass="d-flex justify-content-between" hide-header-close hide-header @hidden="$router.push('/simulacoes')">
            <div class="text-success text-center mt-5 mb-3">
                <CustomIcon :name="'check-circle'" class="mb-2 text-success" :height="'34'" :width="'34'"></CustomIcon>
                <h5 class="text-success">Simulação excluída com sucesso!</h5>
            </div>
            <template #modal-footer>
                <div class="mx-auto">
                    <button type="button" class="btn btn-custom secondary default" @click="$bvModal.hide('modal-excluir-simulacao-sucesso')">Voltar para simulações gravadas</button>
                </div>
            </template>
        </b-modal>
        <AlertModal :alertModal="alertModal" @fechar="resetPagina()"></AlertModal>
        <ModalLoading :message="loadingMessage"></ModalLoading>
    </div>
</template>

<script>
    import Multiselect from "vue-multiselect"
    import SimulacaoService from "@/services/creditPlus/simulacao.service.js"

    export default {
        name: "EditarSimulacao",
        components: {
            Multiselect,
        },
        data() {
            return {
                alert: null,
                alert2: null,
                alertModal: null,
                loadingMessage: "",
                simulacao: {
                    id: "",
                    data: "",
                    cnpj: "",
                    razaoSocial: "",
                    altoRisco: false,
                    prazoContrato: 0,
                    valorTotalEquipamentos: 0,
                    valorAdicional: 0,
                    valorInstalacao: 0,
                    valorDoServicoMensal: 0,
                    totalRecebido: 0,
                },
                formulario: {
                    seguro: false,
                    valorTotalEquipamentos: 0,
                    valorAdicional: 0,
                    valorDoServicoMensal: 0,
                    valorInstalacao: 0,
                    totalRecebido: 0,
                    prazo: {
                        model: null,
                        options: [
                            { key: 12, value: "12 meses" },
                            { key: 24, value: "24 meses" },
                            { key: 36, value: "36 meses" },
                            { key: 48, value: "48 meses" },
                            { key: 60, value: "60 meses" },
                        ],
                    },
                },
                money: {
                    decimal: ",",
                    thousands: ".",
                    prefix: "R$ ",
                    suffix: "",
                    precision: 2,
                    masked: false,
                },
                breadcrumb: [
                    { text: "Simulações gravadas", href: "/simulacoes" },
                    { text: "Editar simulação", active: true },
                ],
            }
        },
        mounted() {
            this.dadosSimulacao()
        },
        methods: {
            resetPagina() {
                this.alertModal = null
                this.$router.go()
            },
            atualizar(registrar = false) {
                let data = {
                    id: this.simulacao.id,
                    valorTotalEquipamentos: this.formulario.valorTotalEquipamentos,
                    valorAdicional: this.formulario.valorAdicional,
                    valorInstalacao: this.formulario.valorInstalacao,
                    valorDoServicoMensal: this.formulario.valorDoServicoMensal,
                    prazoContrato: this.formulario.prazo.model.key,
                    incluirSeguro: this.formulario.seguro,
                }
                this.loadingMessage = registrar ? "Registrando simulação" : "Atualizando simulação"
                SimulacaoService.editar(data).then((result) => {
                    this.loadingMessage = ""
                    if (result.data && result.data.success) {
                        if (registrar) {
                            this.$router.push("/nova-proposta?id=" + result.data.id)
                        } else {
                            this.alertModal = {
                                type: "success",
                                title: "Simulação atualizada com sucesso!",
                                htmlBody: "<a class='btn btn-custom secondary default mt-2' href='/ver-simulacao?id=" + result.data.id + "' >Visualizar informações</a>",
                                botaoFechar: "",
                            }
                        }
                    } else {
                        this.alert2 = {
                            type: "danger",
                            message: result ? result.message : "",
                        }
                    }
                })
            },
            registrar() {
                if (this.changeForm) {
                    this.atualizar(true)
                } else {
                    this.$router.push("/nova-proposta?id=" + this.simulacao.id)
                }
            },
            excluir() {
                SimulacaoService.excluir({ id: this.simulacao.id }).then((result) => {
                    if (result.success && result.data && result.data.id > 0) {
                        this.$bvModal.hide("modal-excluir-simulacao")
                        this.$bvModal.show("modal-excluir-simulacao-sucesso")
                    }
                })
            },
            dadosSimulacao() {
                SimulacaoService.dadosSimulacao(this.$route.query.id).then((result) => {
                    if (result.data) {
                        this.simulacao.data = this.$uteis.formatDate(result.data.dataCriacao)
                        this.simulacao.id = result.data.id
                        this.simulacao.cnpj = this.$uteis.formatCnpj(result.data.cnpj)
                        this.simulacao.razaoSocial = result.data.razaoSocial
                        this.simulacao.prazoContrato = result.data.prazoContrato + " meses"
                        this.simulacao.prazo = result.data.prazoContrato
                        this.simulacao.seguro = result.data.incluirSeguro
                        this.simulacao.valorTotalEquipamentos = result.data.valorTotalEquipamentos
                        this.simulacao.valorDoServicoMensal = result.data.valorDoServicoMensal
                        this.simulacao.valorAdicional = result.data.valorAdicional
                        this.simulacao.valorInstalacao = result.data.valorInstalacao
                        this.simulacao.totalRecebido = result.data.valorTotalEquipamentos + result.data.valorAdicional + result.data.valorDoServicoMensal + result.data.valorInstalacao
                        this.simulacao.altoRisco = result.data.analiseItem.ratingDescricao ? this.$uteis.calculaRisco(result.data.analiseItem.ratingDescricao) : false

                        this.formulario.seguro = this.simulacao.seguro
                        this.formulario.valorTotalEquipamentos = this.simulacao.valorTotalEquipamentos
                        this.formulario.valorAdicional = this.simulacao.valorAdicional
                        this.formulario.valorDoServicoMensal = this.simulacao.valorDoServicoMensal
                        this.formulario.valorInstalacao = this.simulacao.valorInstalacao
                        this.formulario.prazo.model = this.formulario.prazo.options.find((d) => d.key == this.simulacao.prazo)
                    } else {
                        this.alert = {
                            type: "info",
                            message: result ? "Nenhum dado encontrado" : "",
                        }
                    }
                })
            },
        },
        watch: {
            formulario: {
                handler: function () {
                    this.formulario.totalRecebido = this.formulario.valorTotalEquipamentos + this.formulario.valorAdicional + this.formulario.valorDoServicoMensal + this.formulario.valorInstalacao
                },
                deep: true,
            },
        },
        computed: {
            changeForm() {
                return this.validForm &&
                    (this.formulario.valorTotalEquipamentos != this.simulacao.valorTotalEquipamentos ||
                    this.formulario.prazo.model.key != this.simulacao.prazo ||
                    this.formulario.seguro != this.simulacao.seguro ||
                    this.formulario.valorAdicional != this.simulacao.valorAdicional ||
                    this.formulario.valorDoServicoMensal != this.simulacao.valorDoServicoMensal ||
                    this.formulario.valorInstalacao != this.simulacao.valorInstalacao)
            },
            validForm() {
                return (
                    this.formulario.valorTotalEquipamentos > 0 &&
                    this.formulario.prazo.model &&
                    this.formulario.prazo.model.key != "" &&
                    this.errors.items.length == 0
                )
            },
        },
    }
</script>
<style lang="scss">
    .titulo-dados-simulacao {
        border-top: 1px solid $light-gray;
        margin-top: 1rem;
        padding-top: 1rem;
    }
    .container-btns-simulacao {
        border-top: 1px solid $light-gray;
        padding: 1rem 0;
        margin: 1rem 0 0 0;
    }
</style>
