<template>
	<div class="container-card view-nova-proposta container">
		<b-breadcrumb :items="breadcrumb"></b-breadcrumb>
		<div class="card custom-card">
			<div class="card-header">
				<h5 class="card-title">{{titulo}}</h5>
			</div>
			<div class="card-body pt-2">
				<form-proposta v-if="!alert" :simulacao="simulacao" :proposta="null" @atualizarTitulo="atualizarTitulo"/>
				<CustomAlert :alert="alert"></CustomAlert>
			</div>
		</div>
	</div>
</template>

<script>
import SimulacaoService from '@/services/creditPlus/simulacao.service.js'
import FormProposta from '@/views/Proposta/FormProposta.vue'
export default {
	name: 'NovaProposta',
	components: {
		FormProposta
	},
	data() {
		return {
			alert: null,
			titulo: 'Nova proposta',
			simulacao: {
				id : '',
				data : '',
				cnpj : '',
				razaoSocial : '',
				PrazoContrato: 0,
				valorTotalEquipamentos : 0,
				valorDoServicoMensal : 0,
				totalRecebido : 0,
			},
			breadcrumb: [
				{ text: 'Propostas', href: '/propostas' },
				{ text: 'Nova proposta', active: true }
			],
		}
	},
	mounted() {
		this.dadosSimulacao();
	},
	methods: {
		atualizarTitulo(titulo) {
			this.titulo = titulo
		},
		dadosSimulacao() {
			SimulacaoService.dadosSimulacao(this.$route.query.id).then(result => {
				if (result.data) {
					this.simulacao.data = this.$uteis.formatDate(result.data.dataCriacao)
					this.simulacao.id = result.data.id
					this.simulacao.cnpj = this.$uteis.formatCnpj(result.data.cnpj)
					this.simulacao.razaoSocial = result.data.razaoSocial
					this.simulacao.prazo = result.data.prazoContrato
					this.simulacao.prazoContrato = result.data.prazoContrato + " meses"
					this.simulacao.seguro = result.data.incluirSeguro
					this.simulacao.valorTotalEquipamentos = result.data.valorTotalEquipamentos
					this.simulacao.valorDoServicoMensal = result.data.valorDoServicoMensal
					this.simulacao.valorAdicional = result.data.valorAdicional
					this.simulacao.valorInstalacao = result.data.valorInstalacao
					this.simulacao.totalRecebido = result.data.valorTotalEquipamentos + result.data.valorAdicional + result.data.valorInstalacao
					this.simulacao.analiseItem = result.data.analiseItem
					this.simulacao.taxa = result.data.taxa
				} else {
					this.alert = {
						type: 'info',
						message: result ? 'Código da simulação inválido' : ''
					};
				}
			});
		},
	},
}
</script>
<style lang="scss">
</style>