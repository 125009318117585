<template>
   <div class="container-card view-propostas-parceiros">
        <div class="card custom-card">
            <div class="card-header">
                <h5 class="card-title">Propostas</h5>
            </div>
            <div class="card-body">
                <div class="d-flex flex-row mb-3">
                    <p class="align-self-center mb-0 card-text">Veja abaixo as suas propostas realizadas pelos parceiros da {{$nome}}.</p>
                    <router-link class="btn btn-custom secondary default small ml-auto" to="/propostas-parceiros-excluidas"> Ver propostas excluídas </router-link>
                </div>
                <div class="table-container">
                    <div :class="{'table-filter': true, 'open': openFilter}">
                        <p class="align-self-center" @click="openFilter = !openFilter">Filtros: <span></span></p>
                        <div class="table-filter-content">
                            <div class="d-flex">
                                <button class="btn btn-custom secondary default small ml-auto" type="button" :disabled="!temFiltros" @click="limparFiltros"> Limpar filtros </button>
                            </div>
                            <form class="mt-1 fh-form flex-row" @submit.prevent data-vv-scope="formulario">
                                <div class="d-flex flex-1 flex-column mr-4">
                                    <div class="form-group">
                                        <label for="status">Filtrar por data</label>
                                        <CustomDatePicker :model="formulario.datePicker.model" @dataSelecionada="dataSelecionada" :key="formulario.datePicker.key"></CustomDatePicker>
                                    </div>
                                    <div class="form-group">
                                        <label for="status">Filtrar por nome completo</label>
                                        <multiselect v-model="formulario.parceiro.model" :options="formulario.parceiro.options" placeholder="Todos" label="value" track-by="key">
                                            <template slot="option" slot-scope="props">
                                                <span class="">{{props.option.value}}</span>
                                            </template>
                                            <template slot="noResult">Nenhum resultado encontrado.</template>
                                            <template slot="noOptions">Digite ao menos 3 caracteres para iniciar a busca.</template>
                                        </multiselect>
                                    </div>
                                </div>
                                <div class="d-flex flex-1 flex-column ml-4">
                                    <div class="form-group flex-1">
                                        <label for="status">Filtrar por razão social</label>
                                        <multiselect v-model="formulario.nomesEmpresa.model" :options="formulario.nomesEmpresa.options" placeholder="Todos" label="value" track-by="key" @search-change="buscarNomeEmpresa">
                                            <template slot="option" slot-scope="props">
                                                <span class="">{{props.option.value}}</span>
                                            </template>
                                            <template slot="noResult">Nenhum resultado encontrado.</template>
                                            <template slot="noOptions">Digite ao menos 3 caracteres para iniciar a busca.</template>
                                        </multiselect>
                                    </div>
                                    <div class="form-group flex-1">
                                        <label for="status">Filtre por status</label>
                                        <multiselect v-model="formulario.status.model" :options="formulario.status.options" placeholder="Todos" label="value" track-by="key">
                                            <template slot="option" slot-scope="props">
                                                <span class="">{{props.option.value}}</span>
                                                <template slot="noResult">Nenhum resultado encontrado.</template>
                                            </template>
                                        </multiselect>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="table-container">
                        <!-- <b-table class="custom-table mt-4" hover :items="itens" :fields="fields" show-empty emptyText="Nenhum dado encontrado." sort-icon-right :busy="loading"> -->
                        <b-table id="table-propostas-parceiros" class="custom-table tabela-proposta-parceiros" hover :items="items" :fields="fields" show-empty emptyText="Nenhum dado encontrado."
                            sort-icon-right :busy="loading" no-local-sorting  :sort-by.sync="sort.sortBy" :sort-desc.sync="sort.sortDesc" @sort-changed="reordenar">
                            <template #cell(status)=row>
                                <div class="label-status" :style="'background: ' + row.item.status.cor">
                                {{row.item.status.value}}
                                </div>
                            </template>
                            <template #cell(acoes)=row>
                                <div class="d-flex justify-content-around">
                                    <router-link :to="{ 'path' : '/editar-proposta-parceiro', query: { 'id' : row.item.id }}">
                                        <CustomIcon :name="'edit'"/>
                                    </router-link>
                                    <span class="pointer" @click="prepararExcluirProposta(row.item)">
                                        <CustomIcon class="text-custom danger" :name="'x'"/>
                                    </span>
                                </div>
                            </template>
                            <template #table-busy>
                                <div class="text-center text-dark-gray my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong class="ml-2">Carregando informações...</strong>
                                </div>
                            </template>
                        </b-table>
                    </div>
                </div>
                <b-pagination v-if="pagination.perPage <= pagination.rows" @change="alterarPagina" v-model="pagination.currentPage" :total-rows="pagination.rows" :per-page="pagination.perPage" aria-controls="table-propostas-parceiros"></b-pagination>
            </div>
        </div>
        <b-modal id="modal-excluir-proposta" centered footerClass="d-flex justify-content-between">
            <template #modal-header>
                <h5 class="modal-title text-custom danger">Exclusão de proposta</h5>
                <button type="button" aria-label="Close" class="close" @click="$bvModal.hide('modal-excluir-proposta')">
                    <CustomIcon :name="'x'" />
                </button>
            </template>
            <p>
                Você tem certeza que deseja excluir a proposta do parceiro <strong>{{propostaExcluir ? propostaExcluir.parceiro : ''}}</strong>
                do dia <strong>{{propostaExcluir ? propostaExcluir.dataCriacao : ''}}</strong>?
                Ela não será mais exibida na listagem de propostas.
            </p>
            <p class="text-custom danger font-weight-bold">Essa operação é irreversível!</p>
            <template #modal-footer>
                <button class="btn btn-link font-weight-bold" @click="$bvModal.hide('modal-excluir-proposta')">Cancelar</button>
                <button class="btn btn-custom primary danger" @click="excluir()">Excluir</button>
            </template>
        </b-modal>
        <AlertModal :alertModal="alertModal" @fechar="alertModal = null"/>
        <ModalLoading :message="loadingMessage"></ModalLoading>
    </div>
</template>
<script>
import PropostaService from "@/services/creditPlus/proposta.service.js"
import Multiselect from "vue-multiselect"
import CustomDatePicker from '@/components/CustomDatePicker.vue'

export default {
    name: "PropostasParceiros",
    components: {
        Multiselect,
        CustomDatePicker,
    },
    data() {
        return {
            loadingMessage: "",
            fields: [
                { key: "dataCriacao", label: "Data", sortable: true },
                { key: "parceiro", label: "Nome do parceiro", sortable: true },
                { key: "razaoSocial", label: "Razão Social", sortable: true },
                { key: "totalContrato", label: "Valor do Projeto", sortable: true },
                { key: "status", label: "Status", sortable: true },
                { key: "acoes", label: "", sortable: false, tdClass: "w-100-px", thClass: "w-100-px" },
            ],
            items: [],
            alertModal: null,
            loading: false,
            sort: {
                sortBy: "dataCriacao",
                sortDesc: true,
            },
            pagination: {
                rows: 0,
                perPage: 20,
                currentPage: 1,
            },
            openFilter: false,
            propostaExcluir: null,
            formulario: {
                status: {
                    model: null,
                    options: []
                },
                parceiro: {
                    model: null,
                    options: [],
                },
                nomesEmpresa: {
                    model: null,
                    options: []
                },
                datePicker: {
                    key: 0,
                    model: {
                        dataInicio: '',
                        datatFim: '',
                    }
                }
            },
        }
    },
    mounted() {
        this.formulario.status.options = [this.$enums.statusProposta.ANALISE, this.$enums.statusProposta.NEGADA, this.$enums.statusProposta.APROVADA, this.$enums.statusProposta.CANCELADA].map(function (status) {
            return { key: status.key, value: status.value }
        })
        this.listarParceiros()
        this.listarPropostas()
    },
    methods: {
        excluir() {
            this.loadingMessage = "Excluindo proposta..."
            PropostaService.excluir({id: this.propostaExcluir.id}).then(resultado => {
                if (resultado.success && resultado.data.success) {
                    this.alertModal = {
                        type: "success",
                        title: "Proposta excluida com sucesso!",
                        htmlBody: "",
                        botaoFechar: "Fechar janela"
                    }
                    this.listarPropostas()
                } else {
                    this.alertModal = {
                        type: "danger",
                        title: "Falha ao excluir proposta",
                        htmlBody: "Atualize a página e tente novamente mais tarde.",
                        botaoFechar: "Fechar janela"
                    }
                }
            }).finally(() => {
                this.$bvModal.hide("modal-excluir-proposta")
                this.loadingMessage = ""
            })
        },
        prepararExcluirProposta(proposta) {
            this.propostaExcluir = proposta
            this.$bvModal.show("modal-excluir-proposta")
        },
        dataSelecionada(val) {
            this.formulario.datePicker.model = val
            this.listarPropostas()
        },
        reordenar(ctx) {
            this.sort = {
                sortBy: ctx.sortBy,
                sortDesc: ctx.sortDesc,
            }
            this.listarPropostas()
        },
        alterarPagina(value) {
            this.pagination.currentPage = value
            this.listarPropostas()
        },
        listarParceiros() {
            PropostaService.listarParceirosComPropostas().then((result) => {
                if (result.data && result.data.itens.length > 0) {
                    result.data.itens.forEach((element) => {
                        this.formulario.parceiro.options.push({ key: element.id, value: element.nome })
                    })
                }
            })
        },
        listarPropostas() {
            this.loading = true
            this.items = []
            let dados = {
                status: this.formulario.status.model ? this.formulario.status.model.key : null,
                parceiro: this.formulario.parceiro.model ? this.formulario.parceiro.model.key : null,
                perPage: this.pagination.perPage,
                currentPage: this.pagination.currentPage,
                nomeEmpresa: this.formulario.nomesEmpresa.model?.value,
                "sort.sortBy": this.sort?.sortBy,
                "sort.sortDesc": this.sort?.sortDesc,
                ...this.formulario.datePicker.model,
            }
            PropostaService.listarPropostasParceiros(dados).then((result) => {
                if (result.data && result.data.itens.length > 0) {
                    this.items = []
                    this.pagination.rows = result.data.totalItens
                    result.data.itens.forEach((element) => {
                        this.items.push({
                            dataCriacao: this.$uteis.formatDate(element.dataCriacao),
                            id: element.id,
                            parceiro: element.parceiro,
                            razaoSocial: element.simulacao.razaoSocial,
                            totalContrato: "R$ " + this.$uteis.formatNumber(element.simulacao.valorTotalEquipamentos + element.simulacao.valorAdicional + element.simulacao.valorInstalacao),
                            status: this.$enums.getObjectByKey(this.$enums.statusProposta, element.status),
                        })
                    })
                } else {
                    this.alert = {
                        type: "danger",
                        message: result ? result.message : "",
                    }
                }
                this.loading = false
            })
        },
        limparFiltros() {
            this.pagination.currentPage = 1
            this.formulario.status.model = null
            this.formulario.nomesEmpresa.model = null
            this.formulario.parceiro.model = null
            this.formulario.nomesEmpresa.options = []
            this.formulario.parceiro.options = []
            this.formulario.datePicker.model.dataInicio = ''
            this.formulario.datePicker.model.dataFim = ''
            this.formulario.datePicker.key++
            this.listarPropostas()
        },
        buscarNomeEmpresa(nome) {
            this.formulario.nomesEmpresa.options = []
            if (nome.length >= 3) {
                PropostaService.buscarPorNomeEmpresa({nomeEmpresa: nome}).then((result) => {
                    if (result.success && result.data.itens) {
                         this.formulario.nomesEmpresa.options = result.data.itens.map( element => {
                            return {
                                key: element.id,
                                value: element.nomeEmpresa,
                            }
                        }).sort((a, b) => a.value > b.value)
                        this.formulario.nomesEmpresa.options = this.$uteis.uniqueByProprety(this.formulario.nomesEmpresa.options, 'value')
                    }
                })
            }
        },
    },
    watch: {
        'formulario.status.model': {
            handler: function () {
                this.pagination.currentPage = 1
                this.listarPropostas()
            },
            deep: true
        },
        'formulario.parceiro.model': {
            handler: function () {
                this.pagination.currentPage = 1
                this.listarPropostas()
            },
            deep: true
        },
        'formulario.nomesEmpresa.model': {
            handler: function () {
                this.pagination.currentPage = 1
                this.listarPropostas()
            },
            deep: true
        },
    },
    computed: {
        temFiltros() {
            return this.formulario.status.model != null || this.formulario.nomesEmpresa.model != null || this.formulario.parceiro.model != null
            || this.formulario.datePicker.model.dataInicio || this.formulario.datePicker.modeldatatFim
        }
    },
}
</script>
<style lang="scss">
    .label-status {
        padding: 2px 4px;
        border-radius: 6px;
        color: white;
        text-transform: uppercase;
        text-align: center;
        font-size: 0.8rem;
    }
    .tabela-proposta-parceiros {
        thead th {
            border: none !important;
        }
    }
</style>
