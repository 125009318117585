<template>
    <div class="login-layout-container">
        <div class="background" v-bind:style="{'background-image': image}">
            <h1>Portal do <br /> Parceiro</h1>
        </div>
        <div class="layout-content p-3 p-sm-4 d-flex flex-column">
            <div class="d-flex flex-row container-menu-login">
                <img class="logo" src="img/lgo-allugg-color.svg">
                <div class="ml-auto my-auto">
                    <router-link v-if="$route.path.startsWith('/login')" to="/cadastro" class="btn btn-custom secondary default">Seja um parceiro</router-link>
                    <router-link v-if="$route.path.startsWith('/cadastro')" to="/login" class="btn btn-custom secondary default">Login</router-link>
                </div>
            </div>
            <h2 class="d-block d-sm-none py-3">Portal do Parceiro</h2>
            <router-view/>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LoginLayout',
        components: {
        },
        data () {
            return {
                image: 'url("img/login-cadastro-1.jpg")',
            }
        },
        beforeMount() {
            document.querySelector('body').removeAttribute('class')
        },
        mounted() {
            document.querySelector('body').classList.add("login-layout")
        }
    }
</script>
<style lang="scss">
    .login-layout-container {
        height: 100%;
        min-height: 100%;
        display: flex;
        flex-direction: row;
        .background {
            flex: 1;
            max-width: 35%;
            background-size: cover;
            background-position: 58% 20%;
            background-repeat: no-repeat;
            h1 {
                color: $green;
                position: absolute;
                bottom: 20%;
                left: 6%;
                font-weight: 400 !important;
                font-size: 50px !important;
                line-height: 59px !important;
            }
        }
        .layout-content {
            flex: 1;
            padding: 30px !important;
        }
        .logo {
            width: 100px;
        }
    }
    /* Vertical Responsiveness */
    @media screen and ( max-height: 900px ){
        .container-menu-login {
            margin-bottom: 2em !important;
        }
    }
    @media (max-width: $min-lg) {
        .login-layout-container .background h1 {
            font-size: 30px !important;
            line-height: 39px !important;
        }
    }
    @media (max-width: $min-md) {
        .login-layout-container .background {
            display: none;
        }
        .container-menu-login {
            margin-bottom: .5em !important;
        }
    }
    @media (max-width: $min-sm) {
        .login-layout-container .background {
            display: none;
        }
        .login-layout-container h2 {
            padding-top: 30px !important;
        }
        .container-menu-login {
            .btn {
                padding-top: 0.5rem !important;
                padding-bottom: 0.5rem !important;
            }
        }
        .login-layout-container h2 {
            padding-top: 30px !important;
        }
        .login-layout-container .layout-content {
            flex: 1;
            padding: 20px !important;
        }
        .logo {
            max-width: 120px;
        }
        .container-menu-login {
            .btn {
                padding-top: 0.25rem !important;
                padding-bottom: 0.25rem !important;
            }
        }
    }
</style>